import * as XLSX from 'xlsx';
import { PDFDocument, rgb } from 'pdf-lib';
import { ITransactions } from 'data/types/response';

export const exportFiles = (fileType: string, data: Array<ITransactions>) => {
    const filename = 'Approvals';
    switch (fileType) {
        case 'Microsoft (.xlsx)': {
            if (data.length < 1) return;
            const xlsxData = [
                Object.keys(data[0]),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                ...data.map((item) => Object.values(item)),
            ];
            const xlsxWorksheet = XLSX.utils.aoa_to_sheet(xlsxData);
            const xlsxWorkbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                xlsxWorkbook,
                xlsxWorksheet,
                'Sheet 1'
            );
            const xlsxBuffer = XLSX.write(xlsxWorkbook, {
                bookType: 'xlsx',
                type: 'array',
            });
            const xlsxBlob = new Blob([xlsxBuffer], {
                type: 'application/octet-stream',
            });
            const xlsxUrl = URL.createObjectURL(xlsxBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = xlsxUrl;
            downloadLink.download = `${filename}.xlsx`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            break;
        }
        case 'CSV (.csv)': {
            if (data.length < 1) return;
            const csvData = [
                Object.keys(data[0]),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                ...data.map((item) => Object.values(item)),
            ];
            const csvContent = `data:text/csv;charset=utf-8,${csvData
                .map((row) => row.join(','))
                .join('\n')}`;
            const encodedUri = encodeURI(csvContent);
            const downloadLink = document.createElement('a');
            downloadLink.href = encodedUri;
            downloadLink.download = `${filename}.csv`; // Use the filename variable
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            break;
        }

        case 'Open Document (.ods)': {
            if (data.length < 1) return;
            const odsContent = [
                Object.keys(data[0]),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                ...data.map((item) => Object.values(item)),
            ];
            const odsDataList = odsContent
                .map((row) => row.join('\t'))
                .join('\n');

            const blob = new Blob([odsDataList], {
                type: 'application/vnd.oasis.opendocument.spreadsheet',
            });
            const url = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = `${filename}.ods`; // Use the filename variable
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            break;
        }
        case 'Tab Seperated Values (.tsv)': {
            if (data.length < 1) return;
            const tsvData = [
                Object.keys(data[0]),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                ...data.map((item) => Object.values(item)),
            ];
            const tsvDataList = tsvData.map((row) => row.join('\t')).join('\n');

            const blob = new Blob([tsvDataList], {
                type: 'text/tab-separated-values',
            });
            const url = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = `${filename}.tsv`; // Use the filename variable
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            break;
        }

        case 'Web Page (HTML)': {
            // Create the HTML content (you can replace this with your own content)
            if (data.length < 1) return;
            const htmlContent = `
                      <!DOCTYPE html>
                      <html lang="en">
                      <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Audit Table</title>
                      </head>
                         <body>
                            <table>
                               <thead>
                                    <tr>
                                      <th>Method</th>
                                      <th>Type</th>
                                      <th>Status</th>
                                      <th>Email</th>
                                      <th>Amount</th>
                                      <th>Currency</th>
                                      <th>Created At</th>
                                      <th>Updated At</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    ${data
                                        .map(
                                            (item, index) => `
                                            <tr key=${index}>
                                              <td>${item?.method as string}</td>
                                              <td>${item?.pspType}</td>
                                              <td>${item?.status as string}</td>
                                              <td>${item?.email as string}</td>
                                              <td>${item?.amount as number}</td>
                                              <td>${
                                                  item?.currency as string
                                              }</td>
                                              <td>${item?.createdAt}</td>
                                              <td>${item?.updatedAt}</td>
                                            </tr>
                                            `
                                        )
                                        .join('')}
                                  </tbody>
                            </table>                      
                         </body>
                      </html>`;

            // Convert the HTML content to a Blob
            const blob = new Blob([htmlContent], { type: 'text/html' });
            const url = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = `${filename}.html`; // Use the filename variable
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            break;
        }

        case 'PDF (.pdf)': {
            if (data.length < 1) return;
            const createPDF = async () => {
                const pdfDoc = await PDFDocument.create();
                const page = pdfDoc.addPage([2000, 900]);
                const { width, height } = page.getSize();

                // Draw table title
                page.drawText('Approval Data Export', {
                    x: width / 2 - 70, // Center the title
                    y: height - 10,
                    size: 12, // Increase font size for title
                    color: rgb(0, 0, 0),
                });

                const columnHeaders = Object.keys(
                    data[0] as Record<string, any>
                );
                const rowData = data.map(
                    (item) => Object.values(item) as ITransactions[]
                );
                const columnWidth = 200;
                const rowHeight = 20;
                const tableY = height - 40;

                rowData.forEach((row, i) => {
                    const y = tableY - i * rowHeight;

                    row.forEach((cell, j) => {
                        const x = 10 + j * columnWidth;

                        let text: string;

                        if (i === 0) {
                            text = columnHeaders[j];
                        } else if (typeof cell === 'object') {
                            text = JSON.stringify(cell);
                        } else {
                            text = cell;
                        }

                        const textColor = i === 0 ? rgb(1, 1, 1) : rgb(0, 0, 0); // Change text color for header

                        const backgroundColor =
                            i === 0 ? rgb(0, 0, 0) : rgb(1, 1, 1); // Change background color for header

                        page.drawRectangle({
                            x,
                            y,
                            width: columnWidth,
                            height: rowHeight,
                            color: backgroundColor, // Apply background color
                        });

                        page.drawText(text?.toString(), {
                            x: x + 5, // Adjust x position to center text within the cell
                            y: y + 5, // Adjust y position to center text vertically within the cell
                            size: 10,
                            color: textColor, // Apply text color
                        });
                    });
                });

                const pdfBytes = await pdfDoc.save();

                const pdfBlob = new Blob([pdfBytes], {
                    type: 'application/pdf',
                });
                const pdfUrl = URL.createObjectURL(pdfBlob);

                const downloadLink = document.createElement('a');
                downloadLink.href = pdfUrl;
                downloadLink.download = `${filename}.pdf`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            };

            createPDF();
            break;
        }

        default:
            break;
    }
};
