import { Dispatch } from 'react';
import { AppActions, AppActionsEnum } from 'context/Auth/AuthContextValues';
import { getCompanyBrands } from 'services/api/api';

export const getAllCompanyBrands = async (
    dispatch: Dispatch<AppActions>,
    setIsLoading: (value: boolean) => void
): Promise<void> => {
    setIsLoading(true);

    try {
        try {
            const res = await getCompanyBrands();
            dispatch({
                type: AppActionsEnum.SET_COMPANY_AND_BRANDS,
                payload: {
                    companiesAndBrands: res.companies,
                },
            });
        } catch (error) {
            console.error('Error fetching company brands:', error);
        }
    } finally {
        setIsLoading(false);
    }
};
