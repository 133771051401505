import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { Pagination, Table } from 'components/Common';
import { ITransactions } from 'data/types/response';
import { useAuthContext } from 'context/Auth/AuthContext';
import {
    IFetchTransactionPayload,
    IFilteredDataType,
} from 'components/Operations/Transactions/Transaction.type';
import { getTransaction } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import TransactionDetailModal from 'components/Modal/TransactionDetailsModal';
import {
    headerList,
    transactionTableColumn,
} from 'components/Operations/Transactions/TransactionTable/TransactionTableUtill';

const TransactionSummary = () => {
    const { auth } = useAuthContext();
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] =
        useState<ITransactions | null>(null);
    const [transactionData, setTransactionData] = useState<ITransactions[]>([]);
    const [filterData, setFilterData] = useState<IFilteredDataType>({
        status: [],
        search: '',
        page: 1,
        limit: 10,
    });
    const [totalPage, setTotalPage] = useState<number>(10);

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (transactionData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
        setFilterData({
            ...filterData,
            limit: parseInt(e.target.value),
            page: 1,
        });
    };

    const fetchTransactionData = () => {
        setIsLoading(true);
        const payload: IFetchTransactionPayload = { ...filterData };
        if (auth?.selectedBrand?._id) {
            payload.brandIds = [auth?.selectedBrand?._id];
        } else if (auth.selectedCompany?._id) {
            const selectedCompany = (auth?.companiesAndBrands || []).find(
                (company: { _id: string }) =>
                    company._id === auth.selectedCompany?._id
            );
            payload.brandIds =
                (selectedCompany.brandData || []).map(
                    ({ _id }: { _id: string }) => _id
                ) || [];
        }
        getTransaction(payload)
            .then((res) => {
                setTotalPage(res?.total);
                setTransactionData(
                    (res.transactions || []).sort(
                        (a, b) =>
                            new Date(b?.createdAt).getTime() -
                            new Date(a?.createdAt).getTime()
                    ) || []
                );
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        if (!auth?.companiesAndBrands?.length) return;
        fetchTransactionData();
    }, [
        filterData,
        auth?.selectedBrand?._id,
        auth?.selectedCompany?._id,
        auth?.companiesAndBrands,
    ]);

    const updatedHeaderList = useMemo(() => {
        return auth.authUser?.filteredColumn?.length !== 0
            ? headerList.map((item) => ({
                  ...item,
                  isChecked: (auth.authUser?.filteredColumn || []).includes(
                      item.key
                  ),
              }))
            : headerList;
    }, [auth.authUser?.filteredColumn]);

    const filteredArray = transactionTableColumn().filter((item) => {
        const correspondingItem = updatedHeaderList.find(
            (secondItem) => secondItem.key === item.key
        );
        return correspondingItem?.isChecked;
    });

    return (
        <div>
            {isOpen && (
                <TransactionDetailModal
                    transaction={transactionDetails}
                    isTransactionModal
                    setIsOpen={setIsOpen}
                />
            )}
            <div className="!overflow-y-scroll !overflow-x-scroll dataTable rounded-xl mt-5">
                <div className="flex h-full w-full items-center">
                    {isLoading ? (
                        <div className="flex h-full w-full items-center justify-center">
                            <LoadingSpinner />
                        </div>
                    ) : (
                        <Table
                            isTransactionDetailsTable
                            handleRowClick={(details) => {
                                setTransactionDetails(details);
                                setIsOpen(true);
                            }}
                            data={transactionData}
                            columns={filteredArray}
                            className="w-full"
                            colSpan={14}
                        />
                    )}
                </div>
            </div>
            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalPage || 0}
                    pageSize={pageLimit}
                    onPageChange={(page: number) => {
                        setFilterData({ ...filterData, page });
                        setCurrentPage(page);
                    }}
                    siblingCount={1}
                    handleOnSelect={handleOnSelect}
                    text="Show Transactions"
                />
            </div>
        </div>
    );
};

export default TransactionSummary;
