import React, { useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PrimaryButton } from 'components/Common';
import { processTrustPayment } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import {
    generateRandomData,
    PaymentProviderConstant,
} from 'data/constants/payment.constants';
import useFetchCountries from 'hooks/useFetchCountries';
import useFilteredBrandData from 'hooks/useFilteredBrandData';
import { useAuthContext } from 'context/Auth/AuthContext';
import { trustFormValidation } from './Merchant.schema';
import { ITrustType } from './Merchant.type';
import UserInformation from '../Common/UserInformation/UserInformation';
import BillingAddress from '../Common/BillingAddress/BillingAddress';
import OtherInformation from '../Common/OtherInformation/OtherInformation';
import CardDetails from '../Common/CardDetails/CardDetails';
import TransactionVelocity from '../Common/TransactionVelocity/TransactionVelocity';

const Trust = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { auth } = useAuthContext();
    const { control, handleSubmit, reset, setValue } = useForm<ITrustType>({
        resolver: zodResolver(trustFormValidation),
        mode: 'onChange',
        defaultValues: {
            blocked: false,
            kycVerified: false,
            isVelocity: false,
        },
    });
    const { countries } = useFetchCountries();
    const brands = useFilteredBrandData(auth, PaymentProviderConstant.TRUST);

    const handleOnSubmit = handleSubmit((formData) => {
        if (!formData) return;
        setLoading(true);

        const payload = {
            ...formData,
            tags: formData?.tags ?? [],
            bonusCode: formData?.bonusCode ?? '',
            card: {
                ...formData.card,
                expiry: {
                    month: formData.card.expiry.split('/')[0],
                    year: formData.card.expiry.split('/')[1],
                },
                billing_address: {
                    ...formData.address,
                    postal_code: formData.address.postalCode,
                },
            },
            baseamount: parseFloat(formData.baseamount),
            country: formData.address.country,
        };

        processTrustPayment(payload)
            .then((res) => {
                if (res.isSuccess) {
                    showToast(res?.message, 'success');
                    setLoading(false);
                    reset({});
                }
            })
            .catch((err) => {
                if (err?.errors) {
                    const message = (err?.errors ?? []).join(', ');
                    showToast(message ?? 'something went wrong', 'error');
                } else if (err?.data?.errordata) {
                    const message = (err.data.errordata ?? [])
                        .join(', ')
                        .concat(err?.data?.errormessage);
                    showToast(message ?? 'something went wrong', 'error');
                } else if (err?.data?.message) {
                    showToast(
                        err?.data?.message ?? 'something went wrong',
                        'error'
                    );
                } else {
                    showToast(err?.message ?? 'something went wrong', 'error');
                }
                setLoading(false);
            });
    });

    return (
        <>
            <div className="flex w-full items-center justify-end">
                <PrimaryButton
                    color="#8FB131"
                    variant="filled"
                    name="Generate Data"
                    onClick={() =>
                        generateRandomData(
                            PaymentProviderConstant.TRUST,
                            reset,
                            countries,
                            brands
                        )
                    }
                    className="!h-[45px] w-fit px-4"
                />
            </div>
            <form className="w-full" onSubmit={handleOnSubmit}>
                <div>
                    <UserInformation
                        control={control}
                        pspType={PaymentProviderConstant.TRUST}
                        setValue={setValue}
                    />
                    <CardDetails control={control} />
                    <BillingAddress control={control} setValue={setValue} />
                    <OtherInformation
                        control={control}
                        setValue={setValue}
                        pspType={PaymentProviderConstant.TRUST}
                    />
                    <TransactionVelocity control={control} />
                    <div className="mt-10">
                        <PrimaryButton
                            type="submit"
                            isDrawerButton
                            className={classNames('w-full max-w-[224px]')}
                            color="#2E672F"
                            variant="filled"
                            name={loading ? '' : 'Submit'}
                            loading={loading}
                            disabled={loading}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

export default Trust;
