import axios from 'axios';

const baseURL = `${process.env.REACT_APP_SERVER_ENDPOINT as string}/v1/`;

const instance = axios.create({
    baseURL,
});

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token') ?? '';
    // Log the request method and URL on every request (not body)
    console.log(
        `Axios calling: ${(config?.method ?? 'NO_METHOD').toUpperCase()} at ${
            config.url ?? 'NO_URL'
        }`
    );
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

instance.interceptors.response.use(
    (response) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        response?.data || response,
    (error) => {
        if (!error.response) {
            const errorMessage = 'Server error';
            return Promise.reject(errorMessage);
        }
        const errorMessage =
            error?.response?.data || error || 'An error occurred';
        if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem(
                'authErrorMessage',
                JSON.stringify(errorMessage)
            );

            // @ts-ignore
            window.location = '/login';
            return;
        }

        return Promise.reject(errorMessage);
    }
);
export default instance;
