import { Dispatch, SetStateAction, useCallback } from 'react';
import { INodeType } from 'components/RuleEngine/RuleBuilder/RuleBuilder.type';
import { Edge } from 'reactflow';

interface IRemoveTreeOfChildNodeProps {
    setNodes: Dispatch<SetStateAction<any>>;
    setEdges: Dispatch<SetStateAction<any>>;
    setCloseRuleModal: Dispatch<SetStateAction<boolean>>;
}

const useRemoveTreeOfChildNode = ({
    setNodes,
    setEdges,
    setCloseRuleModal,
}: IRemoveTreeOfChildNodeProps) => {
    const collectConnectedNodes = (
        nodes: INodeType[],
        sourceId: string
    ): Set<string> => {
        const connectedNodesIds = new Set<string>();
        const traverseNodes = (id: string) => {
            nodes.forEach((node) => {
                if (node.source === id) {
                    connectedNodesIds.add(node.id);
                    traverseNodes(node.id);
                }
            });
        };
        traverseNodes(sourceId);
        return connectedNodesIds;
    };

    const collectConnectedEdges = (
        edges: any[],
        edgeTarget: string
    ): Set<string> => {
        const connectedEdgeIds = new Set<string>();
        const traverseEdges = (target: string) => {
            edges.forEach((edge) => {
                if (edge.source === target) {
                    connectedEdgeIds.add(edge.id);
                    traverseEdges(edge.target);
                }
            });
        };
        traverseEdges(edgeTarget);
        return connectedEdgeIds;
    };

    return useCallback(
        (id: string) => {
            setNodes((nodes: INodeType[]) => {
                const removedNode = nodes.find((node) => node?.id === id);
                if (!removedNode) return nodes;

                const connectedNodesIds = collectConnectedNodes(
                    nodes,
                    removedNode.id
                );
                return nodes.filter(
                    (node) => node.id !== id && !connectedNodesIds.has(node.id)
                );
            });

            setEdges((edges: Edge[]) => {
                const removedEdge = edges.find((edge) => edge?.id === id);
                if (!removedEdge) return edges;

                const connectedEdgeIds = collectConnectedEdges(
                    edges,
                    removedEdge.target
                );
                return edges.filter(
                    (edge) => edge.id !== id && !connectedEdgeIds.has(edge.id)
                );
            });

            setCloseRuleModal(false);
        },
        [setNodes, setEdges, setCloseRuleModal]
    );
};

export default useRemoveTreeOfChildNode;
