import TermsAndConditions from 'components/Modal/TermsAndConditionsModal/TermsAndConditions';
import { SvgIcon } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import dayjs from 'dayjs';

const AcceptableUsagePolicy = () => {
    const { auth } = useAuthContext();
    const acceptedDate = auth?.authUser?.aupAcceptedDate;

    const formattedDate = dayjs(acceptedDate).format('DD/MM/YYYY');

    return (
        <div className="p-10">
            <div className="font-merri-bold text-2xl">Policy Statements</div>
            <div className="flex items-center gap-2 pt-1 pb-4">
                <SvgIcon icon="CLOCK_ICON_SMALL" />
                <div className="text-xs text-[#383838] pb-0.5">
                    Accepted: {formattedDate}
                </div>
            </div>
            <TermsAndConditions />
        </div>
    );
};

export default AcceptableUsagePolicy;
