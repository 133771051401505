import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import { Modal, PrimaryButton, SvgIcon } from 'components/Common';
import { IModalProps } from '../Modal.type';

const AuthenticationModal = ({
    setIsOpen,
    handleVerifyOTP,
    setOtp,
    otp,
    otpError,
    isOtpLoading = false,
}: IModalProps) => {
    const digitLeft = 6 - otp.length;

    const getButtonName = () => {
        if (isOtpLoading) {
            return '';
        }
        if (digitLeft === 0) {
            return `Let's go`;
        }
        return `${digitLeft} Digit Left`;
    };

    return (
        <Modal
            title={<SvgIcon icon="GOOGLE_LOGO" />}
            setIsOpen={setIsOpen}
            icon
        >
            <div className="p-2">
                <div className="mt-1 flex justify-center font-merri-bold tracking-tighter text-2xl font-extrabold text-green-700">
                    Two-Factor Authentication - A Breeze!
                </div>
                <div className="flex items-start font-poppins-bold tracking-tight justify-center text-base font-extrabold">
                    Enter 6-digit code from your two factor authentication APP.
                </div>
                <div className="mt-8 flex flex-col">
                    <OtpInput
                        value={otp}
                        onChange={(value: string) => setOtp(value)}
                        numInputs={6}
                        renderSeparator={
                            <span
                                style={{ marginLeft: otp ? '20px' : '13px' }}
                            />
                        }
                        renderInput={(props) => (
                            <input
                                id="inputOtp"
                                {...props}
                                style={{
                                    width: '64px',
                                    height: '72px',
                                    color: 'text-green-600',
                                    background: '#F7F7F7',
                                    border:
                                        otpError && otp.length < 6
                                            ? '1px solid red'
                                            : 'none',
                                    boxShadow:
                                        otp && props.value
                                            ? '0 0 0 2px #9ED79F, 0 0 0 6px #C9E9C9'
                                            : 'none',
                                    borderRadius: 15,
                                    fontSize: '30px',
                                    fontWeight: 500,
                                    textAlign: 'center',
                                }}
                            />
                        )}
                    />
                    {otpError && otp.length < 6 && (
                        <div className="mt-1 flex justify-center text-sm font-poppins-medium text-red-500">
                            {otpError}
                        </div>
                    )}
                </div>

                <PrimaryButton
                    onClick={() => handleVerifyOTP()}
                    disabled={isOtpLoading}
                    loading={isOtpLoading}
                    type="submit"
                    name={getButtonName()}
                    color="#2E672F"
                    variant="filled"
                    isDrawerButton
                    className={classNames(
                        'flex justify-center items-center w-full h-10 rounded-xl text-base !font-extrabold mt-10 pt-1',
                        digitLeft !== 0
                            ? '!bg-zinc-300 !text-gray-400'
                            : 'bg-green-700 text-white'
                    )}
                />
                {/* <div className="mt-[48px] mb-3 font-poppins-medium flex items-center justify-center text-[12px]">
                    Need help? We can help{' '}
                    <a
                        className="pl-1 font-merri-regular text-[#8FB131]"
                        href="/"
                    >
                        Contact us
                    </a>
                </div> */}
            </div>
        </Modal>
    );
};
export default AuthenticationModal;
