import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import classNames from 'classnames';
import { z, ZodType } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PrimaryButton, SvgIcon, TextField } from 'components/Common';
import { LoginFormData } from 'data/types/auth';
import { acceptAup, signIn, verifyOtp } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IVerifyOTP } from 'data/types/request';
import { AuthenticationModal } from 'components/Modal';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import TermsAndConditionsModal from 'components/Modal/TermsAndConditionsModal/TermsAndConditionsModal';
import OtpInput from 'react-otp-input';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export const loginValidation: ZodType<LoginFormData> = z
    .object({
        email: z
            .string()
            .min(1, { message: 'Email is required' })
            .email({ message: 'Invalid email format' }),
        password: z.string().min(1, { message: 'Password is required' }),
    })
    .required();

const LoginForm = () => {
    const navigate = useNavigate();
    const { dispatch } = useAuthContext();
    const [open, setOpen] = useState<boolean>(false);
    const [qrCode, setQrCode] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [otpError, setOtpError] = useState<string>('');
    const [isTimeOutError, setIsTimeOutError] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);

    const digitLeftCount = 6 - otp.length;
    const [isLoading, setIsLoading] = useState(false);
    const { control, handleSubmit, reset } = useForm<LoginFormData>({
        resolver: zodResolver(loginValidation),
        defaultValues: {},
    });

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const payload = {
            email: formData?.email,
            password: formData?.password,
        };
        setIsLoading(true);
        signIn(payload)
            .then((result) => {
                setToken(result?.token);
                // For First time login
                if (!result?.aupStatus) {
                    setIsFirstTimeLogin(true);
                    if (!result?.qr) {
                        setTermsModalOpen(true);
                    }
                } else {
                    setIsFirstTimeLogin(false);
                    setOpen(true);
                }
                setQrCode(result?.qr as string);
                reset({ password: '' });
                showToast('Successfully', 'success');
                setIsLoading(false);
                setIsTimeOutError(false);
                localStorage.removeItem('timeLeft');
            })
            .catch((err) => {
                setIsLoading(false);
                if (err?.errors?.[0]?.code === 'LOGIN_ATTEMPTS_TIMEOUT') {
                    setIsTimeOutError(true);
                    if (!err?.timeLeft) {
                        localStorage.setItem(
                            'timeLeft',
                            String(new Date().getTime())
                        );
                        setTimeLeft(1800);
                    } else {
                        const currentTimestamp = new Date().getTime();
                        const startTimestamp =
                            currentTimestamp - (1800 - err.timeLeft) * 1000;
                        localStorage.setItem(
                            'timeLeft',
                            String(startTimestamp)
                        );
                        setTimeLeft(err?.timeLeft);
                    }
                    return;
                }
                setIsTimeOutError(false);
                localStorage.removeItem('timeLeft');
                const errorMessage =
                    err?.errors?.[0]?.message || err?.message || err;
                showToast(errorMessage || 'something went wrong', 'error');
            });
    });

    const acceptTermsAndPolicy = () => {
        setIsOtpLoading(true);
        acceptAup(token)
            .then((res) => {
                if (res?.isSuccess) {
                    setIsFirstTimeLogin(false);
                }
                if (!qrCode) {
                    setOpen(true);
                }
            })
            .catch((err) => {
                showToast(err?.message, 'error');
            })
            .finally(() => {
                setTermsModalOpen(false);
                setIsOtpLoading(false);
            });
    };

    const onOTPVerify = () => {
        if (!qrCode && isFirstTimeLogin) {
            acceptTermsAndPolicy();
            return;
        }

        if (!otp) {
            setOtpError('Please Enter your OTP');
            return;
        }
        if (otp.length < 6) {
            setOtpError('Enter 6 digit OTP');
            return;
        }
        const payload: IVerifyOTP = { code: otp };
        setIsOtpLoading(true);

        if (isFirstTimeLogin) {
            acceptTermsAndPolicy();
        }

        verifyOtp(payload, token)
            .then((result) => {
                if (result?.token) {
                    localStorage.setItem('token', result?.token);
                }
                if (result?.qr) {
                    setQrCode(result?.qr);
                } else {
                    setQrCode('');
                }
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                    },
                });
                setOtpError('');
                showToast('successfully verified', 'success');
                setOpen(false);
                navigate('/operations');
            })
            .catch((err) => {
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsOtpLoading(false);
                setTermsModalOpen(false);
            });
    };

    const handleTermsModalOpen = () => {
        setTermsModalOpen(true);
    };

    const getButtonName = () => {
        if (isOtpLoading && !termsModalOpen) {
            return '';
        }
        if (digitLeftCount === 0) {
            return `Let's go`;
        }
        return `${digitLeftCount} Digit Left`;
    };

    return (
        <>
            {termsModalOpen && (
                <div className="bg-[#F8FBEACC] flex w-full h-full left-0 items-center absolute justify-center">
                    <TermsAndConditionsModal
                        setTermsModalOpen={setTermsModalOpen}
                        onAccept={onOTPVerify}
                        isLoading={isOtpLoading}
                    />
                </div>
            )}
            <div className="mx-auto flex flex-col items-start justify-center w-[500px] max-[900px]:w-[300px]">
                {qrCode ? (
                    <div className="flex flex-col items-center gap-5">
                        <div>
                            <SvgIcon
                                className="mb-11 flex flex-col items-center"
                                icon="GOOGLE_LOGO"
                            />
                            <div className="flex flex-col items-center gap-4">
                                <div className="mt-1 flex justify-center text-2xl tracking-tighter text-green-700 font-merri-bold">
                                    Two-Factor Authentication - A Breeze!
                                </div>
                                <div className="text-center text-base leading-5 tracking-tight font-poppins-regular">
                                    Download the free{' '}
                                    <span className="text-green-200 font-poppins-bold">
                                        Google Authenticator{' '}
                                    </span>{' '}
                                    App, add a new account, then scan the
                                    Barcode to set up your account.
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full flex-col items-center gap-2">
                            <img
                                src={qrCode}
                                alt="code"
                                height="230px"
                                width="230px"
                            />
                            <OtpInput
                                value={otp}
                                onChange={(value: string) => setOtp(value)}
                                numInputs={6}
                                renderSeparator={
                                    <span style={{ marginLeft: '13px' }} />
                                }
                                renderInput={(props: any) => (
                                    <input
                                        id="inputOtp"
                                        {...props}
                                        style={{
                                            width: '64px',
                                            height: '72px',
                                            color: 'text-green-600',
                                            background: '#F7F7F7',
                                            border:
                                                otpError && otp.length < 6
                                                    ? '1px solid red'
                                                    : 'none',
                                            boxShadow:
                                                otp && props.value
                                                    ? '0 0 0 2px #9ED79F, 0 0 0 6px #C9E9C9'
                                                    : 'none',
                                            borderRadius: 15,
                                            fontSize: '30px',
                                            fontWeight: 500,
                                            textAlign: 'center',
                                            outline: props.value
                                                ? '3px solid #9ED79F'
                                                : '',
                                        }}
                                    />
                                )}
                            />
                        </div>
                        {/* <div className="mt-0 flex w-full items-center justify-end tracking-tight font-poppins-medium text-[12px]">
                            Unable to Scan
                            <div className="cursor-pointer border-b-0 pl-1 font-extrabold tracking-tight text-green-200 font-merri-regular">
                                Contact us
                            </div>
                        </div> */}
                        <PrimaryButton
                            onClick={
                                isFirstTimeLogin
                                    ? handleTermsModalOpen
                                    : onOTPVerify
                            }
                            type="submit"
                            disabled={
                                digitLeftCount !== 0 ||
                                (isOtpLoading && !termsModalOpen)
                            }
                            loading={isOtpLoading && !termsModalOpen}
                            name={getButtonName()}
                            color="#2E672F"
                            variant="filled"
                            isDrawerButton
                            className={classNames(
                                'flex justify-center items-center w-full h-10 rounded-xl text-base pt-1 mt-4',
                                digitLeftCount !== 0
                                    ? '!bg-zinc-300 !text-gray-400'
                                    : 'bg-green-700 text-white'
                            )}
                        />
                        <PrimaryButton
                            onClick={() => {
                                setQrCode('');
                                setOtp('');
                                setOpen(false);
                            }}
                            type="submit"
                            name="Go Back"
                            color="#8FB131"
                            variant="filled"
                            isDrawerButton
                            className={classNames(
                                'flex justify-center items-center w-full h-10 rounded-xl text-base pt-1'
                            )}
                        />
                    </div>
                ) : (
                    <>
                        <div className="flex w-full items-center tracking-tighter text-green-100 font-merri-bold text-[32px]">
                            Unlock the Possibilities of Your Business with
                            Mozarto!
                        </div>
                        <div className="font-normal tracking-tight text-[16px] font-poppins-medium">
                            Enter your credentials below to get started
                        </div>
                        <form onSubmit={onSubmit} className="w-full">
                            <div className="flex flex-col pt-12 w-[100%]">
                                <RFTextField
                                    control={control}
                                    name="email"
                                    fullWidth
                                    label="Email address"
                                    type="email"
                                    asterisk
                                />
                                <div className="mt-2">
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                fullWidth
                                                label="Password"
                                                type="password"
                                                asterisk
                                                className={classNames(
                                                    'text-gray-400',
                                                    !fieldState.error?.message
                                                        ? 'text-gray-400 bg-gray-200'
                                                        : 'bg-red-300 border-0 '
                                                )}
                                                isError={
                                                    !!fieldState.error?.message
                                                }
                                                errorMessage={
                                                    fieldState.error?.message ??
                                                    ''
                                                }
                                                iconPosition="right"
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    {isTimeOutError && (
                                        <ErrorMessage
                                            isTimeOutError={isTimeOutError}
                                            setIsTimeOutError={
                                                setIsTimeOutError
                                            }
                                            timeLeft={timeLeft}
                                            setTimeLeft={setTimeLeft}
                                        />
                                    )}
                                    <div className="mt-0 flex items-center justify-end tracking-tight font-poppins-medium text-[12px]">
                                        Forgot password?{' '}
                                        <Link
                                            className="cursor-pointer bg-transparent border-b-0 pl-1 font-extrabold tracking-tight text-green-200 font-merri-regular"
                                            to="/forgot-password"
                                        >
                                            Request here
                                        </Link>
                                    </div>
                                </div>
                                <PrimaryButton
                                    type="submit"
                                    name={isLoading ? '' : 'Submit'}
                                    loading={isLoading}
                                    disabled={isLoading}
                                    color="#2E672F"
                                    isDrawerButton
                                    variant="filled"
                                    className="hover:bg-[#2E672F] hover:border-green-600 focus:border-green-600 mt-10 w-full pt-1"
                                />
                                {/* <div className="flex items-center justify-start pt-3 tracking-tight text-[12px] font-poppins-medium">
                                    Don’t have an account?
                                    <Link
                                        className="cursor-pointer bg-transparent border-b-0 pl-1 font-extrabold tracking-tight text-green-200 font-merri-regular"
                                        to="/"
                                    >
                                        Contact us
                                    </Link>
                                </div> */}
                            </div>
                        </form>

                        {open && (
                            <AuthenticationModal
                                setIsOpen={setOpen}
                                handleVerifyOTP={
                                    isFirstTimeLogin
                                        ? handleTermsModalOpen
                                        : onOTPVerify
                                }
                                setOtp={setOtp}
                                isOtpLoading={isOtpLoading}
                                otp={otp}
                                otpError={otpError}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};
export default LoginForm;
