import { useEffect, useMemo, useState } from 'react';
import { ICompanyData } from 'data/common';
import { IAppContext } from 'context/Auth/AuthContextValues';
import { showToast } from 'data/utils/toast';
import { getCompanies } from 'services/api/api';

interface UseCompanyOptionListProps {
    auth: IAppContext;
    allCompanies: ICompanyData[];
}

interface UseFetchCompaniesProps {
    isOpen: boolean;
    auth: IAppContext;
}
export const useCompanyOptionList = ({
    auth,
    allCompanies,
}: UseCompanyOptionListProps) => {
    return useMemo(() => {
        if (
            auth.selectedCompany &&
            Object.keys(auth.selectedCompany).length > 0 &&
            auth?.selectedCompany?.name !== 'Select Company or Brands'
        ) {
            return [
                { _id: '', name: 'Select Company' },
                auth.selectedCompany,
            ] as ICompanyData[];
        }
        return [{ _id: '', name: 'Select Company' }, ...allCompanies];
    }, [allCompanies, auth.selectedCompany]);
};

export const useFetchCompanies = ({ isOpen, auth }: UseFetchCompaniesProps) => {
    const [allCompanies, setAllCompanies] = useState<any[]>([]);
    const getAllCompanies = () => {
        const companyId: string = auth.selectedCompany?._id || '';
        const query: string = companyId ? `id=${companyId}&key=Company` : '';
        getCompanies(query)
            .then((res) => {
                setAllCompanies(res?.companies || []);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'Something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        if (!isOpen) return;
        if (!auth.isSuperAdmin) return;

        if (auth.companiesAndBrands.length > 0) {
            setAllCompanies(auth.companiesAndBrands);
        } else {
            getAllCompanies();
        }
    }, [auth.isSuperAdmin, auth.companiesAndBrands, isOpen]);

    return { allCompanies };
};
