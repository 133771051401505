import { RefObject, useEffect, useMemo, useState } from 'react';
import { SIDE_MENU_LIST } from 'data/constants/common.constants';
import { useAuthContext } from '../context/Auth/AuthContext';

interface IUserPermissionProps {
    checkPermission: string;
}

const usePermission = ({ checkPermission }: IUserPermissionProps) => {
    const [isWrite, setIsWrite] = useState(false);

    const {
        auth: { permissions = [] },
    } = useAuthContext();

    useEffect(() => {
        const permission: any = permissions.find(
            (p: any) => p.value === checkPermission
        );
        if (permission) {
            setIsWrite(permission.isWrite);
        } else {
            setIsWrite(false); // or null if you want to indicate no permission found
        }
    }, [permissions, checkPermission]);

    return isWrite;
};

export default usePermission;

export const useSideBarItemAsPerRole = ({ auth }: any) => {
    const getPermissions = (
        permissions: any[] | undefined,
        key: string
    ): boolean =>
        (permissions || []).some(
            (perm: { value: string }) => perm.value === key
        );

    const filterSubMenu = (
        permissions: any[] | undefined,
        subMenu: any[] = []
    ): any[] => subMenu.filter((sub) => getPermissions(permissions, sub.key));

    const getSideBarItemAsPerRole = useMemo<any[]>(() => {
        if (!auth.authUser?.role) return [];
        if (!auth?.isSuperAdmin) {
            return SIDE_MENU_LIST.map((menu) => {
                const isMainMenuPermitted = getPermissions(
                    auth?.permissions,
                    menu.key
                );
                const filteredSubMenu = filterSubMenu(
                    auth?.permissions,
                    menu.subMenu
                );

                if (isMainMenuPermitted) {
                    return {
                        ...menu,
                        subMenu: menu.subMenu, // Return original subMenu if main menu is permitted
                    };
                }

                if (filteredSubMenu.length > 0) {
                    return {
                        ...menu,
                        subMenu: filteredSubMenu, // Return filtered subMenu if main menu is not permitted
                    };
                }

                return null; // Explicitly return null for menus that do not match any conditions
            }).filter(Boolean); // Filter out null values
        }
        return SIDE_MENU_LIST;
    }, [auth?.authUser?.role, auth?.permissions]);

    return {
        getSideBarItemAsPerRole,
    };
};

export const useScrollFadeVisibility = (
    elementRef: RefObject<HTMLDivElement>
) => {
    const [isFadeVisible, setIsFadeVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = elementRef.current;
            if (element) {
                const tolerance = 1;
                const isAtBottom =
                    Math.abs(
                        element.scrollHeight -
                            element.scrollTop -
                            element.clientHeight
                    ) <= tolerance;
                setIsFadeVisible(
                    element.scrollHeight > element.clientHeight && !isAtBottom
                );
            }
        };

        const element = elementRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            handleScroll();
            return () => element.removeEventListener('scroll', handleScroll);
        }
    }, [elementRef]);

    return isFadeVisible;
};
