import './table.css';
import { ITableHeaderProps } from '../common';

export function TableHeader<T>({
    columns,
    isTransactionDetailsTable,
    isSidebarTable,
    isAuditHistoryTable,
    onSort,
}: Readonly<ITableHeaderProps<T>>): JSX.Element {
    const getClassName = (): string => {
        if (isSidebarTable) {
            return '';
        }
        if (isAuditHistoryTable) {
            return '!text-center border border-solid border-t-0 border-[#E3E3E3] last:border-r-0 thHeader bg-[#DEECAA]';
        }
        if (isTransactionDetailsTable) {
            return '!text-center border border-solid border-[#E3E3E3] bg-[#ffffff] thHeader';
        }
        return '!text-center border border-solid border-l-0 border-t-0 border-[#E3E3E3] last:border-r-0 thHeader';
    };

    return (
        <tr>
            {columns.map((column) => {
                return (
                    <th
                        key={`table-head-cell-${column.key}`}
                        style={{
                            textAlign: isSidebarTable ? 'justify' : 'center',
                            cursor: 'pointer',
                            width: column.width ? column.width : '100%',
                        }}
                        onClick={() => onSort(column.key)}
                        className={getClassName()}
                    >
                        <div>{column.title}</div>
                    </th>
                );
            })}
        </tr>
    );
}
