import { useState, useEffect } from 'react';

export interface IBrandsData {
    pspType: string;
    name: string;
    _id: string;
    // other properties of brand data
}

interface Company {
    _id: string;
    brandData: IBrandsData[];
    // other properties of company
}

interface AuthContext {
    selectedCompany?: { _id: string };
    companiesAndBrands?: Company[];
}

const useFilteredBrandData = (auth: AuthContext, pspType: string) => {
    const [brands, setBrands] = useState<IBrandsData[]>([]);

    const getFilteredBrandData = () => {
        const selectedCompanyId = auth?.selectedCompany?._id;
        const companies = auth?.companiesAndBrands || [];

        const getFilteredBrandsFromCompany = (
            company: Company,
            type: string
        ): IBrandsData[] => {
            if (!company?.brandData) return [];
            return company.brandData.filter(
                (brand: IBrandsData) => brand.pspType === type
            );
        };

        const getAllFilteredBrands = (
            Companies: Company[],
            type: string
        ): IBrandsData[] => {
            return Companies.reduce(
                (accumulator: IBrandsData[], company: Company) => {
                    const filteredBrands = getFilteredBrandsFromCompany(
                        company,
                        type
                    );
                    return accumulator.concat(filteredBrands);
                },
                []
            );
        };

        const selectedCompany = companies.find(
            (company: Company) => company?._id === selectedCompanyId
        );

        if (selectedCompany) {
            const filteredBrands = getFilteredBrandsFromCompany(
                selectedCompany,
                pspType
            );
            setBrands(filteredBrands);
        } else {
            const allFilteredBrands = getAllFilteredBrands(companies, pspType);
            setBrands(allFilteredBrands);
        }
    };

    useEffect(() => {
        getFilteredBrandData();
    }, [auth, pspType]);

    return brands;
};

export default useFilteredBrandData;
