import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { PrimaryButton, SvgIcon } from '../../Common';

const ConfirmationModal = ({
    message,
    setIsModalOpen,
    onConfirmation,
    isLoading,
    onCancellation,
    className = '',
}: {
    message: string;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    onConfirmation: () => void;
    isLoading: boolean;
    onCancellation?: () => void;
    className?: string;
}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [setIsModalOpen]);

    return (
        <div
            className={`!top-0 bg-[#F8FBEACC] fixed left-0 w-full !z-[100] h-full flex items-center justify-center ${className}`}
        >
            <div
                ref={modalRef}
                className="fixed bg-white pb-5 w-full max-w-[600px] !h-fit rounded-xl shadow-md z-[100]"
            >
                <div>
                    <div className="flex items-center justify-between bg-[#2e672f] rounded-md rounded-bl-none rounded-br-none px-5 py-3 text-white">
                        <p className="font-poppins-medium text-xl my-0">
                            Confirmation
                        </p>
                        <button
                            type="button"
                            aria-label="close"
                            className="cursor-pointer mt-1 bg-transparent border-none"
                            onClick={() => setIsModalOpen(false)}
                        >
                            <SvgIcon icon="CLOSE_BUTTON_WHITE" />
                        </button>
                    </div>
                    <div className="flex justify-center flex-col items-center py-3">
                        <SvgIcon icon="WARNING" />
                        <span className="text-lg text-gray-400 px-5">
                            {message}
                        </span>
                    </div>
                    <div className="flex gap-5 justify-center px-5 py-3">
                        <PrimaryButton
                            loading={isLoading}
                            disabled={isLoading}
                            name={isLoading ? '' : 'Yes'}
                            onClick={onConfirmation}
                            color="#2E672F"
                            variant="filled"
                            isDrawerButton
                            className="w-32 items-center px-4 font-medium !rounded-[12px]"
                        />
                        <PrimaryButton
                            onClick={() => {
                                if (onCancellation) {
                                    onCancellation();
                                } else {
                                    setIsModalOpen(false);
                                }
                            }}
                            name="No"
                            color="#2E672F"
                            variant="outline"
                            isDrawerButton
                            className="w-32 items-center px-4 font-medium !rounded-[12px]"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
