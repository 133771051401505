import React, { useEffect, useRef, useState } from 'react';
import { duplicateRule, updateRule } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { formatValue } from 'data/utils/common';
import { useNavigate } from 'react-router-dom';
import SvgIcon from '../Icon/SvgIcon';
import Switch from '../Switch/Switch';
import { getOperatorSymbol } from '../../RuleEngine/RuleEngine.type';

export const GroupRuleCard: React.FC<any> = ({
    item,
    dragHandleProps,
    commonProps,
}) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const [isActive, setIsActive] = useState(item?.isActive);
    const { getRuleData, setItem, setIsDeleteModalOpen } = commonProps;
    const [isConditionsExpanded, setIsConditionsExpanded] = useState(false);

    const navigate = useNavigate();
    const [isOverflowing, setIsOverflowing] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    // use effect to identify overflowing content
    useEffect(() => {
        const checkOverflow = () => {
            if (containerRef.current) {
                setIsOverflowing(
                    containerRef.current.scrollWidth >
                        containerRef.current.clientWidth
                );
            }
        };

        checkOverflow();

        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [item, isConditionsExpanded]);

    const handleActive = () => {
        setIsActive(!isActive);
        updateRule({ isActive: !isActive }, item?._id)
            .then((res: any) => {
                showToast(res?.message, 'success');
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    const handleRuleDuplicate = () => {
        duplicateRule(item?._id)
            .then((res: any) => {
                showToast(res?.message, 'success');
                getRuleData();
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    return (
        <div className="border-solid transition-transform border-[1px] border-[#E3E3E3] rounded-2xl m-4">
            <div
                className={` ${
                    isActive ? 'bg-[#BDD864]' : 'bg-[#E3E3E3]'
                } rounded-t-xl h-14 flex items-center justify-between px-5`}
            >
                <span className="flex items-center">
                    <div className="cursor-grab w-5 h-6 flex items-center">
                        <button
                            type="button"
                            aria-label="drag-icon"
                            className="cursor-grab bg-transparent border-none p-0"
                            onTouchStart={(e: any) => {
                                e.preventDefault();
                                document.body.style.overflow = 'hidden';
                                onTouchStart(e);
                            }}
                            onMouseDown={(e: any) => {
                                document.body.style.overflow = 'hidden';
                                onMouseDown(e);
                            }}
                            onTouchEnd={() => {
                                document.body.style.overflow = 'visible';
                            }}
                            onMouseUp={() => {
                                document.body.style.overflow = 'visible';
                            }}
                        >
                            <SvgIcon
                                icon="BIG_DRAG_ICON"
                                className="flex items-center stroke-[#2E672F]"
                            />
                        </button>
                    </div>
                    <p
                        className={`font-poppins-bold text-sm m-0 ${
                            isActive ? 'text-black' : 'text-[#383838]'
                        }`}
                    >
                        {item?.name}
                    </p>
                </span>
                <div className="flex items-center gap-6">
                    <div className="text-xs text-[#2E672F] font-poppins-regular flex items-center gap-6">
                        <p>Conditions: {item?.conditions?.length}</p>
                        <p>Actions: {item?.actions?.length}</p>
                        <button
                            type="button"
                            className="flex items-center gap-1.5 cursor-pointer text-xs text-[#2E672F] font-poppins-regular bg-transparent border-none p-0"
                            onClick={handleActive}
                        >
                            <div>Active</div>
                            <Switch
                                className={`rounded-2xl ${
                                    isActive ? 'bg-[#F8FBEA]' : 'bg-[#A4A4A4]'
                                } `}
                                isRuleEngine
                                isActiveSwitch
                                isToggled={isActive}
                                handleCheck={handleActive}
                            />
                        </button>
                        <button
                            type="button"
                            aria-label="delete-icon"
                            onClick={() => {
                                setIsDeleteModalOpen(true);
                                setItem(item);
                            }}
                            className="cursor-pointer bg-transparent border-none p-0"
                        >
                            <SvgIcon
                                icon="DELETE_ICON_2"
                                className="stroke-2 stroke-[#2E672F] ps-1"
                            />
                        </button>
                        <button
                            type="button"
                            onClick={handleRuleDuplicate}
                            className="cursor-pointer flex items-center gap-2 bg-transparent border-none p-0 text-xs text-[#2E672F] font-poppins-regular"
                        >
                            <div>Duplicate</div>
                            <SvgIcon
                                icon="COPY_ICON"
                                className="stroke-2 stroke-[#2E672F] ps-1"
                            />
                        </button>
                    </div>
                    <button
                        type="button"
                        className="flex items-center gap-2 font-bold cursor-pointer bg-transparent border-none p-0 text-xs text-[#2E672F]"
                        onClick={() => {
                            navigate(
                                `/workflows/orchestration/rule-builder?dataId=${
                                    item?._id as string
                                }`
                            );
                        }}
                    >
                        <div className="text-xs font-merri-bold text-[#2E672F]">
                            View Rule
                        </div>
                        <SvgIcon icon="ARROW_LEFT" />
                    </button>
                </div>
            </div>
            <div className="bg-white p-2 rounded-b-2xl py-4 flex items-center text-xs justify-between">
                <div
                    className={`flex ${
                        isConditionsExpanded ? 'items-start' : 'items-center'
                    }  gap-2 justify-between w-full`}
                >
                    <div className="flex items-center gap-2">
                        <div
                            ref={containerRef}
                            className={`flex gap-x-2 pb-0.5 overflow-auto ${
                                isConditionsExpanded
                                    ? 'flex-wrap gap-y-1 max-w-[75vw]'
                                    : 'max-w-[50vw]'
                            }`}
                        >
                            <div className="py-1">Conditions:</div>
                            {item?.conditions.map(
                                (data: any, index: number) => (
                                    <div
                                        key={data?.label}
                                        className="flex items-center"
                                    >
                                        <div className="bg-[#F2F2F2] py-1 rounded px-2 box-border flex items-center gap-1">
                                            <div className="whitespace-nowrap">
                                                {data?.label}
                                            </div>
                                            <div>
                                                {getOperatorSymbol(
                                                    data?.operator
                                                )}
                                            </div>
                                            <div
                                                className={`${
                                                    isConditionsExpanded
                                                        ? ''
                                                        : 'whitespace-nowrap'
                                                }`}
                                            >
                                                {formatValue(data?.value)}
                                            </div>
                                        </div>
                                        {index < item.conditions.length - 1 && (
                                            <div className="ps-2">and</div>
                                        )}
                                    </div>
                                )
                            )}
                            <div className="h-6 p-1 box-border w-14 bg-[#CEEBFF] text-center rounded">
                                Actions
                            </div>
                            {item?.actions?.map((data: any, index: number) => (
                                <div
                                    key={data?.label}
                                    className="flex items-center"
                                >
                                    <div className="bg-[#F2F2F2] py-1 rounded px-2 box-border flex items-center gap-1">
                                        <div className="whitespace-nowrap">
                                            {data?.label}
                                        </div>
                                        <div>
                                            {getOperatorSymbol(data?.operator)}
                                        </div>
                                        <div
                                            className={`${
                                                isConditionsExpanded
                                                    ? ''
                                                    : 'whitespace-nowrap'
                                            }`}
                                        >
                                            {typeof data?.value === 'string'
                                                ? data?.value
                                                : data?.value.join(', ')}
                                        </div>
                                    </div>
                                    {index < item.actions.length - 1 && (
                                        <div className="ps-2">and</div>
                                    )}
                                </div>
                            ))}
                        </div>
                        {isOverflowing && <div>...</div>}
                    </div>

                    <div>
                        {isConditionsExpanded ? (
                            <SvgIcon
                                icon="MINUS_ICON"
                                className="cursor-pointer"
                                onClick={() => setIsConditionsExpanded(false)}
                            />
                        ) : (
                            isOverflowing && (
                                <SvgIcon
                                    icon="PLUS_ICON"
                                    className="cursor-pointer"
                                    onClick={() =>
                                        setIsConditionsExpanded(true)
                                    }
                                />
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
