import { Dispatch, SetStateAction } from 'react';
import cc from 'currency-codes';
import {
    CardType,
    PaymentProvider,
    TransactionStatus,
} from 'data/constants/payment.constants';
import { Position } from 'reactflow';
import { INodeData } from './RuleBuilder/RuleBuilder.type';
import { transactionTypeOptions } from '../OnBoardCashier/OnBoardCashier.type';

export interface IRuleEngineDrawerProps {
    openDrawer: () => void;
    isOpen: boolean;
    groupRuleData: any;
    getGroupRules: any;
    isEdit: any;
    setIsEdit: Dispatch<SetStateAction<any>>;
}
export interface IGroupRuleEngineProps {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setActiveGroup: Dispatch<SetStateAction<string>>;
    activeGroup: string;
    groupRuleData: any;
    setGroupRuleData: Dispatch<SetStateAction<any>>;
    loading: boolean;
    setIsEdit: Dispatch<SetStateAction<any>>;
}

export interface IGroupRuleDataType {
    isActive: boolean;
    _id: string;
}
export interface IDefaultData {
    [key: string]: Array<string | { _id: string; name: string }>;
}

export interface IPreConditionData {
    key: string;
    operator?: string;
    conditionType?: string;
    values: Array<string> | string | number | undefined;
}

export interface IConditionDataProps {
    title: string;
    conditionType: string | undefined;
    items: Array<string> | string | number | undefined;
    operator?: string;
}

export interface IPreconditionPros {
    showPreconditions: boolean;
    getPreConditionDataList: IPreConditionData[];
    setGetPreConditionDataList: Dispatch<SetStateAction<IPreConditionData[]>>;
}

export enum TransactionStateEnum {
    ALL_PAYMENTS = 'All Payments',
    SUSPENDED = 'Suspended',
    PENDING = 'Pending',
    SUCCESS = 'Success',
    EXPIRED = 'Expired',
    FAILED = 'Failed',
    ERROR = 'Error',
    CAPTURED = 'Captured',
}

export enum TransactionStateEnumLabel {
    ALL_PAYMENTS = 'ALL_PAYMENTS',
    SUSPENDED = 'SUSPENDED',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    EXPIRED = 'EXPIRED',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
    CAPTURED = 'CAPTURED',
}

const transactionStateOptions = [
    {
        _id: TransactionStateEnumLabel.ALL_PAYMENTS,
        name: TransactionStateEnum.ALL_PAYMENTS,
    },
    {
        _id: TransactionStateEnumLabel.SUSPENDED,
        name: TransactionStateEnum.SUSPENDED,
    },
    {
        _id: TransactionStateEnumLabel.PENDING,
        name: TransactionStateEnum.PENDING,
    },
    {
        _id: TransactionStateEnumLabel.SUCCESS,
        name: TransactionStateEnum.SUCCESS,
    },
    {
        _id: TransactionStateEnumLabel.EXPIRED,
        name: TransactionStateEnum.EXPIRED,
    },
    {
        _id: TransactionStateEnumLabel.FAILED,
        name: TransactionStateEnum.FAILED,
    },
    {
        _id: TransactionStateEnumLabel.ERROR,
        name: TransactionStateEnum.ERROR,
    },
    {
        _id: TransactionStateEnumLabel.CAPTURED,
        name: TransactionStateEnum.CAPTURED,
    },
];

const PaymentMethodOptions = [
    {
        _id: 'DEPOSIT',
        name: 'Deposit',
    },
    {
        _id: 'WITHDRAWAL',
        name: 'Withdrawal',
    },
];

export const defaultData: IDefaultData = {
    Country: ['Spain', 'Portugal'],
    Currency: cc.data.map((data) => data?.code),
    'User category': ['Beginner', 'Mid', 'High'],
    'Tx Currency': cc.data.map((data) => data?.code),
    'Min Total deposit amount': cc.data.map((data) => data?.code),
    'Max Total deposit amount': cc.data.map((data) => data?.code),
    'Min Total withdrawal amount': cc.data.map((data) => data?.code),
    'Max Total withdrawal amount': cc.data.map((data) => data?.code),
    PSP: PaymentProvider,
    'Transaction Status': TransactionStatus,
    'PSP Used before': PaymentProvider,
    'Initiated PSP': PaymentProvider,
    'PSP last used': PaymentProvider,
    'Tx type': transactionTypeOptions,
    'Bin card brand': CardType,
    'Tx state': transactionStateOptions,
    Methods: transactionTypeOptions,
    Method: transactionTypeOptions,
    'Payment method': PaymentMethodOptions,
};

export interface IPreconditionDropDown {
    handleDelete: (e: any) => void;
    data: IConditionDataProps[];
    setData: any;
    preConditionDataList?: IPreConditionData[];
    getPreConditionDataList: IPreConditionData[];
    setGetPreConditionDataList: Dispatch<SetStateAction<IPreConditionData[]>>;
    setTitle: Dispatch<SetStateAction<string>>;
}

export interface CustomNodeProps {
    data: {
        id: string;
        label: string;
        type: string;
        items: string | string[] | number;
        operation: string;
        setCloseRuleModal: (val: boolean) => void;
        setCloseRuleNode: (val: INodeData) => void;
        srcPos: string;
        actionType: string;
    };
    isConnectable?: boolean;
    // setNodeEvent: Dispatch<SetStateAction<any>>;
    // setCustomNodeData: Dispatch<SetStateAction<any>>;
    // setIsRuleDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
}

export interface IGroupDrawer {
    name: string;
}

export interface ICardPaymentRuleEngine {
    groupId: string;
    handleDeleteGroupRule: () => void;
    isActiveGroup?: boolean;
    groupRuleData?: any;
    isDeleteGroupModalOpen: boolean;
    setIsDeleteGroupModalOpen: Dispatch<SetStateAction<boolean>>;
    deleteGroupLoading: boolean;
}

export interface ISelectedValueType {
    id: string | number;
    name: string;
    isSelected?: boolean;
}

// Regex free text 🛈
export enum ConditionType {
    SELECT = 'SELECT',
    MULTI_SELECTION = 'MULTI_SELECT',
    TEXT_INPUT_REGEX = 'TEXT_REGEX',
    DATEPICKER = 'DATE',
    TEXT_INPUT = 'INPUT',
    NUMBER_INPUT = 'NUMBER',
    PERCENTAGE_INPUT = 'PERCENTAGE',
    INPUT_JOIN = 'MONEY_LIST',
    YES_NO_DROPDOWN = 'YES_NO',
    MONEY_COUNTRY = 'MONEY_COUNTRY',
    TRANSACTION_VELOCITY = 'TRANSACTION_VELOCITY',
    NONE = 'NONE',
}

// Operator for the rule build
export const operators = ['=', '!=', '<', '<=', '>', '>='];

export const getTargetPos = (pos: string) => {
    switch (pos) {
        case 'right':
            return Position.Left;
        case 'top':
            return Position.Bottom;
        case 'bottom':
            return Position.Top;
        default:
            return Position.Left;
    }
};

export const getPosition = (pos: string, srcNode: any) => {
    if (!srcNode?.position) {
        return {
            x: 0,
            y: 0,
        };
    }
    const { x, y } = srcNode.position;

    switch (pos) {
        case 'right':
            return {
                x: Number(x) + 400,
                y: Number(y),
            };
        case 'top':
            return {
                x: Number(x),
                y: Number(y) - 200,
            };
        case 'bottom':
            return {
                x: Number(x),
                y: Number(y) + 200,
            };
        default:
            return {
                x: 0,
                y: 0,
            };
    }
};

export enum IOperatorsTypes {
    EQUAL = 'EQ',
    NOT_EQUAL = 'NEQ',
    IN = 'IN',
    PERCENTAGE = 'PERCENTAGE',
    GREATER_THAN = 'GT',
    GREATER_THAN_OR_EQUAL = 'GTE',
    LESS_THAN = 'LT',
    LESS_THAN_OR_EQUAL = 'LTE',
}

export const getOperatorSymbol = (operator: string) => {
    switch (operator) {
        case IOperatorsTypes.EQUAL:
            return '=';
        case IOperatorsTypes.NOT_EQUAL:
            return '!=';
        case IOperatorsTypes.GREATER_THAN:
            return '>';
        case IOperatorsTypes.GREATER_THAN_OR_EQUAL:
            return '>=';
        case IOperatorsTypes.LESS_THAN:
            return '<';
        case IOperatorsTypes.LESS_THAN_OR_EQUAL:
            return '<=';
        default:
            return operator;
    }
};

export const validateWindowUnit = (
    timeWindow: string,
    windowUnit: number
): boolean => {
    const ranges: Record<string, [number, number]> = {
        HOURS: [1, 24],
        DAYS: [1, 30],
        MONTHS: [1, 12],
    };

    if (!ranges[timeWindow]) return true;
    const [min, max] = ranges[timeWindow];
    return windowUnit >= min && windowUnit <= max;
};

export enum RuleBuilderTabs {
    CONDITION = 'CONDITION',
    ACTION = 'ACTION',
}
