import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Drawer } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { createBrand } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IBrandDrawerProps, IBrands } from '../Brads.type';
import { formSchemaValidation } from '../Brands.schema';
import './index.css';
import { BrandForm } from './BrandForm/BrandForm';
import { getAllCompanyBrands } from '../../companyBrandService';

export const AddBrandsDrawer = ({
    openDrawer,
    isOpen,
    loading,
    editSubBrandData,
    adminUsers,
    companyOptions,
    setBrandLoading,
    setIsOpen,
    getAllBrandsData,
    getUsers,
    brandData,
    brand,
}: IBrandDrawerProps) => {
    const { auth, dispatch } = useAuthContext();

    const { control, handleSubmit, watch, reset } = useForm<IBrands>({
        resolver: zodResolver(formSchemaValidation(auth.isSuperAdmin)),
        mode: 'onChange',
        defaultValues: {},
    });

    useEffect(() => {
        if (!brand) return;
        reset(brandData);
    }, [reset, brand]);

    const onSubmit = handleSubmit((formData: IBrands) => {
        if (!formData) return;
        const adminUser = adminUsers?.filter(
            (value) => value.name === watch('adminUser')
        )?.[0];
        const payload = {
            name: formData?.name,
            adminUser: adminUser?.adminUserId,
            companyId: auth.isSuperAdmin
                ? formData?.superAdminCompany
                : auth?.authUser?.company,
            logo: 'string',
            merchantId: '',
            pspType: formData?.pspType,
            locals: formData?.locals,
            currencies: formData?.currencies,
            countries: formData?.countries,
            description: formData?.description,
        };
        createBrand(payload)
            .then((res) => {
                getAllBrandsData();
                getAllCompanyBrands(dispatch, setBrandLoading);
                setBrandLoading(false);
                reset({});
                setIsOpen(false);
                if (res?.errors?.length) {
                    showToast(res.errors[0].msg);
                }
                showToast(res.message);
            })
            .catch((err) => {
                setBrandLoading(false);
                showToast(
                    err?.errors?.[0]?.msg ||
                        err?.message ||
                        'something went wrong',
                    'error'
                );
            });
    });

    useEffect(() => {
        if (!isOpen) return;
        return () => {
            reset({});
        };
    }, [reset, isOpen]);

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={() => openDrawer()}
            drawerDirection="right"
            className={classNames(
                'bg-pink-100  max-h-[100vh] relative',
                loading ? '' : 'overflow-y-scroll'
            )}
        >
            <BrandForm
                onSubmit={onSubmit}
                control={control}
                openDrawer={() => openDrawer()}
                companyOptionList={companyOptions}
                isSuperAdmin={auth.isSuperAdmin}
                brand={brand}
                loading={loading}
                editSubBrandData={editSubBrandData}
                getUsers={getUsers}
                adminUsers={adminUsers}
            />
        </Drawer>
    );
};
