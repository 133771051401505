import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import OtpInput from 'react-otp-input';
import { loginLogo } from 'assets';
import { ResetPassword } from 'components/AuthComponent';
import { IVerifyOTP } from 'data/types/request';
import { createNewPassword, acceptAup, verifyOtp } from 'services/api/api';
import { IPasswordData } from 'data/types/auth';
import { showToast } from 'data/utils/toast';
import { AuthenticationModal } from 'components/Modal';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import { PrimaryButton } from 'components/Common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { z, ZodType } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import TermsAndConditionsModal from 'components/Modal/TermsAndConditionsModal/TermsAndConditionsModal';

export const formValidation: ZodType<IPasswordData> = z
    .object({
        password: z
            .string({ required_error: 'Password is required' })
            .min(12, {
                message: 'Password must be at least 12 characters long',
            })
            .regex(/[A-Z]/, {
                message: 'Password must include at least one uppercase letter',
            })
            .regex(/[a-z]/, {
                message: 'Password must include at least one lowercase letter',
            })
            .regex(/\d/, {
                message: 'Password must include at least one number',
            })
            .regex(/[^A-Za-z0-9]/, {
                message:
                    'Password must include at least one special character (e.g., !, @, #, $, %)',
            }),
        confirmPassword: z.string({
            required_error: 'Confirm Password is required',
        }),
    })
    .refine((data) => data.password === data.confirmPassword, {
        message: 'Passwords do not match',
        path: ['confirmPassword'],
    })
    .refine(
        (data) =>
            data.password !== undefined && data.confirmPassword !== undefined,
        {
            message: 'Both Password and Confirm Password are required',
            path: ['password', 'confirmPassword'],
        }
    );

const NewAdminPassword = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const [otp, setOtp] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const { dispatch } = useAuthContext();
    const [otpError, setOtpError] = useState<string>('');
    const [qrCode, setQrCode] = useState('');
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { control, handleSubmit, reset } = useForm<IPasswordData>({
        resolver: zodResolver(formValidation),
        defaultValues: {},
    });

    const verifyUser = JSON.parse(localStorage.getItem('user') as string);

    const digitLeftCount = 6 - otp.length;

    useEffect(() => localStorage.removeItem('token'), []);

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const payload = {
            password: formData?.password,
            email: verifyUser.email,
        };
        setIsLoading(true);
        createNewPassword(payload)
            .then((result) => {
                setQrCode(result?.qr);
                if (result?.qr) {
                    setIsFirstTimeLogin(true);
                } else {
                    setIsFirstTimeLogin(false);
                }
                localStorage.setItem('user', JSON.stringify(result.user));
                setToken(result.token);
                showToast('Your password created successfully', 'success');
                reset({});
                setOpen(true);
            })
            .catch((err) => {
                showToast(
                    err || 'Your password not created successfully',
                    'success'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    const onVerifyOtp = () => {
        if (!otp) {
            setOtpError('Please Enter your OTP');
            return;
        }
        if (otp.length < 6) {
            setOtpError('Enter 6 digit OTP');
            return;
        }
        const payload: IVerifyOTP = { code: otp };
        setIsOtpLoading(true);

        if (isFirstTimeLogin) {
            acceptAup(token)
                .then((res) => {
                    if (res?.isSuccess) {
                        setIsFirstTimeLogin(false);
                    }
                })
                .catch((err) => {
                    showToast(err?.message, 'error');
                });
        }

        verifyOtp(payload, token)
            .then((result) => {
                localStorage.setItem('token', result.token);
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                    },
                });
                setOtpError('');
                showToast('successfully verified', 'success');
                setOpen(false);
                navigate('/operations');
            })
            .catch((err) => {
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsOtpLoading(false);
                setTermsModalOpen(false);
            });
    };

    const handleTermsModalOpen = () => {
        setTermsModalOpen(true);
    };

    const getBorderStyle = (value: number) => {
        if (otpError && otp.length < 6) {
            return '1px solid red';
        }
        if (otp && value) {
            return '2px solid #9ED79F';
        }
        return 'none';
    };

    const getButtonName = () => {
        if (isOtpLoading && !termsModalOpen) {
            return '';
        }
        if (digitLeftCount === 0) {
            return `Let's go`;
        }
        return `${digitLeftCount} Digit Left`;
    };

    return (
        <>
            {termsModalOpen && (
                <div className="bg-[#F8FBEACC] flex w-full h-full items-center absolute justify-center">
                    <TermsAndConditionsModal
                        setTermsModalOpen={setTermsModalOpen}
                        onAccept={onVerifyOtp}
                        isLoading={isOtpLoading}
                    />
                </div>
            )}
            <div className="flex w-[100vw] h-[100vh]">
                <div className="flex w-1/2 bg-amber-100">
                    <img
                        src={loginLogo}
                        className="loginLogo"
                        alt="logo"
                        width="100%"
                        height="100%"
                    />
                </div>
                {qrCode ? (
                    <div className="mx-auto flex flex-col items-start justify-center w-[500px] max-[900px]:w-[300px]">
                        <div className="flex flex-col items-center gap-5">
                            <div>
                                <SvgIcon
                                    className="mb-11 flex flex-col items-center"
                                    icon="GOOGLE_LOGO"
                                />
                                <div className="flex flex-col items-center gap-4">
                                    <div className="mt-1 flex justify-center text-2xl tracking-tighter text-green-700 font-merri-bold">
                                        Two-Factor Authentication - A Breeze!
                                    </div>
                                    <div className="text-center text-base leading-5 tracking-tight font-poppins-regular">
                                        Download the free{' '}
                                        <span className="text-green-200 font-poppins-bold">
                                            Google Authenticator{' '}
                                        </span>{' '}
                                        App, add a new account, then scan the
                                        Barcode to set up your account.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full flex-col items-center gap-4 mt-5">
                            <img
                                src={qrCode}
                                alt="code"
                                height="230px"
                                width="230px"
                            />
                            <OtpInput
                                value={otp}
                                onChange={(value: string) => setOtp(value)}
                                numInputs={6}
                                renderSeparator={
                                    <span style={{ marginLeft: '13px' }} />
                                }
                                renderInput={(props: any) => (
                                    <input
                                        id="inputOtp"
                                        {...props}
                                        style={{
                                            width: '64px',
                                            height: '72px',
                                            color: 'text-green-600',
                                            background: '#F7F7F7',
                                            border: getBorderStyle(props.value),
                                            boxShadow:
                                                otp && props.value
                                                    ? '0 0 0 2px #9ED79F, 0 0 0 6px #C9E9C9'
                                                    : 'none',
                                            borderRadius: 15,
                                            fontSize: '30px',
                                            fontWeight: 500,
                                            textAlign: 'center',
                                            outline: props.value
                                                ? '3px solid #9ED79F'
                                                : '',
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <PrimaryButton
                            onClick={
                                isFirstTimeLogin
                                    ? handleTermsModalOpen
                                    : onVerifyOtp
                            }
                            type="submit"
                            disabled={
                                digitLeftCount !== 0 ||
                                (isOtpLoading && !termsModalOpen)
                            }
                            loading={isOtpLoading && !termsModalOpen}
                            name={getButtonName()}
                            color="#2E672F"
                            variant="filled"
                            isDrawerButton
                            className={classNames(
                                'flex justify-center items-center w-full h-10 rounded-xl text-base !font-extrabold pt-1 mt-9',
                                digitLeftCount !== 0
                                    ? '!bg-zinc-300 !text-gray-400'
                                    : 'bg-green-700 text-white'
                            )}
                        />
                    </div>
                ) : (
                    <div className="mx-14 flex w-1/2">
                        <ResetPassword
                            isNewAdmin
                            handleSubmit={onSubmit}
                            control={control}
                            isLoading={isLoading}
                        />
                        {open && (
                            <AuthenticationModal
                                setIsOpen={setOpen}
                                handleVerifyOTP={onVerifyOtp}
                                isOtpLoading={isOtpLoading}
                                setOtp={setOtp}
                                otp={otp}
                                otpError={otpError}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default NewAdminPassword;
