import { Dispatch, SetStateAction } from 'react';
import {
    IBrandsPermissions,
    IGroup,
    IPagePermissions,
    IPermissionData,
} from 'data/common';
import { IGetAllBrandData, IGetAllPageAreasData } from 'data/types/response';
import { TextField } from 'components/Common';
import { IColumnType } from 'components/Common/common';

export const UserDrawerPageAreaTableColumns = (
    isDisabled: boolean,
    selectedGroup: IGroup | undefined | null,
    pageAreaData: IPagePermissions[],
    permissionsData: IPermissionData | null,
    setPageAreaData: Dispatch<SetStateAction<IPagePermissions[]>>
): IColumnType<IGetAllPageAreasData>[] => [
    {
        key: 'name',
        width: '200px',
        title: (
            <span className="font-merri-bold tracking-tight">
                Page Areas <span className="text-red-200">*</span>
            </span>
        ),
    },
    {
        key: 'read',
        width: 10,
        title: <span className="font-poppins-bold text-xs">Read</span>,
        render: (_, i) => (
            <TextField
                disabled={isDisabled}
                checked={
                    isDisabled
                        ? (selectedGroup?.name !== 'Select Group' &&
                              selectedGroup?.permission?.pageAreasPermissions?.find(
                                  (item: IPagePermissions) =>
                                      item?.pageAccessId === i?._id
                              )?.isRead) ||
                          (selectedGroup?.name !== 'Select Group' &&
                              permissionsData?.permission?.pageAreasPermissions?.find(
                                  (value) => value?.pageAccessId === i?._id
                              )?.isRead)
                        : (selectedGroup?.name !== 'Select Group' &&
                              (pageAreaData?.find(
                                  (item) => item?.pageAccessId === i?._id
                              )?.isRead ||
                                  pageAreaData?.find(
                                      (item) => item?.pageAccessId === i?._id
                                  )?.isWrite)) ||
                          false
                }
                onChange={() => {
                    const record = pageAreaData?.find(
                        (item) => item?.pageAccessId === i?._id
                    );
                    const updated = pageAreaData?.map((ite) => {
                        if (
                            record &&
                            record?.isRead &&
                            ite?.pageAccessId === record?.pageAccessId &&
                            !record?.isWrite
                        ) {
                            return {
                                ...record,
                                pageAccessId: '',
                                isRead: false,
                                isWrite: false,
                            } as IPagePermissions;
                        }

                        if (
                            record &&
                            !record.isRead &&
                            ite?.pageAccessId === record?.pageAccessId
                        ) {
                            return {
                                ...ite,
                                isRead: true,
                            } as IPagePermissions;
                        }

                        return ite;
                    });

                    if (setPageAreaData) {
                        setPageAreaData(
                            updated?.filter((item) => item?.pageAccessId !== '')
                        );
                    }

                    if (!record) {
                        if (setPageAreaData) {
                            setPageAreaData([
                                ...pageAreaData,
                                {
                                    pageAccessId: i?._id,
                                    isRead: true,
                                    isWrite: false,
                                } as IPagePermissions,
                            ]);
                        }
                    }
                }}
                value={i?.name}
                name="read"
                isCheckbox
                type="checkbox"
            />
        ),
    },
    {
        key: 'write',
        width: 10,
        title: <span className="font-poppins-bold text-xs">Write</span>,
        render: (_, i) => (
            <TextField
                disabled={isDisabled}
                checked={
                    isDisabled
                        ? (selectedGroup?.name !== 'Select Group' &&
                              selectedGroup?.permission?.pageAreasPermissions?.find(
                                  (item: IPagePermissions) =>
                                      item?.pageAccessId === i?._id
                              )?.isWrite) ||
                          (selectedGroup?.name !== 'Select Group' &&
                              permissionsData?.permission?.pageAreasPermissions?.find(
                                  (value) => value?.pageAccessId === i?._id
                              )?.isWrite)
                        : pageAreaData?.find(
                              (item) => item?.pageAccessId === i?._id
                          )?.isWrite || false
                }
                onChange={() => {
                    const record = pageAreaData?.find(
                        (item) => item?.pageAccessId === i?._id
                    );
                    const updated = pageAreaData?.map((ite) => {
                        if (
                            record &&
                            record?.isWrite &&
                            ite?.pageAccessId === record?.pageAccessId &&
                            !record.isRead
                        ) {
                            return {
                                ...record,
                                pageAccessId: '',
                                isRead: false,
                                isWrite: false,
                            } as IPagePermissions;
                        }
                        if (
                            record &&
                            record?.isWrite &&
                            ite?.pageAccessId === record?.pageAccessId
                        ) {
                            return {
                                ...record,
                                isWrite: false,
                                isRead: false,
                            } as IPagePermissions;
                        }
                        if (
                            record &&
                            !record.isWrite &&
                            ite?.pageAccessId === record?.pageAccessId
                        ) {
                            return {
                                ...ite,
                                isWrite: true,
                                isRead: true,
                            } as IPagePermissions;
                        }

                        return ite;
                    });
                    if (setPageAreaData) {
                        setPageAreaData(
                            updated?.filter((item) => item?.pageAccessId !== '')
                        );
                    }
                    if (record && record.isWrite && record.isRead) {
                        const checkList = pageAreaData?.filter(
                            (item) => item?.pageAccessId !== i?._id
                        );
                        if (setPageAreaData) {
                            setPageAreaData(checkList);
                        }
                    }
                    if (!record) {
                        if (setPageAreaData) {
                            setPageAreaData([
                                ...pageAreaData,
                                {
                                    pageAccessId: i?._id,
                                    isRead: true,
                                    isWrite: true,
                                } as IPagePermissions,
                            ]);
                        }
                    }
                }}
                value={i?.name}
                name="write"
                isCheckbox
                type="checkbox"
            />
        ),
    },
];

export const UserDrawerAccessTableColumns = (
    isDisabled: boolean,
    selectedGroup: IGroup | undefined | null,
    accessAreaData: IBrandsPermissions[],
    permissionsData: IPermissionData | null,
    setAccessAreaData: Dispatch<SetStateAction<IBrandsPermissions[]>>
): IColumnType<IGetAllBrandData>[] => [
    {
        key: 'name',
        width: '200px',
        title: (
            <span className="font-merri-bold">
                Access <span className="text-red-200">*</span>
            </span>
        ),
    },
    {
        key: 'read',
        width: 10,
        title: <span className="font-poppins-bold text-xs">Read</span>,
        render: (_, i) => (
            <TextField
                disabled={isDisabled}
                checked={
                    isDisabled
                        ? (selectedGroup?.name !== 'Select Group' &&
                              selectedGroup?.permission?.brandsPermissions?.find(
                                  (item: IBrandsPermissions) =>
                                      item?.brandId === i?._id
                              )?.isRead) ||
                          (selectedGroup?.name !== 'Select Group' &&
                              permissionsData?.permission?.brandsPermissions?.find(
                                  (value) => value?.brandId === i?._id
                              )?.isRead)
                        : accessAreaData?.find(
                              (item) => item?.brandId === i?._id
                          )?.isRead ||
                          accessAreaData?.find(
                              (item) => item?.brandId === i?._id
                          )?.isWrite ||
                          false
                }
                onChange={() => {
                    const record = accessAreaData?.find(
                        (item) => item?.brandId === i?._id
                    );
                    const updated = accessAreaData?.map((ite) => {
                        if (
                            record &&
                            record?.isRead &&
                            ite.brandId === record?.brandId &&
                            !record?.isWrite
                        ) {
                            return {
                                ...record,
                                brandId: '',
                                isRead: false,
                                isWrite: false,
                            } as IBrandsPermissions;
                        }

                        if (
                            record &&
                            !record.isRead &&
                            ite?.brandId === record?.brandId
                        ) {
                            return {
                                ...ite,
                                isRead: true,
                            } as IBrandsPermissions;
                        }

                        return ite;
                    });
                    if (setAccessAreaData) {
                        setAccessAreaData(
                            updated?.filter((item) => item?.brandId !== '')
                        );
                    }

                    if (!record) {
                        if (setAccessAreaData) {
                            setAccessAreaData([
                                ...accessAreaData,
                                {
                                    brandId: i._id,
                                    isRead: true,
                                    isWrite: false,
                                } as IBrandsPermissions,
                            ]);
                        }
                    }
                }}
                value={i?.name}
                name="read"
                isCheckbox
                type="checkbox"
            />
        ),
    },
    {
        key: 'write',
        width: 10,
        title: <span className="font-poppins-bold text-xs">Write</span>,
        render: (_, i) => (
            <TextField
                disabled={isDisabled}
                checked={
                    isDisabled
                        ? (selectedGroup?.name !== 'Select Group' &&
                              selectedGroup?.permission?.brandsPermissions?.find(
                                  (item) => item?.brandId === i?._id
                              )?.isWrite) ||
                          (selectedGroup?.name !== 'Select Group' &&
                              permissionsData?.permission?.brandsPermissions?.find(
                                  (value) => value?.brandId === i?._id
                              )?.isWrite)
                        : accessAreaData?.find(
                              (item) => item?.brandId === i?._id
                          )?.isWrite || false
                }
                onChange={() => {
                    const record = accessAreaData?.find(
                        (item) => item?.brandId === i._id
                    );
                    const updated = accessAreaData?.map((ite) => {
                        if (
                            record &&
                            record.isWrite &&
                            ite?.brandId === record?.brandId &&
                            !record.isRead
                        ) {
                            return {
                                ...record,
                                brandId: '',
                                isRead: false,
                                isWrite: false,
                            } as IBrandsPermissions;
                        }
                        if (
                            record &&
                            record.isWrite &&
                            ite?.brandId === record?.brandId
                        ) {
                            return {
                                ...record,
                                isWrite: false,
                                isRead: false,
                            } as IBrandsPermissions;
                        }
                        if (
                            record &&
                            !record.isWrite &&
                            ite?.brandId === record?.brandId
                        ) {
                            return {
                                ...ite,
                                isWrite: true,
                                isRead: true,
                            } as IBrandsPermissions;
                        }

                        return ite;
                    });
                    if (setAccessAreaData) {
                        setAccessAreaData(
                            updated?.filter((item) => item?.brandId !== '')
                        );
                    }
                    if (record && record.isWrite && record.isRead) {
                        const checkList = accessAreaData?.filter(
                            (item) => item?.brandId !== i?._id
                        );
                        if (setAccessAreaData) {
                            setAccessAreaData(checkList);
                        }
                    }
                    if (!record) {
                        if (setAccessAreaData) {
                            setAccessAreaData([
                                ...accessAreaData,
                                {
                                    brandId: i?._id,
                                    isRead: true,
                                    isWrite: true,
                                } as IBrandsPermissions,
                            ]);
                        }
                    }
                }}
                value={i?.name}
                name="write"
                isCheckbox
                type="checkbox"
            />
        ),
    },
];
