import { ReactNode } from 'react';
import { Switch, TextField } from 'components/Common';
import { getDateFormat, getFirstDateFormat } from 'data/utils/common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { IColumnType } from 'components/Common/common';
import { ICreateUserData } from 'data/common';
import { UserTableColumnProps } from '../User.type';
import { GetStatus } from '../User';

export const UserTableColumn = ({
    isAllChecked,
    setCheckedUsers,
    checkedUsers,
    companyUsers,
    setUserData,
    setIsEditUser,
    setIsOpen,
    isAllowedUser,
    setShowDisableModal,
    setSelectedUser,
}: UserTableColumnProps): IColumnType<ICreateUserData>[] => {
    const handleSelectAll = () => {
        const filteredUsers =
            companyUsers?.filter((user) => !user?.isDeleted) ?? [];
        setCheckedUsers(filteredUsers?.map((item) => item?._id));
    };

    const handleDeselectAll = () => {
        setCheckedUsers([]);
    };

    return [
        {
            key: 'checked',
            width: 20,
            title: (
                <div className="flex justify-center">
                    <TextField
                        onChange={() =>
                            isAllChecked
                                ? handleDeselectAll()
                                : handleSelectAll()
                        }
                        isCheckbox
                        checked={isAllChecked}
                        type="checkbox"
                    />
                </div>
            ),
            render: (_, i) => (
                <div className="flex justify-center">
                    <TextField
                        isCheckbox
                        disabled={i?.isDeleted}
                        checked={
                            checkedUsers?.find((item) => item === i._id) ===
                            i._id
                        }
                        onChange={() => {
                            if (checkedUsers?.includes(i._id) || isAllChecked) {
                                setCheckedUsers(
                                    checkedUsers?.filter(
                                        (item) => item !== i?._id
                                    )
                                );
                            } else {
                                setCheckedUsers([...checkedUsers, i?._id]);
                            }
                        }}
                        type="checkbox"
                    />
                </div>
            ),
        },
        {
            key: 'mid',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        MID
                    </div>
                </div>
            ),
            width: 100,
        },
        {
            key: 'name',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Name
                    </div>
                </div>
            ),
            width: 200,
        },
        {
            key: 'email',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Email
                    </div>
                </div>
            ),
            width: 200,
        },

        {
            key: 'lastLogin',
            width: 200,
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Last Logged In
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div>
                    {i?.lastLogin
                        ? getDateFormat(i.lastLogin)
                        : "Hasn't logged in"}
                </div>
            ),
        },
        {
            key: 'status',
            width: 100,
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Status
                    </div>
                </div>
            ),
            render: (_, i) => {
                let status: ReactNode;
                if (i?.failedAttempts && i.failedAttempts >= 4) {
                    status = GetStatus('disabled');
                } else if (i?.isDisabled) {
                    status = GetStatus('suspended');
                } else {
                    status = GetStatus(i?.isDeleted);
                }
                return <div className="flex justify-center">{status}</div>;
            },
        },
        {
            key: 'updatedBy',
            width: 200,
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Last Modified By
                    </div>
                </div>
            ),
            render: (_, i) => <div>{i.updatedBy ?? '-'}</div>,
        },
        {
            key: 'createdAt',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Created
                    </div>
                </div>
            ),
            render: (_, i) => <div>{getFirstDateFormat(i.createdAt)}</div>,
            width: 200,
        },
        {
            key: 'edit',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Edit
                    </div>
                </div>
            ),
            width: 40,
            render: (_, i) => (
                <div>
                    <SvgIcon
                        className={`cursor-pointer ${
                            !isAllowedUser
                                ? 'pointer-events-none opacity-50'
                                : ''
                        }`}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (i?.isDeleted) return;
                            setIsEditUser(true);
                            setUserData(i);
                            setIsOpen(true);
                        }}
                        icon="EDIT_ICON"
                    />
                </div>
            ),
        },
        {
            key: 'disable',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Disable
                    </div>
                </div>
            ),
            width: 40,
            render: (_, i) => (
                <Switch
                    disabled={!isAllowedUser}
                    handleCheck={() => {
                        setShowDisableModal(true);
                        setSelectedUser(i);
                    }}
                    isToggled={i.isDeleted}
                    className="rounded-2xl bg-gray-200"
                />
            ),
        },
    ];
};
