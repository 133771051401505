import React, { useState } from 'react';
import { PrimaryButton, Select, Tooltip } from 'components/Common';
import { bulkActionForBrand } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import { BrandsHeaderProps, Options } from '../Brads.type';
import { getAllCompanyBrands } from '../../companyBrandService';

export const BrandsHeader = ({
    getAllBrandsData,
    setCheckedBrands,
    checkedBrands,
    openDrawer,
    isAllowedUser,
}: BrandsHeaderProps) => {
    const { dispatch } = useAuthContext();
    const [selectedValue, setSelectedValue] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleBrandDisable = () => {
        if (!selectedValue) return;
        if (selectedValue !== '1') {
            bulkActionForBrand({
                brandIds: checkedBrands,
                type: selectedValue.toUpperCase(),
            })
                .then((res) => {
                    if (res.isSuccess) {
                        getAllCompanyBrands(dispatch, setIsLoading);
                        getAllBrandsData();
                        showToast(res.message);
                        setCheckedBrands([]);
                    }
                })
                .catch((err) => {
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsConfirmationModal(false);
                });
        }
    };

    return (
        <div className="mb-5 flex w-full items-center justify-between max-[970px]:flex-col">
            {isConfirmationModal && (
                <ConfirmationModal
                    onConfirmation={handleBrandDisable}
                    message="Are you sure you want to disable selected brands?"
                    isLoading={isLoading}
                    setIsModalOpen={setIsConfirmationModal}
                    onCancellation={() => {
                        setIsConfirmationModal(false);
                    }}
                />
            )}
            <div className="font-merri-bold tracking-tighter text-2xl text-[#131119]">
                Sub Brands
            </div>
            <div className="flex items-center gap-4">
                <Tooltip
                    text={
                        checkedBrands?.length === 0
                            ? 'Please select first the Mids from the table that you want to edit'
                            : ''
                    }
                    className="w-44"
                >
                    <Select
                        isDisabled={!checkedBrands?.length}
                        isGroup
                        isMarginBottom={false}
                        options={Options}
                        handleOnSelect={(event) => {
                            setSelectedValue(event.target.value);
                            setIsConfirmationModal(true);
                        }}
                        className="w-full max-w-44 !pr-2 !border-2 !border-[#818181] !rounded-xl px-4 !text-xs !h-11"
                    />
                </Tooltip>
                <div className="w-[120px]">
                    <PrimaryButton
                        disabled={!isAllowedUser}
                        onClick={openDrawer}
                        type="button"
                        name="Add Sub Brand"
                        color="#8FB131"
                        variant="filled"
                        className="!rounded-xl px-4 !w-[120px] !text-xs !h-11"
                    />
                </div>
            </div>
        </div>
    );
};
