import { useEffect, useState } from 'react';
import { ICompanyData } from 'data/common';
import { getCompanies } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { useAuthContext } from 'context/Auth/AuthContext';
import { UserRoles } from 'data/constants/common.constants';
import { CompaniesTable } from './CompaniesTable';
import { CompaniesHeader } from './CompaniesHeader';
import AddCompaniesDrawer from './CompaniesDrawer/AddCompaniesDrawer';
import EditCompaniesDrawer from './CompaniesDrawer/EditCompaniesDrawer';

const Companies = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isEditCompany, setIsEditCompany] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [company, setCompany] = useState<ICompanyData | null>(null);
    const [allCompanies, setAllCompanies] = useState<ICompanyData[]>([]);
    const [checkedCompanies, setCheckedCompanies] = useState<string[]>([]);

    const { auth } = useAuthContext();

    const getAllCompanies = () => {
        setIsLoading(true);
        let companyId = '';
        if (auth?.authUser?.role === UserRoles.ADMIN) {
            companyId = auth?.authUser.company ?? '';
        } else if (auth?.authUser?.role === UserRoles.SUPER_ADMIN) {
            companyId = auth.selectedCompany?._id ?? '';
        }
        const query: string = companyId ? `id=${companyId}` : '';
        getCompanies(query)
            .then((res) => {
                setAllCompanies(res?.companies || []);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getAllCompanies();
    }, [auth?.selectedCompany, auth?.selectedBrand]);

    const openDrawer = () => {
        setIsEditCompany(false);
        setCompany(null);
        setIsOpen(!isOpen);
    };

    return (
        <div className="p-5">
            <CompaniesHeader
                getAllCompanies={getAllCompanies}
                openDrawer={openDrawer}
                setCheckedCompanies={setCheckedCompanies}
                checkedCompanies={checkedCompanies}
            />

            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <CompaniesTable
                    setCompany={setCompany}
                    setIsEditCompany={setIsEditCompany}
                    setIsOpen={setIsOpen}
                    allCompanies={allCompanies}
                    setAllCompanies={setAllCompanies}
                    setCheckedCompanies={setCheckedCompanies}
                    checkedCompanies={checkedCompanies}
                />
            )}

            {isEditCompany ? (
                <EditCompaniesDrawer
                    isOpen={isOpen}
                    company={company}
                    openDrawer={openDrawer}
                    setCompany={setCompany}
                    getAllCompanies={getAllCompanies}
                    setIsOpen={setIsOpen}
                    isEditCompany={isEditCompany}
                />
            ) : (
                <AddCompaniesDrawer
                    isOpen={isOpen}
                    company={company}
                    openDrawer={openDrawer}
                    setCompany={setCompany}
                    getAllCompanies={getAllCompanies}
                    setIsOpen={setIsOpen}
                />
            )}
        </div>
    );
};

export default Companies;
