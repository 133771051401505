import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { loginLogo } from 'assets';
import { LoginForm } from 'components/AuthComponent';
import { showToast } from '../../data/utils/toast';

const Login = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            return navigate('/operations');
        }

        const storedErrorMessage = localStorage.getItem('authErrorMessage');
        if (storedErrorMessage) {
            const errorObj = JSON.parse(storedErrorMessage);

            if (errorObj) {
                localStorage.removeItem('authErrorMessage');
                showToast(
                    errorObj?.message === 'jwt expired'
                        ? 'This link has expired please contact your admin'
                        : errorObj?.message,
                    'error'
                );
            }
        }
    }, []);

    return (
        <div className="flex w-[100vw] h-[100vh]">
            <div className="flex w-1/2 bg-amber-100">
                <img
                    src={loginLogo}
                    className="loginLogo"
                    alt="logo"
                    width="100%"
                    height="100%"
                />
            </div>
            <div className="mx-14 flex w-1/2">
                <LoginForm />
            </div>
        </div>
    );
};

export default Login;
