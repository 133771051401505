import { ICompanyData } from 'data/common';
import { Switch, TextField } from 'components/Common';
import { getFirstDateFormat } from 'data/utils/common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { IColumnType } from 'components/Common/common';
import { CompaniesTableParams } from '../Companies.type';

export const CompaniesTableColumn = ({
    isAllChecked,
    checkedCompanies,
    allCompanies,
    setCheckedCompanies,
    setCompany,
    setIsEditCompany,
    setIsOpen,
    setSelectedCompany,
    setIsConfirmationModal,
}: CompaniesTableParams): IColumnType<ICompanyData>[] => {
    const handleSelectAll = () => {
        const filteredCompanies =
            allCompanies?.filter((company) => !company?.isDeleted) ?? [];
        setCheckedCompanies(filteredCompanies?.map((item) => item?._id));
    };

    const handleDeselectAll = () => {
        setCheckedCompanies([]);
    };

    return [
        {
            key: 'checked',
            width: 20,
            title: (
                <div className="flex justify-center">
                    <TextField
                        checked={isAllChecked}
                        isCheckbox
                        onChange={() =>
                            isAllChecked
                                ? handleDeselectAll()
                                : handleSelectAll()
                        }
                        type="checkbox"
                    />
                </div>
            ),
            render: (_, i) => (
                <div className="flex justify-center">
                    <TextField
                        isCheckbox
                        checked={
                            checkedCompanies?.find((item) => item === i._id) ===
                            i._id
                        }
                        disabled={i?.isDeleted}
                        onChange={() => {
                            if (
                                checkedCompanies?.includes(i._id) ||
                                isAllChecked
                            ) {
                                setCheckedCompanies(
                                    checkedCompanies?.filter(
                                        (item) => item !== i?._id
                                    )
                                );
                            } else {
                                setCheckedCompanies([
                                    ...checkedCompanies,
                                    i?._id,
                                ]);
                            }
                        }}
                        type="checkbox"
                    />
                </div>
            ),
        },
        {
            key: 'mid',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        MID
                    </div>
                </div>
            ),
            width: 50,
        },
        {
            key: 'name',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Company Name
                    </div>
                </div>
            ),
            width: 200,
        },
        {
            key: 'adminName',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Admin
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div>
                    {i?.userData?.length > 0 ? i?.userData?.join(', ') : '-'}
                </div>
            ),
            width: 200,
        },
        {
            key: 'createdAt',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Created
                    </div>
                </div>
            ),
            render: (_, i) => <div>{getFirstDateFormat(i.createdAt)}</div>,
            width: 200,
        },
        {
            key: 'edit',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Edit
                    </div>
                </div>
            ),
            width: 200,
            render: (_, i) => (
                <SvgIcon
                    className="cursor-pointer"
                    onClick={() => {
                        setCompany?.(i);
                        setIsEditCompany(true);
                        setIsOpen(true);
                    }}
                    icon="EDIT_ICON"
                />
            ),
        },
        {
            key: 'disable',
            title: (
                <div className="flex justify-center">
                    <div className="text-xs tracking-tight font-poppins-medium">
                        Disable
                    </div>
                </div>
            ),
            width: 200,
            render: (_, i) => (
                <Switch
                    handleCheck={() => {
                        setSelectedCompany(i);
                        setIsConfirmationModal(true);
                    }}
                    isToggled={i.isDeleted}
                    className="rounded-2xl bg-gray-200"
                />
            ),
        },
    ];
};
