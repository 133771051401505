import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Modal, PrimaryButton, StatusBadge } from 'components/Common';
import { ModalConstants } from 'data/constants/common.constants';
import { formatNumber, getDateFormat } from 'data/utils/common';
import { getTransactionStatus } from 'data/utils/transactions';
import RawResponse from 'components/Modal/TransactionDetailsModal/RawResponse';
import { PaymentProviderConstant } from 'data/constants/payment.constants';
import VerticalTable from 'components/VerticalTable/VerticalTable';
import 'components/AuditDrawer/AudtiDrawer.css';
import { getTransactionTabData } from './transactionFields';
import { ActionModal } from '../index';
import { ActionModalProps } from '../Modal.type';
import { getPSPNameByValue } from '../../PaymentMethods/Configuration/configurationUtills';

const TransactionDetailModal = ({
    transaction,
    setIsOpen,
    isTransactionModal,
}: ActionModalProps) => {
    const [openActionModal, setOpenActionModal] = useState(false);
    const [selectedTransactionTab, setSelectedTransactionTab] =
        useState<string>('Payment');
    const modalRef = useRef<HTMLDivElement>(null);

    const data = useMemo(() => {
        return getTransactionTabData(selectedTransactionTab, transaction);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaction, isTransactionModal, selectedTransactionTab]);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            // If the modal is open and the click is outside the modal, close it
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        // Add event listener when modal is open
        document.addEventListener('mousedown', handleDocumentClick);

        // Remove event listener when component is unmounted or modal is closed
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [setIsOpen]);

    const getTransaction = () => {
        const formatTransaction = (
            id: string | undefined,
            type: string,
            currency: string | undefined,
            baseamount: number | undefined,
            applicationName: string | undefined
        ) => ({
            id,
            type,
            status: getTransactionStatus(transaction),
            acceptedBy: transaction?.pspType,
            amount: baseamount
                ? `${formatNumber(Number(baseamount))} ${currency ?? '-'}`
                : '-',
            applicationName: applicationName ?? '-',
        });

        if (!transaction) return null;

        const fullName = transaction?.firstName.concat(
            ` ${transaction.lastName as string}`
        );

        switch (transaction.pspType) {
            case PaymentProviderConstant.CLEO:
                return formatTransaction(
                    transaction._id,
                    transaction.method === 'CLEO_PAYOUT'
                        ? 'Withdrawal'
                        : 'Deposit',
                    transaction.currencyiso3a ?? 'CLP',
                    transaction.baseamount,
                    fullName
                );
            case PaymentProviderConstant.TRUST:
                return formatTransaction(
                    transaction._id,
                    'CARD',
                    transaction.transactionDetails?.currencyiso3a,
                    transaction.baseamount,
                    fullName
                );
            case PaymentProviderConstant.PAYMODUM:
                return formatTransaction(
                    transaction._id,
                    'CARD',
                    transaction.transactionBaseCurrencyConverted,
                    transaction.baseamount,
                    fullName
                );
            case PaymentProviderConstant.BRITE:
                return formatTransaction(
                    transaction._id,
                    'BANK',
                    transaction.currency,
                    transaction.baseamount,
                    fullName
                );
            default:
                return formatTransaction(
                    transaction._id,
                    '-',
                    transaction.currency,
                    transaction.baseamount,
                    fullName
                );
        }
    };

    const transactionFields = getTransaction();

    return (
        <Modal
            classname={`${
                isTransactionModal
                    ? '!p-0 min-[1200px]:!w-[1200px] min-w-[600px] min-[1200px]:max-h-[700px] max-h-[600px] overflow-scroll transactionModal'
                    : ''
            }`}
            isTransactionModal={isTransactionModal}
            title="Transaction Details"
            setIsOpen={setIsOpen}
            headerButton={
                <div className="flex gap-[30px]">
                    {/* <PrimaryButton
                        className="!h-[40px]"
                        // onClick={() => {
                        //     setOpenActionModal(true);
                        // }}
                        isDrawerButton
                        color="#2E672F"
                        variant="filled"
                        name="Manual Update"
                    />{' '}
                        <PrimaryButton
                        onClick={() => {}}
                        isDrawerButton
                        className="!h-[40px]"
                        color="#8FB131"
                        variant="filled"
                        name="Export"
                        /> */}
                    {openActionModal && (
                        <ActionModal
                            className="actionModal"
                            setIsOpen={setOpenActionModal}
                        />
                    )}
                </div>
            }
            icon
        >
            <div ref={modalRef}>
                <div className="flex flex-col bg-[#FAFAF5] p-9">
                    <div className="flex items-start !text-start text-[#131119] font-poppins-bold tracking-tight text-lg">
                        Transaction # {transactionFields?.id}
                    </div>

                    <div className="flex flex-wrap items-center gap-x-5 w-[900px]">
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="tracking-tight font-poppins-bold">
                                Transaction Type
                            </p>
                            <p className="bg-[#73C5FF] p-2 rounded-lg">
                                <div className="text-xs capitalize font-poppins-bold">
                                    {transactionFields?.type?.toLowerCase()}
                                </div>
                            </p>
                        </div>{' '}
                        <div className="flex items-center justify-between gap-x-2 gap-y-1">
                            <p className="tracking-tight font-poppins-bold">
                                Transaction Status
                            </p>

                            <StatusBadge
                                fullWidth={false}
                                status={transaction?.status}
                            />
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="tracking-tight font-poppins-bold">
                                Accepted By
                            </p>
                            <p className="bg-[#FFF0BB] p-2 rounded-lg capitalize font-poppins-bold text-xs">
                                {getPSPNameByValue(
                                    transactionFields?.acceptedBy
                                )}
                            </p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="tracking-tight font-poppins-bold">
                                Amount
                            </p>
                            <p className="font-poppins-regular">
                                {transactionFields?.amount ?? '-'}
                            </p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="tracking-tight font-poppins-bold">
                                Application Name
                            </p>
                            <p className="font-poppins-regular">
                                {transactionFields?.applicationName ?? '-'}
                            </p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="tracking-tight font-poppins-bold">
                                Application Key
                            </p>
                            <p className="font-poppins-regular">
                                {transaction?.externalKey ?? '-'}
                            </p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="tracking-tight font-poppins-bold">
                                Created
                            </p>
                            <p className="font-poppins-regular">
                                {getDateFormat(
                                    transaction?.pspType !== 'TRUST'
                                        ? ((transaction?.createdAt ??
                                              transaction?.updateAt ??
                                              transaction?.created) as string)
                                        : transaction?.createdAt ??
                                              transaction?.updatedAt ??
                                              transaction?.created
                                )}
                            </p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="tracking-tight font-poppins-bold">
                                Modified
                            </p>
                            <p className="font-poppins-regular">
                                {getDateFormat(
                                    transaction?.pspType !== 'TRUST'
                                        ? ((transaction?.updateAt ??
                                              transaction?.createdAt ??
                                              transaction?.created) as string)
                                        : transaction?.updatedAt ??
                                              transaction?.createdAt ??
                                              transaction?.created
                                )}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="p-9 pt-2">
                    <div className="flex gap-x-5 p-7 pl-0">
                        <PrimaryButton
                            onClick={(
                                e:
                                    | React.MouseEvent<HTMLElement, MouseEvent>
                                    | undefined
                            ) => {
                                setSelectedTransactionTab(
                                    (e?.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl !h-8 px-5 !text-xs pt-1',
                                selectedTransactionTab === 'Payment'
                                    ? ' !bg-[#DEECAA] !font-black !text-black'
                                    : ' !bg-transparent !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.PAYMENT}
                        />
                        <PrimaryButton
                            onClick={(
                                e:
                                    | React.MouseEvent<HTMLElement, MouseEvent>
                                    | undefined
                            ) => {
                                setSelectedTransactionTab(
                                    (e?.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl !h-8 px-5 !text-xs pt-1',
                                selectedTransactionTab === 'User Information'
                                    ? ' !bg-[#DEECAA] !font-black !text-black'
                                    : ' !bg-transparent !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.USER_INFORMATION}
                        />{' '}
                        <PrimaryButton
                            onClick={(
                                e:
                                    | React.MouseEvent<HTMLElement, MouseEvent>
                                    | undefined
                            ) => {
                                setSelectedTransactionTab(
                                    (e?.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl !h-8 px-5 !text-xs pt-1',
                                selectedTransactionTab ===
                                    'Transaction Information'
                                    ? ' !bg-[#DEECAA] !font-black !text-black'
                                    : ' !bg-transparent !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.TRANSACTION_INFORMATION}
                        />{' '}
                        <PrimaryButton
                            onClick={(
                                e:
                                    | React.MouseEvent<HTMLElement, MouseEvent>
                                    | undefined
                            ) => {
                                setSelectedTransactionTab(
                                    (e?.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl !h-8 px-5 !text-xs pt-1',
                                selectedTransactionTab === 'KYC Status'
                                    ? ' !bg-[#DEECAA] !font-black !text-black'
                                    : ' !bg-transparent !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.KYC_STATUS}
                        />{' '}
                        <PrimaryButton
                            onClick={(
                                e:
                                    | React.MouseEvent<HTMLElement, MouseEvent>
                                    | undefined
                            ) => {
                                setSelectedTransactionTab(
                                    (e?.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl !h-8 px-5 !text-xs pt-1',
                                selectedTransactionTab === 'Other'
                                    ? ' !bg-[#DEECAA] !font-black !text-black'
                                    : ' !bg-transparent !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.OTHER}
                        />
                        <PrimaryButton
                            onClick={(
                                e:
                                    | React.MouseEvent<HTMLElement, MouseEvent>
                                    | undefined
                            ) => {
                                setSelectedTransactionTab(
                                    (e?.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl !h-8 px-5 !text-xs pt-1',
                                selectedTransactionTab === 'Financial'
                                    ? ' !bg-[#DEECAA] !font-black !text-black'
                                    : ' !bg-transparent !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.FINANCIAL}
                        />{' '}
                        <PrimaryButton
                            onClick={(
                                e:
                                    | React.MouseEvent<HTMLElement, MouseEvent>
                                    | undefined
                            ) => {
                                setSelectedTransactionTab(
                                    (e?.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl !h-8 px-5 !text-xs pt-1',
                                selectedTransactionTab === 'Raw Response'
                                    ? ' !bg-[#DEECAA] !font-black !text-black'
                                    : ' !bg-transparent !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name="Raw Response"
                        />
                    </div>
                    {selectedTransactionTab === ModalConstants.RAW_RESPONSE ? (
                        <RawResponse transaction={transaction} />
                    ) : (
                        <div className="mt-2 flex w-full max-w-full items-center justify-start">
                            <VerticalTable data={data ?? []} />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
export default TransactionDetailModal;
