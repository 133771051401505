import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { getLabelColor } from 'data/utils/common';
import '../Input/index.css';
import { ISelectProps } from '../common';
import './Select.css';

const Select = (props: ISelectProps) => {
    const {
        isCompanyAdmin,
        isDisabled,
        value,
        isGroup,
        handleOnSelect,
        options,
        isError = false,
        bordered = false,
        errorMessage,
        fullWidth,
        asterisk,
        label,
        className,
        name,
        isPadding,
        isRightMargin,
        isMarginBottom = true,
        ...rest
    } = props;
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const handleFocusChange = () => {
        setIsFocused(document.activeElement === inputRef.current);
    };

    useEffect(() => {
        // Add event listeners to track focus changes
        document.addEventListener('focus', handleFocusChange, true);
        document.addEventListener('blur', handleFocusChange, true);
        document.addEventListener('hover', handleFocusChange, true);

        // Clean up the event listeners when the component is unmounted
        return () => {
            document.removeEventListener('focus', handleFocusChange, true);
            document.removeEventListener('blur', handleFocusChange, true);
            document.removeEventListener('hover', handleFocusChange, true);
        };
    }, []);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={classNames(
                'flex flex-col ',
                fullWidth && 'w-full',
                isRightMargin && 'mr-[36px]',
                isPadding && 'pl-[0.6rem] pr-7',
                isMarginBottom && 'mb-4'
            )}
        >
            {label && (
                <label
                    className={classNames(
                        'font-poppins-medium tracking-tight text-sm mb-2 text-start',
                        getLabelColor({ isError, isHovered, value, isFocused })
                    )}
                    htmlFor={label}
                >
                    {label}
                    {asterisk && (
                        <span className="!text-red-200 text-lg pl-[2px]">
                            *
                        </span>
                    )}
                </label>
            )}
            <select
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={inputRef}
                disabled={isDisabled}
                defaultValue={value}
                value={value ?? ''}
                className={classNames(
                    className,
                    'flex w-full rounded-md p-3 form-select text-sm text-[#393838] cursor-pointer',
                    bordered
                        ? '!border-2 !border-[#f1f1f1] hover:!border-[#9ed79f]'
                        : 'border-transparent'
                )}
                onChange={handleOnSelect}
                name={label}
                id={label}
                {...rest}
            >
                {options?.map((v, index) => {
                    const getValue = () => {
                        if (
                            index === 0 &&
                            !value &&
                            isGroup &&
                            !isCompanyAdmin
                        ) {
                            return '1';
                        }
                        if (
                            ((isGroup || value) &&
                                index === options.length - 1) ||
                            v?.name === 'No-Groups'
                        ) {
                            return v?._id as string;
                        }
                        return v?._id as string;
                    };

                    return (
                        <option
                            key={v?._id}
                            selected={
                                value
                                    ? v?._id === value ||
                                      v?.permissionId === value
                                    : (index === 0 && !isCompanyAdmin) ||
                                      value === null
                            }
                            value={getValue()}
                            disabled={v?.isDisable}
                        >
                            {isGroup ? v?.name : v?.title}
                        </option>
                    );
                })}
            </select>
            {errorMessage && (
                <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};
export default Select;
