import { useState } from 'react';
import Payout from 'components/PaymentMethods/Simulator/Payout';
import PayIn from 'components/PaymentMethods/Simulator/PayIn';

const TABS = {
    PAYIN: 'PayIn',
    PAYOUT: 'PayOut',
};

const SimulatorPayIn = () => {
    const [activeTab, setActiveTab] = useState(TABS.PAYIN);

    return (
        <>
            <div className="flex items-center text-center gap-3 px-8 my-2">
                <button
                    type="button"
                    onClick={() => setActiveTab(TABS.PAYIN)}
                    className={`cursor-pointer border-none transition-all px-5 w-full max-w-[224px] shadow rounded duration-150 py-2 ${
                        activeTab === TABS.PAYIN
                            ? 'bg-[#2e672f]  text-white'
                            : 'bg-[#f5f5f5]  text-black'
                    }`}
                >
                    PayIn
                </button>
                <button
                    type="button"
                    className={`cursor-pointer border-none transition-all px-5 w-full max-w-[224px] shadow rounded duration-150 py-2 ${
                        activeTab === TABS.PAYOUT
                            ? 'bg-[#2e672f]  text-white'
                            : 'bg-[#f5f5f5]  text-black'
                    }`}
                    onClick={() => setActiveTab(TABS.PAYOUT)}
                >
                    PayOut
                </button>
            </div>
            <div>{activeTab === TABS.PAYIN ? <PayIn /> : <Payout />}</div>
        </>
    );
};

export default SimulatorPayIn;
