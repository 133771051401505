import React, { useEffect, useRef, useState } from 'react';
import UpArrow from 'assets/images/up.svg';
import DownArrow from 'assets/images/down.svg';
import { getCompanyBrands } from 'services/api/api';
import { useAuthContext } from 'context/Auth/AuthContext';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { IBrandData, ICompanyData } from './MultiLevelDropDown.type';

const MultiLevelDropdown = () => {
    const { auth, dispatch } = useAuthContext();
    const [open, setOpen] = useState<boolean>(false);
    const [companies, setCompanies] = useState<ICompanyData[]>([
        { id: '', name: 'Select Company or Brands', brands: [] },
    ]);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const getAllCompanyBrands = () => {
        getCompanyBrands()
            .then((res) => {
                setCompanies(res.companies);
                dispatch({
                    type: AppActionsEnum.SET_COMPANY_AND_BRANDS,
                    payload: {
                        companiesAndBrands: res.companies,
                    },
                });
                if (auth.isSuperAdmin && res.companies) {
                    setCompanies([
                        auth?.isSuperAdmin && {
                            id: '',
                            name: 'Select Company or Brands',
                            brands: [],
                        },
                        ...(res.companies || ''),
                    ]);
                } else {
                    dispatch({
                        type: AppActionsEnum.SET_COMPANY,
                        payload: {
                            selectedCompany: res.companies[0],
                        },
                    });
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    useEffect(() => {
        if (auth.companiesAndBrands.length > 0) {
            if (auth.isSuperAdmin) {
                setCompanies([
                    auth?.isSuperAdmin && {
                        id: '',
                        name: 'Select Company or Brands',
                        brands: [],
                    },
                    ...(auth.companiesAndBrands || ''),
                ]);
            } else {
                setCompanies(auth.companiesAndBrands);
            }
        } else {
            getAllCompanyBrands();
        }
    }, [auth.companiesAndBrands]);

    useEffect(() => {
        const handleClickOutside = (event: TouchEvent | MouseEvent) => {
            const targetElement = event.target as Element;
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target as Node) &&
                !targetElement.closest('.dropdown-toggle')
            ) {
                setOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [wrapperRef]);

    useEffect(() => {
        const selectedLocalCompany = JSON.parse(
            localStorage.getItem('selectedCompany') ?? 'null'
        );
        const selectedLocalBrand = JSON.parse(
            localStorage.getItem('selectedBrand') ?? 'null'
        );
        if (selectedLocalCompany) {
            dispatch({
                type: AppActionsEnum.SET_COMPANY,
                payload: {
                    selectedCompany: selectedLocalCompany,
                },
            });
        }
        if (selectedLocalBrand) {
            dispatch({
                type: AppActionsEnum.SET_BRAND,
                payload: {
                    selectedBrand: selectedLocalBrand,
                },
            });
        }
    }, []);

    const handleCompanyClick = (item: ICompanyData) => {
        const isSelectedCompany = auth.selectedCompany?._id === item?._id;
        const companyData = auth.isSuperAdmin
            ? isSelectedCompany
                ? null
                : item
            : item;

        localStorage.setItem('selectedCompany', JSON.stringify(companyData));
        localStorage.removeItem('selectedBrand');
        dispatch({
            type: AppActionsEnum.SET_COMPANY,
            payload: {
                selectedCompany: companyData,
            },
        });
        dispatch({
            type: AppActionsEnum.SET_BRAND,
            payload: {
                selectedBrand: null,
            },
        });
        dispatch({
            type: AppActionsEnum.SET_BRANDS_LIST,
            payload: {
                brandsList: [],
            },
        });
        dispatch({
            type: AppActionsEnum.SET_PAGE_AREA_PERMISSIONS,
            payload: {
                pageAreaPermissions: [],
            },
        });
        if (!item?._id) setOpen(false);
    };

    const handleBrandClick = (brand: IBrandData) => {
        localStorage.setItem('selectedBrand', JSON.stringify(brand));
        dispatch({
            type: AppActionsEnum.SET_BRAND,
            payload: {
                selectedBrand: brand,
            },
        });
        dispatch({
            type: AppActionsEnum.SET_BRANDS_LIST,
            payload: {
                brandsList: [],
            },
        });
        dispatch({
            type: AppActionsEnum.SET_PAGE_AREA_PERMISSIONS,
            payload: {
                pageAreaPermissions: [],
            },
        });
        setOpen(false);
    };

    return (
        <div className="flex justify-center relative w-80 p-3 rounded-lg bg-[#F5F5F5] h-[26px] z-[99]">
            <button
                type="button"
                className="w-full border-none bg-transparent flex cursor-pointer justify-between items-center text-sm text-[#A4A4A4] dropdown-toggle font-poppins-regular"
                onMouseDown={(e) => {
                    e.preventDefault();
                    setOpen(!open);
                }}
            >
                {auth.selectedBrand?.name ||
                    auth.selectedCompany?.name ||
                    'Select Company or Brands'}
                <img src={open ? UpArrow : DownArrow} alt="arrow" />
            </button>
            {open && (
                <div
                    className="absolute w-full max-h-80 h-auto overflow-y-auto top-14 bg-[#FAFAF5] z-50 rounded border border-[#E3E3E3] border-solid cursor-pointer"
                    ref={wrapperRef}
                >
                    <ul className="m-0 w-full p-0">
                        {companies?.map((item) => (
                            <React.Fragment key={item?.mid}>
                                <div
                                    role="presentation"
                                    onClick={() => handleCompanyClick(item)}
                                >
                                    <div className="flex justify-between items-center py-2 px-3 border-b border-t-0 border-x-0 border-[#E3E3E3] border-solid">
                                        <div className="text-xs font-poppins-medium tracking-tighter">
                                            {item?.name ?? ''}
                                        </div>
                                        <img
                                            src={
                                                auth.selectedCompany?._id ===
                                                item?._id
                                                    ? UpArrow
                                                    : DownArrow
                                            }
                                            alt="arrow"
                                        />
                                    </div>
                                </div>
                                {auth.selectedCompany?._id === item._id && (
                                    <div className="grid">
                                        {item?.brandData
                                            ?.filter((v) => !v?.isDeleted)
                                            ?.map((brand: IBrandData) => (
                                                <button
                                                    type="button"
                                                    key={brand?._id}
                                                    className="bg-transparent p-0 border-none cursor-pointer"
                                                    onClick={() =>
                                                        handleBrandClick(brand)
                                                    }
                                                >
                                                    <div className="flex justify-between items-center py-2 pr-3 pl-5 border-b border-t-0 border-x-0 border-[#E3E3E3] border-solid">
                                                        <div className="text-xs font-poppins-medium tracking-tight">
                                                            {brand.name}
                                                        </div>
                                                    </div>
                                                </button>
                                            ))}
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MultiLevelDropdown;
