import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Drawer, PrimaryButton, SvgIcon, RFTextField } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { saveGroup, updateGroupById } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { ISaveGroupReq } from 'data/types/request';
import { IGroupDrawer, IRuleEngineDrawerProps } from '../../RuleEngine.type';
import { formSchemaValidation } from '../../RuleEngine.schema';

const RuleEngineDrawer = ({
    openDrawer,
    isOpen,
    groupRuleData,
    getGroupRules,
    isEdit,
    setIsEdit,
}: IRuleEngineDrawerProps) => {
    const { auth } = useAuthContext();
    const [groupCreateLoading, setGroupCreateLoading] = useState(false);

    const { control, handleSubmit, reset } = useForm<IGroupDrawer>({
        resolver: zodResolver(formSchemaValidation),
        mode: 'onChange',
        defaultValues: {},
    });

    const dataLength = Number(groupRuleData.length) + 1;
    useEffect(() => {
        if (isEdit && isEdit.edit) {
            reset({
                name: isEdit?.item?.name,
            });
        }
    }, []);

    const onSubmit = handleSubmit(async (formData) => {
        if (!formData) return;

        setGroupCreateLoading(true);

        const payload: ISaveGroupReq = {
            name: formData?.name,
            companyId: auth?.selectedCompany?._id,
            order: isEdit.edit ? isEdit.item.order : dataLength,
            brandId: auth?.selectedBrand?._id,
        };

        const handleSuccess = (message: string) => {
            reset({});
            showToast(message, 'success');
            openDrawer();
            setIsEdit({ edit: false, item: null });
            getGroupRules();
        };

        const handleError = (err: any) => {
            const errorMessage =
                err?.errors?.[0]?.message || 'Something went wrong';
            showToast(errorMessage, 'error');
        };

        try {
            if (isEdit.edit) {
                await updateGroupById(isEdit?.item?._id, payload);
                handleSuccess('Successfully updated group');
            } else {
                await saveGroup(payload);
                handleSuccess('Successfully created group');
            }
        } catch (err) {
            handleError(err);
        } finally {
            setGroupCreateLoading(false);
        }
    });

    return (
        <Drawer
            drawerSize={384}
            isOpen={isOpen}
            toggleDrawer={openDrawer}
            drawerDirection="right"
            className={classNames('bg-pink-100 max-h-[100vh] relative')}
        >
            <form className="w-full" onSubmit={onSubmit}>
                <div className="p-5">
                    <div className="mx-auto flex items-center justify-between w-[320px]">
                        <div className="my-0 text-xl font-merri-bold tracking-tighter text-green-100">
                            {`${isEdit?.edit ? 'Update' : 'New'} Group`}
                        </div>
                        <SvgIcon
                            className="cursor-pointer"
                            icon="CLOSE_BUTTON"
                            onClick={openDrawer}
                        />
                    </div>
                    <div className="mx-auto mt-5 w-[320px]">
                        <RFTextField
                            className="!w-[294px]"
                            control={control}
                            name="name"
                            placeholder="Group Name"
                            label="Group Name"
                            type="text"
                            noArrows
                        />
                    </div>
                </div>
                <div className="sticky bottom-0 flex bg-white px-5">
                    <PrimaryButton
                        type="submit"
                        loading={groupCreateLoading}
                        disabled={groupCreateLoading}
                        isDrawerButton
                        className={classNames(
                            'w-[320px] mx-auto !font-extrabold'
                        )}
                        name={
                            groupCreateLoading
                                ? ''
                                : `${
                                      isEdit?.edit ? 'Update' : 'Create New'
                                  } Group`
                        }
                        color="#2E672F"
                        variant="filled"
                    />
                </div>
            </form>
        </Drawer>
    );
};
export default RuleEngineDrawer;
