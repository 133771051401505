import { useEffect, useState } from 'react';
import { getGroupData } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGroup } from 'data/common';
import usePermission from 'hooks/commonHook';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { useAuthContext } from 'context/Auth/AuthContext';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { PageIdentifiers } from 'data/constants/common.constants';
import { GroupsHeader } from './GroupsHeader';
import { GroupsTable } from './GroupsTable';
import { AddGroupsDrawer } from './GroupsDrawer/AddGroupDrawer';
import { EditGroupsDrawer } from './GroupsDrawer/EditGroupDrawer';

const Groups = () => {
    const { auth, dispatch } = useAuthContext();

    const [allGroups, setAllGroups] = useState<IGroup[]>([]);
    const [userCompany, setUserCompany] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [checkedGroups, setCheckedGroups] = useState<string[]>([]);
    const [editGroupDetails, setEditGroupDetails] = useState<IGroup | null>(
        null
    );
    const [isEditGroupDrawer, setIsEditGroupDrawer] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGroupLoading, setIsGroupLoading] = useState<boolean>(false);

    const isWrite = usePermission({
        checkPermission: PageIdentifiers.GROUPS,
    });

    const isAllowedUser = auth?.isSuperAdmin ? true : isWrite;
    const getGroups = () => {
        setIsLoading(true);
        getGroupData({
            companyId: auth?.selectedCompany?._id,
            brandId: auth?.selectedBrand?._id,
        })
            .then((res) => {
                setAllGroups(res?.groups);
                dispatch({
                    type: AppActionsEnum.SET_GROUPS,
                    payload: {
                        groups: res.groups,
                    },
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getGroups();
    }, [auth?.selectedBrand, auth?.selectedCompany]);

    const openDrawer = () => {
        setIsEditGroupDrawer(false);
        setEditGroupDetails(null);

        if (isOpen) {
            setUserCompany('');
        }
        setIsOpen(!isOpen);
    };

    return (
        <div className="p-5">
            <GroupsHeader
                checkedGroups={checkedGroups}
                getGroups={getGroups}
                setCheckedGroups={setCheckedGroups}
                openDrawer={openDrawer}
                isAllowedUser={isAllowedUser}
            />
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <GroupsTable
                    allGroups={allGroups}
                    setCheckedGroups={setCheckedGroups}
                    checkedGroups={checkedGroups}
                    setAllGroups={setAllGroups}
                    setEditGroupDetails={setEditGroupDetails}
                    setIsEditGroupDrawer={setIsEditGroupDrawer}
                    setIsOpen={setIsOpen}
                    setUserCompany={setUserCompany}
                    isAllowedUser={isAllowedUser}
                    getGroups={getGroups}
                />
            )}
            {isEditGroupDrawer ? (
                <EditGroupsDrawer
                    isOpen={isOpen}
                    openDrawer={openDrawer}
                    loading={isLoading}
                    userCompany={userCompany}
                    getGroups={getGroups}
                    setIsOpen={setIsOpen}
                    isEditGroupDrawer={isEditGroupDrawer}
                    editGroup={editGroupDetails}
                    setIsGroupLoading={setIsGroupLoading}
                    isGroupLoading={isGroupLoading}
                />
            ) : (
                <AddGroupsDrawer
                    isOpen={isOpen}
                    openDrawer={openDrawer}
                    loading={isLoading}
                    userCompany={userCompany}
                    getGroups={getGroups}
                    setIsOpen={setIsOpen}
                    setIsGroupLoading={setIsGroupLoading}
                    isGroupLoading={isGroupLoading}
                />
            )}
        </div>
    );
};
export default Groups;
