import React, { useState } from 'react';
import { Control } from 'react-hook-form';
import { IPasswordData } from 'data/types/auth';
import { PrimaryButton } from 'components/Common';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import SvgIcon from 'components/Common/Icon/SvgIcon';

interface IResetPassword {
    isNewAdmin?: boolean;
    handleSubmit: () => void;
    control: Control<IPasswordData>;
    isLoading: boolean;
}

export const ResetPassword = ({
    isNewAdmin,
    handleSubmit,
    control,
    isLoading = false,
}: IResetPassword) => {
    const [validationStatus, setValidationStatus] = useState({
        minLength: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialChar: false,
    });

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setValidationStatus({
            minLength: value.length >= 12,
            hasUpperCase: /[A-Z]/.test(value),
            hasLowerCase: /[a-z]/.test(value),
            hasNumber: /\d/.test(value),
            hasSpecialChar: /[^A-Za-z0-9]/.test(value),
        });
    };

    function getButtonName() {
        if (isLoading) {
            return '';
        }
        return isNewAdmin ? 'Set Password' : 'Reset Password';
    }

    function getValidationIcon(condition: boolean) {
        return (
            <span className="w-3">
                {condition ? (
                    <SvgIcon icon="CHECK" className="stroke-[#8FB131]" />
                ) : (
                    <SvgIcon icon="CROSS" className="stroke-red-500" />
                )}
            </span>
        );
    }

    return (
        <div className="flex flex-col justify-center items-start w-[500px] mx-auto">
            <div className="flex items-center text-[32px] text-green-100 font-merri-bold tracking-tighter w-full">
                {isNewAdmin ? 'Create your Password' : 'New Password'}
            </div>
            <div className="text-[16px] font-poppins-medium tracking-tight">
                {isNewAdmin
                    ? 'Enter your password to complete the onboarding process.'
                    : 'Enter your new password to begin again.'}{' '}
            </div>
            <form onSubmit={handleSubmit} className="w-full">
                <div className="flex flex-col w-[100%] pt-12">
                    <RFTextField
                        control={control}
                        name="password"
                        fullWidth
                        onChange={handlePasswordChange}
                        label="Enter Password"
                        type="password"
                        iconPosition="right"
                        asterisk
                    />
                    <div>
                        <RFTextField
                            control={control}
                            name="confirmPassword"
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            iconPosition="right"
                            asterisk
                        />
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            {getValidationIcon(validationStatus?.minLength)}
                            <p className="text-xs text-[#C8C8C8] m-0">
                                Password must be at least 12 characters long
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            {getValidationIcon(
                                validationStatus?.hasLowerCase &&
                                    validationStatus?.hasUpperCase
                            )}
                            <p className="text-xs text-[#C8C8C8] m-0">
                                Password must contain both uppercase and
                                lowercase letters
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            {getValidationIcon(validationStatus?.hasNumber)}
                            <p className="text-xs text-[#C8C8C8] m-0">
                                Password must include at least one number
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            {getValidationIcon(
                                validationStatus?.hasSpecialChar
                            )}
                            <p className="text-xs text-[#C8C8C8] m-0">
                                Password must include at least one special
                                character (e.g., !, @, #, $, %)
                            </p>
                        </div>
                    </div>
                    <PrimaryButton
                        type="submit"
                        name={getButtonName()}
                        disabled={isLoading}
                        loading={isLoading}
                        isDrawerButton
                        color="#2E672F"
                        variant="filled"
                        className="hover:bg-[#2E672F] hover:border-green-600 focus:border-green-600 mt-10 w-full font-medium pt-1"
                    />
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
