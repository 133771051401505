import { Dispatch, SetStateAction } from 'react';
import { StatusBadge, TextField } from 'components/Common';
import { ITransactions } from 'data/types/response';
import { getTransactionStatus } from 'data/utils/transactions';
import {
    formatNumber,
    getDateFormats,
    getFirstDateFormat,
} from 'data/utils/common';
import { IColumnType } from 'components/Common/common';
import { ITransactionsId } from '../Approval.type';

export const approvalTableColumn = (
    isAllChecked: boolean,
    setCheckedApprovals: Dispatch<SetStateAction<Array<ITransactionsId>>>,
    approvalData: Array<ITransactions>,
    checkedApprovals: Array<ITransactionsId>
): IColumnType<any>[] => {
    const handleSelectAll = () => {
        setCheckedApprovals(
            approvalData
                ?.filter((a) => a.status === 'HOLD')
                ?.map((item: ITransactions) => {
                    return {
                        id: item?._id,
                        pspType: item?.pspType,
                        method: item?.method,
                    };
                })
        );
    };

    const handleDeselectAll = () => {
        setCheckedApprovals([]);
    };

    return [
        {
            key: 'checked',
            width: 10,
            title: (
                <div className="flex justify-center">
                    <TextField
                        onChange={() =>
                            isAllChecked
                                ? handleDeselectAll()
                                : handleSelectAll()
                        }
                        isCheckbox
                        checked={isAllChecked}
                        type="checkbox"
                    />
                </div>
            ),
            render: (_, i) => (
                <div className="flex justify-center">
                    <TextField
                        isCheckbox
                        disabled={i.status !== 'HOLD'}
                        checked={
                            checkedApprovals?.find((item) => item.id === i._id)
                                ?.id === i._id
                        }
                        onChange={() => {
                            if (
                                checkedApprovals?.some(
                                    (item) => item.id === i?._id
                                ) ||
                                isAllChecked
                            ) {
                                setCheckedApprovals(
                                    checkedApprovals?.filter(
                                        (item) => item.id !== i?._id
                                    )
                                );
                            } else {
                                setCheckedApprovals([
                                    ...checkedApprovals,
                                    {
                                        id: i?._id,
                                        pspType: i?.pspType,
                                        method: i?.method,
                                    },
                                ]);
                            }
                        }}
                        type="checkbox"
                    />
                </div>
            ),
        },
        {
            key: 'method',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Method
                    </div>
                </div>
            ),
            width: 100,
            render: (_, i) => <div>{i.method || 'TRUST_PAYIN'}</div>,
        },
        {
            key: 'updated',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Updated
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div>
                    {getFirstDateFormat(
                        (i?.updatedAt || i?.createdAt) as string
                    )}
                </div>
            ),
            width: 100,
        },
        {
            key: 'tid',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        TX ID
                    </div>
                </div>
            ),
            width: 200,
            render: (_, i) => <div>{i?._id}</div>,
        },
        {
            key: 'email',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        User Email
                    </div>
                </div>
            ),
            width: 300,
            render: (_, i) => <div>{i?.email ?? '-'}</div>,
        },
        {
            key: 'status',
            width: 100,
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Status
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div className="flex justify-center items-center">
                    <StatusBadge status={getTransactionStatus(i)} />
                </div>
            ),
        },
        {
            key: 'suspected',
            width: 100,
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Suspected
                    </div>
                </div>
            ),
            render: (_, i) => <div>{i?.Suspected ?? '-'}</div>,
        },
        {
            key: 'blocked',
            width: 100,
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Blocked
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div className="capitalize">
                    {i?.blocked != null ? String(i.blocked) : '-'}
                </div>
            ),
        },
        {
            key: 'type',
            width: 100,
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Tx Type
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div>
                    {i.method === 'CLEO_PAYOUT' ? 'Withdrawal' : 'Deposit'}
                </div>
            ),
        },
        {
            key: 'amount',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Tx Amount
                    </div>
                </div>
            ),
            width: 150,
            render: (_, i) => (
                <div>
                    {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                        ? formatNumber(Number(i?.baseamount))
                        : formatNumber(Number(i.amount))}
                </div>
            ),
        },
        {
            key: 'amount',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Tx Base
                    </div>
                </div>
            ),
            width: 150,
            render: (_, i) => (
                <div>
                    {formatNumber(Number(i?.transactionBaseAmountConverted))}
                </div>
            ),
        },
        {
            key: 'userBalance',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        User Balance
                    </div>
                </div>
            ),
            width: 200,
            render: (_, i) => <div>{formatNumber(Number(i?.userBalance))}</div>,
        },
        {
            key: 'created',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Created
                    </div>
                </div>
            ),
            render: (_, i) => (
                <div>
                    {getDateFormats(
                        (i?.createdAt || i?.updateAt || i?.created) as string
                    )}
                </div>
            ),
            width: 100,
        },
        {
            key: 'state',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        State
                    </div>
                </div>
            ),
            render: (_, i) => <div>{i?.address?.state || '-'}</div>,
            width: 200,
        },
    ];
};
