import React from 'react';
import { get } from 'data/utils/common';
import './table.css';
import { ITableRowProps } from '../common';

const TableRow = ({
    data,
    columns,
    isAdminUserModal,
    isSidebarTable,
    isAuditHistoryTable,
    isTransactionDetailsTable,
    handleRowClick,
    colSpan,
}: ITableRowProps<any>) => {
    const getTableRowClassNames = () => {
        if (isAuditHistoryTable) {
            return 'border border-solid border-l-0 border-t-0 last:border-b-0 border-r-0 border-[#BDD864] bg-[#F8FBEA] tableCell';
        }
        if (isAdminUserModal || isTransactionDetailsTable) {
            return 'cursor-pointer';
        }
        return '';
    };

    const getTableBodyClassNames = (columnRender: boolean) => {
        if (isSidebarTable) {
            return 'border-none pl-2 !text-start';
        }

        if (isAuditHistoryTable) {
            return 'border border-solid border-l-0 border-opacity-0 border-t-0 last:border-r-0 !border-[#BDD864] !text-center tableRow';
        }

        const renderClass = columnRender ? '!text-center' : '!text-start';
        return `border border-solid border-l-0 border-t-0 last:border-r-0 text-center ${renderClass} border-b-0 border-[#E3E3E3] whitespace-nowrap tableRow`;
    };

    return (
        <>
            {!!data?.length &&
                data.map((item: { _id: string; createdAt: string }) => (
                    <tr
                        key={`table-body-${item?._id}`}
                        className={`border border-solid border-l-0 border-t-0 last:border-b-0 border-r-0 border-[#E3E3E3] tableCell ${getTableRowClassNames()} `}
                    >
                        {columns.map((column) => {
                            const value = get(item, column.key, '');
                            const disableColumn =
                                column.key === 'edit' ||
                                column.key === 'disable' ||
                                column.key === 'delete';

                            let cellValue: React.ReactNode;

                            if (column.render) {
                                const rendered = column.render(column, item);
                                if (
                                    rendered !== undefined &&
                                    rendered !== null
                                ) {
                                    cellValue = rendered;
                                } else {
                                    cellValue = '-';
                                }
                            } else if (!value && value !== 0) {
                                cellValue = '-';
                            } else {
                                cellValue = value;
                            }

                            return (
                                <td
                                    key={`table-row-cell-${column?.key}`}
                                    className={`!text-center p-0 !pl-0 ${getTableBodyClassNames(
                                        Boolean(column.render)
                                    )}`}
                                >
                                    <button
                                        onClick={() => {
                                            if (!disableColumn) {
                                                handleRowClick(item);
                                            }
                                        }}
                                        type="button"
                                        aria-label="table-row"
                                        className={`w-full bg-transparent cursor-pointer ${getTableBodyClassNames(
                                            Boolean(column.render)
                                        )}`}
                                    >
                                        {cellValue}
                                    </button>
                                </td>
                            );
                        })}
                    </tr>
                ))}
            {!data?.length && (
                <tr>
                    <td
                        colSpan={colSpan}
                        className="w-full py-4 text-center font-poppins-regular"
                    >
                        No Data Found
                    </td>
                </tr>
            )}
        </>
    );
};

export default TableRow;
