import './style.css';

const TermsAndConditions = () => (
    <>
        <div>
            This policy outlines the acceptable use of Mozarto’s back-office
            system for merchants, ensuring secure, responsible, and compliant
            use of the platform to manage payment operations and sensitive data.
        </div>
        <div className="title">1. General Usage Guidelines</div>
        <div>
            <div>
                1.1. Use Mozarto’s back-office system solely for business
                purposes related to your merchant account.
            </div>
            <div>
                1.2. Ensure all actions within the system align with applicable
                legal, regulatory, and contractual requirements.
            </div>
            <div className="title">
                2. Access, Authentication, and Password Guidance
            </div>
            <div>
                2.1. Only authorized users designated by your organization may
                access Mozarto’s back-office system.
            </div>
            <div>2.2. Password Requirements:</div>
            <div className="bullet-list">
                <div>
                    Passwords must be a minimum of 12 characters and include at
                    least one uppercase letter, one lowercase letter, one
                    number, and one special character (e.g., !@#$%^&*).
                </div>
                <div>
                    Avoid using common words, phrases, or sequential patterns
                    (e.g., Password123 or 1234abcd).
                </div>
                <div>
                    Do not reuse passwords used for other systems or personal
                    accounts.
                </div>
                <div>2.3. Password Maintenance:</div>
                <div>
                    Change your password at least every 90 days or immediately
                    if you suspect it has been compromised.
                </div>
                <div>
                    Do not write down passwords in unsecured locations or store
                    them in unapproved tools (e.g., plain text files).
                </div>
                <div>
                    Use a password manager if necessary, approved by your
                    organization.
                </div>
                <div>2.4. Account Security:</div>
                <div>
                    Enable multi-factor authentication (MFA) for additional
                    protection if required.
                </div>
                <div>
                    Report lost, stolen, or compromised credentials to Mozarto
                    support immediately.
                </div>
                <div>
                    Never share login credentials with others, including
                    colleagues or third-party providers.
                </div>
            </div>
            <div className="title">3. Data Security and Confidentiality</div>
            <div>
                3.1. Treat all data accessed in Mozarto’s back-office system,
                including transaction details and customer information, as
                confidential.
            </div>
            <div>
                3.2. Do not copy, export, or share sensitive data outside the
                system without Mozarto’s approval or unless required for
                business operations.
            </div>
            <div>
                3.3. Ensure your organization’s systems comply with security
                standards (e.g., PCI DSS) when handling data.
            </div>
            <div className="title">4. System Configuration</div>
            <div>
                4.1. Ensure that any configurations or rules set in the system,
                such as routing or failover mechanisms, align with your business
                needs and do not compromise security.
            </div>
            <div>
                4.2. Contact Mozarto support for guidance or approval if
                uncertain about configurations.
            </div>
            <div className="title">5. Reporting and Incident Notification</div>
            <div>
                5.1. Report any suspicious activities, unauthorized access, or
                system malfunctions to Mozarto support immediately.
            </div>
            <div>
                5.2. Cooperate with Mozarto in addressing and resolving reported
                issues.
            </div>
            <div className="title">6. Prohibited Activities</div>
            <div>
                6.1. Do not attempt to access system functionalities or data not
                explicitly assigned to your account.
            </div>
            <div>
                6.2 Do not use the system to process illegal or fraudulent
                transactions.
            </div>
            <div>
                6.3. Avoid activities that may disrupt the system or compromise
                security, such as unauthorized testing or scripting.
            </div>
            <div className="bullet-list mt-5 !p-0">
                <div>
                    Violations of this policy may result in the suspension or
                    termination of access to Mozarto’s back-office system.
                </div>
                <div>
                    Mozarto reserves the right to investigate any misuse and
                    take appropriate actions, including legal measures if
                    necessary.
                </div>
            </div>
        </div>
    </>
);

export default TermsAndConditions;
