import React, { useState } from 'react';
import { saveDisableEnableGroupRule } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGroupRuleDataType } from 'components/RuleEngine/RuleEngine.type';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import SvgIcon from '../Icon/SvgIcon';
import Switch from '../Switch/Switch';

const GroupCard: React.FC<any> = ({ item, dragHandleProps, commonProps }) => {
    const [active, setActive] = useState(item?.isActive);
    const { dispatch } = useAuthContext();
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const {
        setActiveGroup,
        activeGroup,
        groupRuleData,
        setGroupRuleData,
        setIsOpen,
        setIsEdit,
    } = commonProps;

    const handleDisable = () => {
        if (!item?._id) return;
        const payload = {
            groupId: item?._id,
            isActive: !active,
        };
        saveDisableEnableGroupRule(payload)
            .then(() => {
                const newGroupRuleData = groupRuleData.map(
                    (data: IGroupRuleDataType) => {
                        if (item?._id === data._id) {
                            return {
                                ...data,
                                isActive: !active,
                            };
                        }
                        return data;
                    }
                );
                setGroupRuleData(newGroupRuleData);
                setActive(!active);
                showToast('Succesfully', 'success');
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };
    const handleSetActive = () => {
        setActiveGroup?.(item?._id);
        dispatch({
            type: AppActionsEnum.SET_ACTIVE_GROUP,
            payload: {
                activeGroup: item?._id,
            },
        });
    };

    const getBorderClass = () => {
        if (activeGroup === item?._id && !active) {
            return 'text-black border-2 border-black border-solid';
        }
        if (activeGroup === item?._id) {
            return 'text-[#8FB131] border-2 border-solid border-[#8FB131]';
        }
        return 'text-black border-2 border-transparent border-solid';
    };

    return (
        <button
            type="button"
            onClick={active ? handleSetActive : () => {}}
            className={`w-full ${
                active ? 'bg-[#F8FBEA]' : 'bg-[#E3E3E3] cursor-not-allowed'
            } rounded-2xl ${getBorderClass()}`}
        >
            <div className="flex flex-wrap items-center justify-between h-14 pr-5 pl-2">
                <span className="font-bold flex items-center gap-2">
                    <button
                        type="button"
                        aria-label="Drag button"
                        className="cursor-grab bg-transparent border-none p-0"
                        onTouchStart={(e: any) => {
                            e.preventDefault();
                            document.body.style.overflow = 'hidden';
                            onTouchStart(e);
                        }}
                        onMouseDown={(e: any) => {
                            document.body.style.overflow = 'hidden';
                            onMouseDown(e);
                        }}
                        onTouchEnd={() => {
                            document.body.style.overflow = 'visible';
                        }}
                        onMouseUp={() => {
                            document.body.style.overflow = 'visible';
                        }}
                    >
                        <SvgIcon
                            icon="DRAG_ICON"
                            className={`flex items-center ${
                                activeGroup === item?._id && active
                                    ? 'stroke-[#8FB131]'
                                    : 'stroke-[#000]'
                            } `}
                        />
                    </button>
                    <p className="font-poppins-bold text-xs">{item.name}</p>
                </span>
                <div className="space-x-5">
                    <Switch
                        className={`rounded-2xl ${
                            active ? 'bg-[#DEECAA]' : 'bg-[#A4A4A4]'
                        } `}
                        isRuleEngine
                        isToggled={active}
                        handleCheck={handleDisable}
                    />
                    <SvgIcon
                        icon="EDIT_ICON"
                        className={`${
                            item.isActive
                                ? 'cursor-pointer'
                                : 'cursor-not-allowed'
                        }`}
                        onClick={(e) => {
                            if (item.isActive) {
                                e.stopPropagation();
                                setIsOpen(true);
                                setIsEdit({ edit: true, item });
                            }
                        }}
                    />
                </div>
            </div>
        </button>
    );
};
export default GroupCard;
