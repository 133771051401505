import React, { useState } from 'react';
import classNames from 'classnames';
import { IIcons } from 'data/common';
import { TextField } from '../index';
import './searchMenu.css';
import SvgIcon from '../Icon/SvgIcon';
import { ISearchMenuListProps } from '../common';

const SearchMenuList = ({
    className,
    datalist,
    selectedCompanyId,
    onSelectPsp,
    selectedProvider,
}: ISearchMenuListProps) => {
    const [query, setQuery] = useState('');

    const handleQueryChange = (event: any) => {
        setQuery(event.target.value);
    };

    const filteredProviders = datalist.filter((provider) =>
        provider.name.toLowerCase().includes(query.toLowerCase())
    );

    const getMenuClass = (provider: { name: string }) => {
        if (selectedProvider?.name === provider.name && selectedCompanyId) {
            return 'selected';
        }
        return selectedCompanyId ? '' : 'disabled';
    };

    return (
        <div
            className={classNames(
                className,
                'searchable-payment-providers-list w-full'
            )}
        >
            <div className="w-full bg-white z-[100]">
                <TextField
                    type="search"
                    iconPosition="left"
                    icon="SEARCH_ICON"
                    className="min-[1480px]:!w-[400px] min-w-1/2 !pl-9"
                    placeholder="Search"
                    value={query}
                    onChange={handleQueryChange}
                />
            </div>

            <div className="mt-4 searchList">
                {filteredProviders.length > 0 ? (
                    filteredProviders.map((provider, index) => (
                        <button
                            type="button"
                            key={provider.name}
                            className={`menuList ${getMenuClass(provider)}`}
                            onClick={() => onSelectPsp(provider)}
                        >
                            <SvgIcon
                                key={`${provider.name}_${index}`}
                                icon={provider?.logo as keyof IIcons}
                                className="h-[32px]"
                            />
                            <div className="flex w-full items-center justify-between text-center">
                                <span className="font-poppins-bold">
                                    {provider.name}
                                </span>
                                <SvgIcon
                                    className="flex"
                                    style={{ cursor: 'pointer' }}
                                    icon={
                                        selectedProvider?.name ===
                                            provider.name && selectedCompanyId
                                            ? 'FILL_RIGHT_ICON'
                                            : 'STATE_RIGHT_ICON'
                                    }
                                />
                            </div>
                        </button>
                    ))
                ) : (
                    <p className="text-base font-poppins-medium">
                        No providers match your search.
                    </p>
                )}
            </div>
        </div>
    );
};

export default SearchMenuList;
