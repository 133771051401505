import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Drawer } from 'components/Common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { createCompany } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import { ICompaniesDrawerProps, ICompanyProps } from '../Companies.type';
import { formSchemaValidation } from '../Companies.schema';
import { CompaniesForm } from './CompaniesForm/CompaniesForm';
import { getAllCompanyBrands } from '../../companyBrandService';

const AddCompaniesDrawer = ({
    isOpen,
    openDrawer,
    isEditCompany,
    company,
    getAllCompanies,
    setIsOpen,
}: ICompaniesDrawerProps) => {
    const { dispatch } = useAuthContext();

    const [companyLoading, setCompanyLoading] = useState<boolean>(false);

    const { control, handleSubmit, reset } = useForm<ICompanyProps>({
        resolver: zodResolver(formSchemaValidation),
        defaultValues: {},
    });

    useEffect(() => {
        if (!company) return;
        const asyncDefaultValues: ICompanyProps = {
            companyName: company?.name,
        };
        reset(asyncDefaultValues);
    }, [reset, company]);

    const onSubmit = handleSubmit((formData: ICompanyProps) => {
        if (!formData) return;
        try {
            setCompanyLoading(true);
            createCompany({
                companyName: formData?.companyName,
            })
                .then((result) => {
                    if (result?.errors?.length) {
                        showToast(result.errors[0].msg);
                    }
                    getAllCompanyBrands(dispatch, setCompanyLoading);
                    getAllCompanies('', '');
                    setCompanyLoading(false);
                    reset({ companyName: '' });
                    setIsOpen(false);
                    showToast(result.message, 'success');
                })
                .catch((err: any) => {
                    setCompanyLoading(false);
                    showToast(
                        err?.errors?.[0]?.msg || 'something went wrong',
                        'error'
                    );
                });
        } catch (error: any) {
            setCompanyLoading(false);
            showToast(error?.response?.data || 'something went wrong', 'error');
        }
    });

    useEffect(() => {
        if (!isOpen) return;
        return () => {
            reset({});
        };
    }, [reset, isOpen]);

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={() => openDrawer()}
            drawerDirection="right"
            className={classNames(
                'bg-pink-100  max-h-[100vh]',
                companyLoading ? '' : 'overflow-y-scroll'
            )}
        >
            <div className="p-5">
                <div className="flex items-center justify-between">
                    <div className="my-0 text-xl font-black text-green-100 font-merri-bold tracking-tighter">
                        {isEditCompany ? 'Update Company' : 'Add New Company'}
                    </div>
                    <SvgIcon
                        className="cursor-pointer"
                        icon="CLOSE_BUTTON"
                        onClick={() => openDrawer()}
                    />
                </div>
                <CompaniesForm
                    company={company}
                    companyLoading={companyLoading}
                    handleSubmit={onSubmit}
                    control={control}
                />
            </div>
        </Drawer>
    );
};

export default AddCompaniesDrawer;
