import { useRef } from 'react';
import { useScrollFadeVisibility } from 'hooks/commonHook';
import './style.css';
import { ITermsAndConditionsModalProps } from '../Modal.type';
import TermsAndConditions from './TermsAndConditions';
import { PrimaryButton } from '../../Common';

const TermsAndConditionsModal = ({
    setTermsModalOpen,
    onAccept,
    isLoading,
}: ITermsAndConditionsModalProps) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const isFadeVisible = useScrollFadeVisibility(contentRef);

    const handleDecline = () => {
        setTermsModalOpen(false);
    };

    return (
        <div className="flex items-center justify-center">
            <div className="max-w-[556px] bg-white shadow-lg shadow-black/40 fixed w-full !z-[1000]">
                <div className="relative">
                    <div className="bg-[#F8FBEA] text-xl text-[#2E672F] py-5 text-center font-poppins-bold">
                        Terms and conditions
                    </div>
                    <div
                        ref={contentRef}
                        className="bg-white p-10 pb-28 text-xs font-poppins-regular leading-[18px] h-[50vh] overflow-y-auto"
                    >
                        <TermsAndConditions />
                    </div>
                    <div
                        className={`absolute left-0 bottom-20 mb-[0.5px] w-full max-w-[556px] h-56 z-10 pointer-events-none ${
                            isFadeVisible ? 'shadow-fade-top' : 'hidden'
                        }`}
                    />
                    <div className="flex items-center border-0 border-t border-solid border-[#C8C8C8] gap-x-5 absolute left-0 bottom-0 w-full max-w-[556px] box-border p-5 bg-white">
                        <PrimaryButton
                            type="submit"
                            isDrawerButton
                            color="#8FB131"
                            className=" !h-10 w-full"
                            variant="filled"
                            name="Decline"
                            disabled={isLoading}
                            onClick={handleDecline}
                        />
                        <PrimaryButton
                            type="submit"
                            isDrawerButton
                            className="!h-10 w-full"
                            color="#2E672F"
                            variant="filled"
                            onClick={onAccept}
                            loading={isLoading}
                            disabled={isLoading}
                            name={isLoading ? '' : 'Accept and Continue'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditionsModal;
