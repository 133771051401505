import { useEffect } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Drawer } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { createGroup } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGroupsDrawerProps, IGroupType } from '../Groups.type';
import { formSchemaValidation } from '../Groups.schema';
import { GroupForm } from './GroupForm/GroupForm';

export const AddGroupsDrawer = ({
    isOpen,
    openDrawer,
    loading,
    userCompany,
    getGroups,
    setIsOpen,
    setIsGroupLoading,
    isGroupLoading,
}: IGroupsDrawerProps) => {
    const { auth } = useAuthContext();

    const { control, handleSubmit, reset, watch } = useForm<IGroupType>({
        resolver: zodResolver(formSchemaValidation(auth.isSuperAdmin)),
        defaultValues: {},
    });

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        setIsGroupLoading(true);
        const payload = {
            name: formData?.groupName,
            companyId: auth.isSuperAdmin
                ? formData?.companyId
                : auth?.authUser?.company,
            brandsPermissions: formData?.brandsPermissions,
            pageAreasPermissions: formData?.pageAreasPermissions,
        };
        createGroup(payload)
            .then((result) => {
                if (result?.errors?.length) {
                    showToast(result.errors[0].msg);
                }
                getGroups();
                setIsGroupLoading(false);
                reset({});
                setIsOpen(false);
                showToast(result.message, 'success');
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.msg || 'something went wrong',
                    'error'
                );
            });
    });

    useEffect(() => {
        if (!isOpen) return;
        return () => {
            reset({});
        };
    }, [reset, isOpen]);

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={() => openDrawer()}
            drawerDirection="right"
            className={classNames(
                'bg-pink-100  max-h-[100vh] relative',
                loading ? '' : 'overflow-y-scroll'
            )}
        >
            <GroupForm
                onSubmit={onSubmit}
                loading={isGroupLoading}
                control={control}
                openDrawer={() => openDrawer()}
                isSuperAdmin={auth.isSuperAdmin}
                userCompany={userCompany}
                isOpen={isOpen}
                watch={watch}
            />
        </Drawer>
    );
};
