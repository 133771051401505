import { IGroup } from 'data/common';
import { Switch, TextField } from 'components/Common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { IColumnType } from 'components/Common/common';
import { GroupsTableParams } from '../Groups.type';

export const GroupsTableColumn = ({
    isAllChecked,
    checkedGroups,
    allGroups,
    setCheckedGroups,
    setEditGroupDetails,
    setUserCompany,
    setIsEditGroupDrawer,
    setIsOpen,
    isAllowedUser,
    setShowDisableModal,
    setSelectedGroup,
}: GroupsTableParams): IColumnType<IGroup>[] => {
    const handleSelectAll = () => {
        const filteredGroups =
            allGroups?.filter((group) => !group?.isDeleted) ?? [];
        setCheckedGroups(filteredGroups?.map((item) => item?._id));
    };

    const handleDeselectAll = () => {
        setCheckedGroups([]);
    };

    return [
        {
            key: 'checked',
            width: 10,
            title: (
                <div className="flex justify-center">
                    <TextField
                        onChange={() =>
                            isAllChecked
                                ? handleDeselectAll()
                                : handleSelectAll()
                        }
                        isCheckbox
                        checked={isAllChecked}
                        type="checkbox"
                    />
                </div>
            ),
            render: (_, i) => (
                <div className="flex justify-center">
                    <TextField
                        isCheckbox
                        disabled={i?.isDeleted}
                        checked={
                            checkedGroups?.find((item) => item === i?._id) ===
                            i?._id
                        }
                        onChange={() => {
                            if (
                                checkedGroups?.includes(i?._id) ||
                                isAllChecked
                            ) {
                                setCheckedGroups(
                                    checkedGroups?.filter(
                                        (item) => item !== i?._id
                                    )
                                );
                            } else {
                                setCheckedGroups([...checkedGroups, i?._id]);
                            }
                        }}
                        type="checkbox"
                    />
                </div>
            ),
        },
        {
            key: 'mid',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        MID
                    </div>
                </div>
            ),
            width: 50,
        },
        {
            key: 'name',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Name
                    </div>
                </div>
            ),
            width: 20,
        },
        {
            key: 'edit',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Edit
                    </div>
                </div>
            ),
            width: 40,
            render: (_, i) => (
                <SvgIcon
                    className={`cursor-pointer ${
                        !isAllowedUser ? 'pointer-events-none opacity-50' : ''
                    }`}
                    onClick={() => {
                        if (i?.isDeleted) return;
                        setEditGroupDetails?.(i);
                        setUserCompany(i?.companyId);
                        setIsEditGroupDrawer(true);
                        setIsOpen(true);
                    }}
                    icon="EDIT_ICON"
                />
            ),
        },
        {
            key: 'disable',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Disable
                    </div>
                </div>
            ),
            width: 40,
            render: (_, i) => (
                <Switch
                    disabled={!isAllowedUser}
                    handleCheck={() => {
                        setShowDisableModal(true);
                        setSelectedGroup(i);
                    }}
                    isToggled={i?.isDeleted}
                    className="rounded-2xl bg-gray-200"
                />
            ),
        },
    ];
};
