import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { PDFDocument, rgb } from 'pdf-lib';
import { getAuditUser } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import { Modal, PrimaryButton, Loader } from 'components/Common';
import DatePicker from 'components/Common/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { AuditHeader } from './AuditHeader';
import { IAuditData, IFilterData, IGetAllAuditsProps } from './Audit.type';
import { AuditTable } from './AuditTable';

const downloadPDF = async (auditDetails: IAuditData[], filename: string) => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([2000, 900]);
    const { width, height } = page.getSize();

    // Draw table title
    page.drawText('Audit Data Export', {
        x: width / 2 - 70, // Center the title
        y: height - 10,
        size: 12, // Increase font size for title
        color: rgb(0, 0, 0),
    });

    const columnHeaders = Object.keys(auditDetails[0] as Record<string, any>);
    const rowData = auditDetails.map(
        (item) => Object.values(item) as IAuditData[]
    );

    const columnWidth = 200;
    const rowHeight = 20;
    const tableY = height - 40;

    // Draw table rows and columns
    rowData.forEach((row, i) => {
        const y = tableY - i * rowHeight;

        row.forEach((cell, j) => {
            const x = 10 + j * columnWidth;
            let text: string;

            if (i === 0) {
                text = columnHeaders[j];
            } else if (typeof cell === 'object') {
                text = JSON.stringify(cell);
            } else {
                text = cell;
            }

            const textColor = i === 0 ? rgb(1, 1, 1) : rgb(0, 0, 0); // Change text color for header
            const backgroundColor = i === 0 ? rgb(0, 0, 0) : rgb(1, 1, 1); // Background color for header

            page.drawRectangle({
                x,
                y,
                width: columnWidth,
                height: rowHeight,
                color: backgroundColor, // Apply background color
            });

            page.drawText(text?.toString(), {
                x: x + 5, // Adjust x position to center text
                y: y + 5, // Adjust y position to center text vertically
                size: 10,
                color: textColor, // Apply text color
            });
        });
    });

    const pdfBytes = await pdfDoc.save();

    const pdfBlob = new Blob([pdfBytes], {
        type: 'application/pdf',
    });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = `${filename}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

const AuditPage = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openExportModal, setOpenExportModal] = useState<boolean>(false);
    const [exportType, setExportType] = useState<string | null>(null);
    const [auditDetails, setAuditDetails] = useState<Array<IAuditData>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<IFilterData>({
        fileType: 'Microsoft (.xlsx)',
        startDate: '',
        endDate: '',
    });
    const { auth } = useAuthContext();

    const getAllAudits = (dataList: IGetAllAuditsProps) => {
        if (auth.selectedBrand?._id) {
            dataList.mids = [auth?.selectedBrand?.mid];
        } else if (auth.selectedCompany?._id) {
            const selectedCompany = (auth?.companiesAndBrands || []).find(
                (company: { _id: string }) =>
                    company._id === auth.selectedCompany?._id
            );
            dataList.mids = [
                ...((selectedCompany.brandData || []).map(
                    ({ mid }: { mid: string }) => mid
                ) || []),
                selectedCompany.mid,
            ];
        }
        setLoading(true);
        getAuditUser(dataList)
            .then((res) => {
                setAuditDetails(res?.auditData);
            })
            .catch((err) => {
                showToast(err?.message || 'something went wrong', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleExport = () => {
        if (!exportType && auditDetails.length === 0) {
            return;
        }

        const filename = 'Auditlogs';

        switch (exportType) {
            case 'Microsoft (.xlsx)': {
                const xlsxData = [
                    Object.keys(auditDetails[0]),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    ...auditDetails.map((item) => Object.values(item)),
                ];
                const xlsxWorksheet = XLSX.utils.aoa_to_sheet(xlsxData);
                const xlsxWorkbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(
                    xlsxWorkbook,
                    xlsxWorksheet,
                    'Sheet 1'
                );
                const xlsxBuffer = XLSX.write(xlsxWorkbook, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const xlsxBlob = new Blob([xlsxBuffer], {
                    type: 'application/octet-stream',
                });
                const xlsxUrl = URL.createObjectURL(xlsxBlob);
                const downloadLink = document.createElement('a');
                downloadLink.href = xlsxUrl;
                downloadLink.download = `${filename}.xlsx`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                setExportType(null);
                break;
            }
            case 'CSV (.csv)': {
                const csvData = [
                    Object.keys(auditDetails[0]),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    ...auditDetails.map((item) => Object.values(item)),
                ];
                const csvContent = `data:text/csv;charset=utf-8,${csvData
                    .map((row) => row.join(','))
                    .join('\n')}`;
                const encodedUri = encodeURI(csvContent);
                const downloadLink = document.createElement('a');
                downloadLink.href = encodedUri;
                downloadLink.download = `${filename}.csv`; // Use the filename variable
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                setExportType(null);
                break;
            }

            case 'Open Document (.ods)': {
                const odsContent = [
                    Object.keys(auditDetails[0]),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    ...auditDetails.map((item) => Object.values(item)),
                ];
                const odsDataList = odsContent
                    .map((row) => row.join('\t'))
                    .join('\n');

                const blob = new Blob([odsDataList], {
                    type: 'application/vnd.oasis.opendocument.spreadsheet',
                });
                const url = URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.download = `${filename}.ods`; // Use the filename variable
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                setExportType(null);
                break;
            }
            case 'Tab Seperated Values (.tsv)': {
                const tsvData = [
                    Object.keys(auditDetails[0]),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    ...auditDetails.map((item) => Object.values(item)),
                ];
                const tsvDataList = tsvData
                    .map((row) => row.join('\t'))
                    .join('\n');

                const blob = new Blob([tsvDataList], {
                    type: 'text/tab-separated-values',
                });
                const url = URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.download = `${filename}.tsv`; // Use the filename variable
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                setExportType(null);

                break;
            }

            case 'Web Page (HTML)': {
                // Create the HTML content (you can replace this with your own content)
                const htmlContent = `
                      <!DOCTYPE html>
                      <html lang="en">
                      <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Audit Table</title>
                      </head>
                         <body>
                            <table>
                               <thead>
                                    <tr>
                                      <th>User ID</th>
                                      <th>User Name</th>
                                      <th>Action</th>
                                      <th>Page</th>
                                      <th>Updated By</th>
                                      <th>Level</th>
                                      <th>Source</th>
                                      <th>HTTP Method</th>
                                      <th>Created At</th>
                                      <th>Updated At</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    ${auditDetails
                                        .map(
                                            (item, index) => `
                                            <tr key=${index}>
                                              <td>${item.userId}</td>
                                              <td>${item.userName}</td>
                                              <td>${item.action}</td>
                                              <td>${item.page}</td>
                                              <td>${item.updatedBy}</td>
                                              <td>${item.level}</td>
                                              <td>${item.source}</td>
                                              <td>${item.httpMethod}</td>
                                              <td>${item.createdAt}</td>
                                              <td>${item.updatedAt}</td>
                                            </tr>
                                            `
                                        )
                                        .join('')}
                                  </tbody>
                            </table>                      
                         </body>
                      </html>`;

                // Convert the HTML content to a Blob
                const blob = new Blob([htmlContent], { type: 'text/html' });
                const url = URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.download = `${filename}.html`; // Use the filename variable
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                setExportType(null);
                break;
            }

            case 'PDF (.pdf)': {
                downloadPDF(auditDetails, filename);
                setExportType(null);
                break;
            }

            default:
                break;
        }
    };

    useEffect(() => {
        handleExport();
    }, [exportType]);

    useEffect(() => {
        if (!auth?.companiesAndBrands?.length) return;
        getAllAudits({});
    }, [auth?.companiesAndBrands, auth?.selectedBrand, auth?.selectedCompany]);

    useEffect(() => {
        if (openExportModal) {
            setExportType(null);
        }
    }, [openExportModal]);

    const handleFilter = () => {
        getAllAudits(filterData);
        setOpenExportModal(false);
    };

    const handleCancelFilter = () => {
        setFilterData({
            fileType: 'Microsoft (.xlsx)',
            startDate: '',
            endDate: '',
        });
        getAllAudits({
            fileType: 'Microsoft (.xlsx)',
            startDate: '',
            endDate: '',
        });
        setOpenExportModal(false);
    };

    return (
        <div className="p-5">
            {openExportModal && (
                <Modal
                    icon
                    setIsOpen={setOpenExportModal}
                    title="Transactions Filter"
                >
                    <>
                        {/* <div className="mb-2">
                            Export transactions for your choice of accounting
                            software or tools.
                        </div>
                        <Select
                            bordered
                            isGroup
                            editUser={filterData?.fileType}
                            options={[
                                {
                                    name: 'Microsoft (.xlsx)',
                                    _id: 'Microsoft (.xlsx)',
                                },
                                { name: 'CSV (.csv)', _id: 'CSV (.csv)' },
                                { name: 'PDF (.pdf)', _id: 'PDF (.pdf)' },
                            ]}
                            handleOnSelect={(e) =>
                                setFilterData({
                                    ...filterData,
                                    fileType: e.target.value,
                                })
                            }
                        /> */}
                        <div className="pb-2">Select a date range:</div>
                        <div className="grid grid-cols-2 gap-x-4">
                            <DatePicker
                                label=""
                                placeholder="Start date"
                                dateTimePickerProps={{
                                    options: {
                                        maxDate: new Date(),
                                    },
                                }}
                                onChange={(selectedDates: Date[]) => {
                                    setFilterData({
                                        ...filterData,
                                        startDate: dayjs(
                                            selectedDates[0]
                                        ).format(),
                                    });
                                }}
                                name=""
                                value={filterData?.startDate as string}
                            />
                            <DatePicker
                                label=""
                                placeholder="End Date"
                                dateTimePickerProps={{
                                    options: {
                                        minDate: new Date(
                                            Date.parse(
                                                filterData?.startDate as string
                                            )
                                        ),
                                        maxDate: new Date(),
                                    },
                                }}
                                onChange={(selectedDates: Date[]) => {
                                    setFilterData({
                                        ...filterData,
                                        endDate: dayjs(
                                            selectedDates[0]
                                        ).format(),
                                    });
                                }}
                                name=""
                                value={filterData?.endDate as string}
                            />
                        </div>
                        <div className="mt-4 flex justify-center gap-3">
                            <PrimaryButton
                                onClick={handleFilter}
                                type="submit"
                                name="Filter"
                                color="#2E672F"
                                variant="filled"
                                isDrawerButton
                                className="w-full items-center px-4 font-medium"
                            />
                            <PrimaryButton
                                onClick={handleCancelFilter}
                                type="submit"
                                name="Cancel"
                                color="#2E672F"
                                variant="outline"
                                isDrawerButton
                                className="w-full items-center px-4 font-medium"
                            />
                        </div>
                    </>
                </Modal>
            )}
            <AuditHeader
                setOpenExportModal={setOpenExportModal}
                setExportType={setExportType}
            />
            {loading ? (
                <div className="mt-5">
                    <Loader />
                </div>
            ) : (
                <AuditTable
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    auditDetails={auditDetails}
                />
            )}
        </div>
    );
};
export default AuditPage;
