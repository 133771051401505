import React, { FC, useRef, useEffect, useState } from 'react';
import './index.css';
import classNames from 'classnames';
import { IIcons } from 'data/common';
import { getLabelColor } from 'data/utils/common';
import SvgIcon from '../Icon/SvgIcon';
import { ITextFieldProps } from '../common';

const useFocusState = (inputRef: React.RefObject<HTMLInputElement>) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocusChange = () => {
        setIsFocused(document.activeElement === inputRef.current);
    };

    useEffect(() => {
        document.addEventListener('focus', handleFocusChange, true);
        document.addEventListener('blur', handleFocusChange, true);
        document.addEventListener('hover', handleFocusChange, true);

        return () => {
            document.removeEventListener('focus', handleFocusChange, true);
            document.removeEventListener('blur', handleFocusChange, true);
            document.removeEventListener('hover', handleFocusChange, true);
        };
    }, [inputRef]);

    return isFocused;
};

const Input: FC<ITextFieldProps> = ({
    defaultValue,
    style,
    icon,
    iconPosition,
    isCheckbox = false,
    fullWidth = false,
    label,
    className,
    iconOnClick,
    isError = false,
    placeHolder,
    asterisk = false,
    errorMessage,
    noArrows = false,
    max,
    containerStyle,
    pattern,
    type = 'text',
    onChange,
    isLabelMarginBottom,
    isSearchBar = false,
    ...props
}: ITextFieldProps) => {
    const inputRef = useRef(null);
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [passwordType, setPasswordType] = useState('password');
    const [inputValue, setInputValue] = useState<string>(defaultValue ?? '');
    const isFocused = useFocusState(inputRef);

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password');
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleInputChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setInputValue(event.target.value);
    };

    const getIcon = () => {
        if (type !== 'password') {
            return icon as keyof IIcons;
        }

        return passwordType === 'password'
            ? 'HIDDEN_PASSWORD'
            : 'SHOW_PASSWORD';
    };

    return (
        <div
            className={classNames(containerStyle, 'flex flex-col mb-4', {
                'w-full': fullWidth,
                '!mb-1': isCheckbox,
            })}
        >
            {!isCheckbox && !!label && (
                <label
                    className={classNames(
                        'font-semibold text-sm mb-0 text-start font-poppins-medium tracking-tight',
                        {
                            'mb-2': isLabelMarginBottom,
                        },
                        getLabelColor({
                            isFocused,
                            value: String(props.value),
                            isError,
                            isHovered,
                        })
                    )}
                    htmlFor={props?.id}
                >
                    {label}
                    {asterisk && (
                        <span className="!text-red-200 text-lg pl-[2px] pt-3">
                            *
                        </span>
                    )}
                </label>
            )}
            <div
                className={`${
                    isCheckbox ? 'w-[22px]' : 'w-full'
                } input-with-icon relative flex items-center`}
            >
                {iconPosition === 'left' && (
                    <SvgIcon
                        icon={icon}
                        onClick={(e) => (iconOnClick ? iconOnClick(e) : '')}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-37%)',
                            left: iconPosition === 'left' ? '10px' : 'auto',
                            right: 'auto',
                        }}
                    />
                )}
                {isCheckbox ? (
                    <label className="container">
                        {label}
                        <input
                            onClick={(e) => e.stopPropagation()}
                            placeholder={placeHolder}
                            className={classNames(
                                className,
                                'flex text-sm w-full',
                                {
                                    'has-value': inputValue,
                                }
                            )}
                            style={{
                                color: '#393838',
                                borderRadius: 6,
                                padding: '13px 10px',
                            }}
                            onChange={(e) => {
                                if (onChange) {
                                    onChange(e);
                                    handleInputChange(e);
                                }
                            }}
                            type="checkbox"
                            pattern={pattern}
                            {...props}
                        />
                        <span className="checkmark" />
                    </label>
                ) : (
                    <input
                        defaultValue={defaultValue}
                        max={max}
                        ref={inputRef}
                        placeholder={placeHolder}
                        className={classNames(
                            className,
                            'flex text-sm w-full',
                            {
                                'input-field': noArrows,
                                'has-value': inputValue,
                            }
                        )}
                        pattern={pattern}
                        style={{
                            color: '#393838',
                            borderRadius: 6,
                            padding: isSearchBar ? '10px 10px' : '13px 10px',
                        }}
                        onChange={(e) => {
                            if (onChange) {
                                onChange(e);
                                handleInputChange(e);
                            }
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        type={type === 'password' ? passwordType : type}
                        {...props}
                    />
                )}
                {iconPosition === 'right' && (
                    <SvgIcon
                        icon={getIcon()}
                        onClick={(e) =>
                            iconOnClick ? iconOnClick(e) : togglePassword()
                        }
                        style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            left: iconPosition === 'right' ? 'auto' : '10px',
                            right: iconPosition === 'right' ? '10px' : 'auto',
                        }}
                    />
                )}
            </div>
            {errorMessage && (
                <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default Input;
