import './modal.css';
import SvgIcon from '../Icon/SvgIcon';
import { IModalProps } from '../common';

const Modal = ({
    title,
    setIsOpen,
    children,
    headerButton,
    icon = false,
    isTransactionModal,
    classname = '',
}: IModalProps) => {
    function getHeaderClass() {
        if (typeof title !== 'string') {
            return 'modalImageHeader';
        }
        if (isTransactionModal) {
            return 'modalHeader !bg-[#F8FBEA] border-gray-300 border-t-0 border-x-0 border-b-2 border-solid p-5 sticky top-0 z-[100]';
        }
        return 'modalHeader';
    }

    return (
        <div className="darkBG" style={{ position: 'absolute', zIndex: 102 }}>
            <div className="centered">
                <div className={`${classname} modal`}>
                    <div className={getHeaderClass()}>
                        {typeof title !== 'string' ? (
                            <div className="flex w-full justify-center font-merri-bold tracking-tight">
                                {title}
                            </div>
                        ) : (
                            <div
                                className={`heading ${
                                    isTransactionModal ? '!px-4' : ''
                                } font-merri-bold tracking-tight`}
                            >
                                {title}
                            </div>
                        )}
                        <div
                            className={`flex gap-5 ${
                                typeof title === 'string'
                                    ? 'items-center'
                                    : 'h-full'
                            }`}
                        >
                            {headerButton}
                            {icon && (
                                <SvgIcon
                                    onClick={() => setIsOpen(false)}
                                    icon="CLOSE_BUTTON"
                                    className="pr-2 cursor-pointer"
                                />
                            )}
                        </div>
                    </div>
                    <div className="modalContent">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
