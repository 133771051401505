import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { getCleoPayInCallback } from 'services/api/api';

const CleoCallback = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const type = searchParams.get('type') ?? '';
    const id = searchParams.get('id') ?? '';

    useEffect(() => {
        if (type && id) {
            getCleoPayInCallback(id)
                .then(() => {
                    navigate('/operations');
                })
                .catch(() => {
                    navigate('/operations');
                });
        }
    }, []);

    return (
        <div>
            <LoadingSpinner />
        </div>
    );
};

export default CleoCallback;
