import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import {
    PrimaryButton,
    Table,
    SvgIcon,
    Loader,
    RFSelectField,
    RFTextField,
} from 'components/Common';
import { getAllBrands, getAllPageAreas } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGetAllBrandData, IGetAllPageAreasData } from 'data/types/response';
import { useAuthContext } from 'context/Auth/AuthContext';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import {
    GroupDrawerAccessTableColumns,
    GroupDrawerPageAreaTableColumns,
} from '../GroupsDrawerUtill';
import { IGroupFormProps } from '../../Groups.type';
import {
    useCompanyOptionList,
    useFetchCompanies,
} from '../../../useAdminCompany';

export const GroupForm = ({
    onSubmit,
    loading,
    control,
    isEditGroupDrawer,
    openDrawer,
    isSuperAdmin,
    isOpen,
    watch,
    editCompany,
}: IGroupFormProps) => {
    const { auth, dispatch } = useAuthContext();
    const [allBrandData, setAllBrandData] = useState<IGetAllBrandData[] | null>(
        null
    );
    const [allPageAreaData, setAllPageAreaData] = useState<
        IGetAllPageAreasData[] | null
    >(null);
    const [pageAreaLoading, setPageAreaLoading] = useState<boolean>(false);
    const [brandLoading, setBrandLoading] = useState<boolean>(false);

    const { allCompanies } = useFetchCompanies({ isOpen, auth });
    const companyOptionList = useCompanyOptionList({ auth, allCompanies });

    const handleGetData = () => {
        setPageAreaLoading(true);
        setBrandLoading(true);
        if (isOpen) {
            getAllBrands({
                filter: {
                    companyId: watch('companyId') || editCompany,
                },
            })
                .then((result) => {
                    dispatch({
                        type: AppActionsEnum.SET_BRANDS_LIST,
                        payload: {
                            brandsList: result?.brands,
                        },
                    });
                    setAllBrandData(result?.brands);
                    setBrandLoading(false);
                })
                .catch((err) => {
                    setBrandLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });

            if (
                auth.pageAreaPermissions &&
                auth.pageAreaPermissions.length > 0
            ) {
                setAllPageAreaData(auth.pageAreaPermissions);
                setPageAreaLoading(false);
            } else {
                getAllPageAreas()
                    .then((result) => {
                        dispatch({
                            type: AppActionsEnum.SET_PAGE_AREA_PERMISSIONS,
                            payload: {
                                pageAreaPermissions: result?.pageAreas,
                            },
                        });
                        setAllPageAreaData(result?.pageAreas);
                        setPageAreaLoading(false);
                    })
                    .catch((err) => {
                        setPageAreaLoading(false);
                        showToast(
                            err?.errors?.[0]?.message || 'something went wrong',
                            'error'
                        );
                    });
            }
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => handleGetData(), 300);
        return () => clearTimeout(timeoutId);
    }, [isOpen, watch('companyId')]);

    return (
        <form onSubmit={onSubmit}>
            <div className="p-5">
                <div className="flex items-center justify-between">
                    <div className="my-0 text-xl font-black text-green-100 font-merri-bold tracking-tighter">
                        {isEditGroupDrawer ? 'Update Group' : 'Add New Group'}
                    </div>
                    <SvgIcon
                        className="cursor-pointer"
                        icon="CLOSE_BUTTON"
                        onClick={openDrawer}
                    />
                </div>
                <div className="mt-5">
                    <RFTextField
                        control={control}
                        name="groupName"
                        fullWidth
                        label="Name of Group"
                        type="text"
                        asterisk
                        placeholder="Customer Service.."
                    />

                    {isSuperAdmin && (
                        <div className="mt-5">
                            <RFSelectField
                                name="companyId"
                                control={control}
                                fullWidth
                                disabled={isEditGroupDrawer}
                                label="Select Your Company"
                                asterisk
                                options={companyOptionList || []}
                            />
                        </div>
                    )}
                </div>
                <div className="mt-3">
                    <div>
                        {brandLoading ? (
                            <Loader />
                        ) : (
                            <div>
                                <Controller
                                    name="brandsPermissions"
                                    control={control}
                                    render={({
                                        field: { onChange, value, ...field },
                                        fieldState,
                                    }) => (
                                        <>
                                            <Table
                                                colSpan={3}
                                                isSidebarTable
                                                data={allBrandData || []}
                                                columns={GroupDrawerAccessTableColumns(
                                                    false,
                                                    onChange,
                                                    value
                                                )}
                                                className="w-full"
                                                {...field}
                                            />
                                            <div className="mb-1 text-sm font-medium text-red-200">
                                                {fieldState.error?.message
                                                    ? fieldState.error?.message
                                                    : ''}
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        {pageAreaLoading ? (
                            <Loader />
                        ) : (
                            <div>
                                <Controller
                                    name="pageAreasPermissions"
                                    control={control}
                                    render={({
                                        field: { onChange, value, ...field },
                                        fieldState,
                                    }) => (
                                        <>
                                            <Table
                                                isSidebarTable
                                                data={allPageAreaData || []}
                                                columns={GroupDrawerPageAreaTableColumns(
                                                    false,
                                                    onChange,
                                                    value
                                                )}
                                                className="w-full"
                                                colSpan={3}
                                                {...field}
                                            />
                                            <div className="mb-1 text-sm font-medium text-red-200">
                                                {fieldState.error?.message
                                                    ? fieldState.error?.message
                                                    : ''}
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex mt-2 p-5 sticky bottom-0 bg-white z-10">
                <PrimaryButton
                    disabled={loading}
                    isDrawerButton
                    type="submit"
                    className={classNames('w-full')}
                    color="#2E672F"
                    variant="filled"
                    loading={loading}
                    name={
                        loading
                            ? ''
                            : isEditGroupDrawer
                            ? 'Update Group'
                            : 'Create New Group'
                    }
                />
            </div>
        </form>
    );
};
