import React from 'react';

const CompanySelectionMessage = () => {
    return (
        <div className="flex justify-center w-full py-10 text-center">
            <div className="flex flex-col gap-6">
                <div className="text-4xl font-poppins-bold tracking-tight text-gray-500">
                    Please select company or brand
                </div>
            </div>
        </div>
    );
};

export default CompanySelectionMessage;
