import { IBrandData } from 'data/common';
import { Switch, TextField } from 'components/Common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { IColumnType } from 'components/Common/common';
import { IBrandsTableColumnProps } from '../Brads.type';

export const BrandsTableColumn = ({
    isAllChecked,
    setCheckedBrands,
    checkedBrands,
    setBrand,
    setIsOpen,
    getUsers,
    setIsEditBrandsDrawer,
    brands,
    isAllowedUser,
    setShowDisableModal,
    setSelectedBrand,
}: IBrandsTableColumnProps): IColumnType<IBrandData>[] => {
    const handleSelectAll = () => {
        const filteredBrands =
            brands?.filter((brand) => !brand?.isDeleted) ?? [];
        setCheckedBrands(filteredBrands?.map((item) => item?._id));
    };

    const handleDeselectAll = () => {
        setCheckedBrands([]);
    };

    return [
        {
            key: 'checked',
            width: 20,
            title: (
                <div className="flex justify-center">
                    <TextField
                        checked={isAllChecked}
                        isCheckbox
                        onChange={() =>
                            isAllChecked
                                ? handleDeselectAll()
                                : handleSelectAll()
                        }
                        type="checkbox"
                    />
                </div>
            ),
            render: (_, i) => (
                <div className="flex justify-center">
                    <TextField
                        isCheckbox
                        disabled={i?.isDeleted}
                        checked={
                            checkedBrands?.find((item) => item === i._id) ===
                            i._id
                        }
                        onChange={() => {
                            if (
                                checkedBrands?.includes(i._id) ||
                                isAllChecked
                            ) {
                                setCheckedBrands(
                                    checkedBrands?.filter(
                                        (item) => item !== i?._id
                                    )
                                );
                            } else {
                                setCheckedBrands([...checkedBrands, i?._id]);
                            }
                        }}
                        type="checkbox"
                    />
                </div>
            ),
        },
        {
            key: 'mid',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        MID
                    </div>
                </div>
            ),
            width: 100,
        },
        {
            key: 'name',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Name
                    </div>
                </div>
            ),
            width: 200,
        },
        {
            key: 'description',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Description
                    </div>
                </div>
            ),
            width: 200,
        },
        {
            key: 'adminUser',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Admin User
                    </div>
                </div>
            ),
            width: 200,
        },
        {
            key: 'countries',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Countries
                    </div>
                </div>
            ),
            render: (_i, index) => (
                <div className="tooltip">
                    {index?.countries?.join(',')?.length > 25
                        ? index?.countries
                              ?.join(',')
                              ?.slice(0, 15)
                              .concat('...', '')
                        : index?.countries?.join(',')}
                    <span
                        className={`${
                            index?.countries?.join(',')?.length > 25
                                ? 'tooltiptext'
                                : ''
                        }`}
                    >
                        {index?.countries?.join(',')?.length > 25
                            ? index?.countries?.join(',')
                            : ''}
                    </span>
                </div>
            ),
            width: 200,
        },
        {
            key: 'pspType',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Psps
                    </div>
                </div>
            ),
            width: 200,
        },
        {
            key: 'currencies',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Currencies
                    </div>
                </div>
            ),
            render: (_i, index) => (
                <div className="tooltip">
                    {index?.currencies?.join(',')?.length > 25
                        ? index?.currencies
                              ?.join(',')
                              ?.slice(0, 15)
                              .concat('...', '')
                        : index?.currencies?.join(',')}
                    <span
                        className={`${
                            index?.currencies?.join(',')?.length > 25
                                ? 'tooltiptext'
                                : ''
                        }`}
                    >
                        {index?.currencies?.join(',')?.length > 25
                            ? index?.currencies?.join(',')
                            : ''}
                    </span>
                </div>
            ),
            width: 200,
        },
        {
            key: 'locals',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Locals
                    </div>
                </div>
            ),
            width: 200,
        },

        {
            key: 'edit',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Edit
                    </div>
                </div>
            ),
            width: 70,
            render: (_, i) => (
                <SvgIcon
                    className={`cursor-pointer ${
                        !isAllowedUser ? 'pointer-events-none opacity-50' : ''
                    }`}
                    onClick={() => {
                        if (i?.isDeleted) return;
                        getUsers(i?.companyId);
                        setIsOpen(true);
                        setBrand(i);
                        setIsEditBrandsDrawer(true);
                    }}
                    icon="EDIT_ICON"
                />
            ),
        },

        {
            key: 'disable',
            title: (
                <div className="flex justify-center">
                    <div className="font-poppins-medium tracking-tight text-xs">
                        Disable
                    </div>
                </div>
            ),
            width: 70,
            render: (_, i) => (
                <Switch
                    disabled={!isAllowedUser}
                    handleCheck={() => {
                        setSelectedBrand(i);
                        setShowDisableModal(true);
                    }}
                    isToggled={i?.isDeleted}
                    className="rounded-2xl bg-gray-200"
                />
            ),
        },
    ];
};
