import { useState } from 'react';
import classNames from 'classnames';
import OtpInput from 'react-otp-input';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loginLogo } from 'assets';
import { ResetPassword } from 'components/AuthComponent';
import { resetPassword, verifyOtp } from 'services/api/api';
import { IPasswordData } from 'data/types/auth';
import { showToast } from 'data/utils/toast';
import { AuthenticationModal } from 'components/Modal';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IVerifyOTP } from 'data/types/request';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { PrimaryButton } from 'components/Common';
import { formValidation } from './NewAdminPassword';

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { dispatch } = useAuthContext();
    const token = searchParams.get('token') ?? '';
    const [open, setOpen] = useState<boolean>(false);
    const [qrCode, setQrCode] = useState('');
    const [otp, setOtp] = useState<string>('');
    const [otpError, setOtpError] = useState<string>('');
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { control, handleSubmit, reset } = useForm<IPasswordData>({
        resolver: zodResolver(formValidation),
        defaultValues: {},
    });

    const digitLeftCount = 6 - otp.length;

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const payload = {
            token,
            password: formData?.password || '',
        };
        setIsLoading(true);
        resetPassword(payload)
            .then((result) => {
                if (result.isSuccess) {
                    if (result?.qr) {
                        setQrCode(result?.qr);
                    }
                    setOpen(true);
                    reset({});
                    showToast(
                        result.message,
                        result.isSuccess ? 'success' : 'error'
                    );
                }
            })
            .catch((err) => {
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    const onVerifyOtp = () => {
        if (!otp) {
            setOtpError('Please Enter your OTP');
            return;
        }
        if (otp.length < 6) {
            setOtpError('Enter 6 digit OTP');
            return;
        }
        const payload: IVerifyOTP = { code: otp };
        setIsOtpLoading(true);
        verifyOtp(payload, token)
            .then((result) => {
                localStorage.setItem('token', result.token || token);
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                    },
                });
                setOtpError('');
                showToast('successfully verified', 'success');
                setOpen(false);
                navigate('/operations');
            })
            .catch((err) => {
                showToast(err.message, 'error');
            })
            .finally(() => {
                setIsOtpLoading(false);
            });
    };

    const getBorderStyle = (value: number) => {
        if (otpError && otp.length < 6) {
            return '1px solid red';
        }
        if (otp && value) {
            return '2px solid #9ED79F';
        }
        return 'none';
    };

    return (
        <div className="flex w-[100vw] h-[100vh]">
            <div className="flex w-1/2 bg-amber-100">
                <img
                    src={loginLogo}
                    className="loginLogo"
                    alt="logo"
                    width="100%"
                    height="100%"
                />
            </div>
            <div className="mx-14 flex items-center justify-center w-1/2">
                {qrCode ? (
                    <div className="mx-auto flex flex-col items-start justify-center w-[500px] max-[900px]:w-[300px]">
                        <div className="flex flex-col items-center gap-5">
                            <div>
                                <SvgIcon
                                    className="mb-11 flex flex-col items-center"
                                    icon="GOOGLE_LOGO"
                                />
                                <div className="mt-1 flex justify-center text-xl font-extrabold text-green-700">
                                    Two-Factor Authentication - A Breeze!
                                </div>
                                <div>
                                    Download the free{' '}
                                    <span className="font-extrabold text-green-200">
                                        Google Authenticator{' '}
                                    </span>{' '}
                                    App, add a new account, then scan the
                                    Barcode to set up your account.
                                </div>
                            </div>
                        </div>
                        <img
                            src={qrCode}
                            alt="code"
                            height="30%"
                            width="50%"
                            className="mx-auto"
                        />
                        <div className="mx-auto">
                            <OtpInput
                                value={otp}
                                onChange={(value: string) => setOtp(value)}
                                numInputs={6}
                                renderSeparator={
                                    <span style={{ marginLeft: '13px' }} />
                                }
                                renderInput={(props: any) => (
                                    <input
                                        id="inputOtp"
                                        {...props}
                                        style={{
                                            width: '53px',
                                            height: '55px',
                                            color: 'text-green-600',
                                            background: '#F7F7F7',
                                            border: getBorderStyle(props.value),
                                            borderRadius: 15,
                                            fontSize: '30px',
                                            fontWeight: 500,
                                            textAlign: 'center',
                                            outline: props.value
                                                ? '3px solid #9ED79F'
                                                : '',
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <PrimaryButton
                            onClick={onVerifyOtp}
                            type="submit"
                            name={
                                digitLeftCount === 0
                                    ? `Let's go`
                                    : `${digitLeftCount} Digits Left`
                            }
                            isDrawerButton
                            color="#2E672F"
                            variant="filled"
                            className={classNames(
                                'ml-5 mt-5 flex justify-center items-center w-[88%] h-10 rounded-xl bg-zinc-300 text-base font-bold text-gray-400',
                                {
                                    'bg-green-700 text-white':
                                        digitLeftCount === 0,
                                }
                            )}
                        />
                    </div>
                ) : (
                    <div>
                        <ResetPassword
                            handleSubmit={onSubmit}
                            control={control}
                            isLoading={isLoading}
                        />
                        {open && (
                            <AuthenticationModal
                                setIsOpen={setOpen}
                                handleVerifyOTP={onVerifyOtp}
                                setOtp={setOtp}
                                isOtpLoading={isOtpLoading}
                                otp={otp}
                                otpError={otpError}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResetPasswordPage;
