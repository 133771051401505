import React, { ChangeEvent } from 'react';
import {
    DatePicker,
    MultiSelectDropdown,
    TextField,
    Select,
} from 'components/Common';
import { ICountry } from 'components/OnBoardCashier/OnBoardCashier.type';
import { getListName } from 'data/utils/common';
import {
    ConditionType,
    defaultData,
    IConditionDataProps,
} from '../../../RuleEngine.type';

export const getInputField = (
    v: IConditionDataProps,
    index: number,
    handleMultiSelect: (selectedLists: any, index: number) => void,
    handleChange: (
        index: number,
        value: string | number | undefined | string[]
    ) => void,
    countries?: ICountry[],
    Mids?: string[]
) => {
    const countryNameList: string[] | undefined = countries?.map(
        (data) => data?.name?.common
    );

    if (countries) {
        defaultData['Bank Country'] = countryNameList || [];
        defaultData['User country'] = countryNameList || [];
        defaultData['Bin issuer country'] = countryNameList || [];
        defaultData['IP country'] = countryNameList || [];
    }
    if (Mids) {
        defaultData['Merchant Id'] = Mids;
    }

    const handleBothInputChange = (
        index1: number,
        value: string | number | undefined,
        type: 'input' | 'select',
        currentData: [string, string]
    ) => {
        const [inputValue, selectValue] = currentData;
        const updatedValues: [string, string] = [
            inputValue || '',
            selectValue || '',
        ];

        if (type === 'input') {
            updatedValues[0] = value as string;
        } else if (type === 'select') {
            updatedValues[1] = value === 'Currency' ? '' : (value as string);
        }
        handleChange(index1, updatedValues);
    };

    const currentValues: any = v.items ?? ['', ''];

    switch (v.conditionType) {
        case ConditionType.MULTI_SELECTION:
            return (
                <MultiSelectDropdown
                    backgroundColor="#ffffff"
                    style={{
                        chips: {
                            background: '#F5F5F5',
                            color: 'black',
                            borderRadius: '4px',
                            padding: '5px',
                            fontWeight: 500,
                            height: '24px',
                            marginBottom: '2px',
                        },
                        optionContainer: {
                            maxHeight: '250px',
                        },
                    }}
                    className="mb-3 custom-multiselect"
                    selectedValues={(v?.items as string[])?.map(
                        (list: string) => ({
                            name: getListName(list),
                            id: list,
                        })
                    )}
                    showArrow
                    placeholder={`Select ${v?.title?.toLowerCase()}...`}
                    options={defaultData[v?.title]?.map((list: any) =>
                        typeof list !== 'object'
                            ? { name: list, id: list }
                            : { name: list.name, id: list._id }
                    )}
                    displayValue="name"
                    onSelect={(selectedLists) => {
                        handleMultiSelect(selectedLists, index);
                    }}
                    onRemove={(selectedLists) => {
                        handleMultiSelect(selectedLists, index);
                    }}
                />
            );
        case ConditionType.SELECT:
            return (
                <Select
                    bordered
                    isCompanyAdmin
                    isGroup
                    fullWidth
                    options={[
                        {
                            id: '',
                            name: `Select ${v?.title?.toLowerCase()}...`,
                        },
                        ...(defaultData[v?.title]?.map((list: any) =>
                            typeof list !== 'object'
                                ? { name: list, id: list }
                                : { name: list.name, _id: list._id }
                        ) || []),
                    ]}
                    value={(v?.items as string) || ''}
                    handleOnSelect={(e: ChangeEvent<HTMLSelectElement>) => {
                        handleChange(
                            index,
                            !e.target.value ||
                                e.target.value ===
                                    `Select ${v?.title?.toLowerCase()}...`
                                ? ''
                                : e.target.value
                        );
                    }}
                    className="w-full text-gray-400 !border-2 !border-[#f1f1f1] !rounded-lg hover:!border-[#9ed79f]"
                />
            );
        case ConditionType.YES_NO_DROPDOWN:
            return (
                <Select
                    bordered
                    isCompanyAdmin
                    isGroup
                    fullWidth
                    options={[
                        {
                            id: '',
                            name: `Select ${v?.title?.toLowerCase()}...`,
                        },
                        {
                            _id: true,
                            name: 'Yes',
                        },
                        {
                            _id: false,
                            name: 'No',
                        },
                    ]}
                    value={(v?.items as string) || ''}
                    handleOnSelect={(e: ChangeEvent<HTMLSelectElement>) => {
                        handleChange(
                            index,
                            !e.target.value ||
                                e.target.value ===
                                    `Select ${v?.title?.toLowerCase()}...`
                                ? ''
                                : e.target.value
                        );
                    }}
                    className="w-full text-gray-400 !border-2 !border-[#f1f1f1] !rounded-lg hover:!border-[#9ed79f]"
                />
            );
        case ConditionType.DATEPICKER:
            return (
                <DatePicker
                    onChange={(selectedDates: any) => {
                        handleChange(index, selectedDates);
                    }}
                    dateTimePickerProps={{
                        options: {
                            minDate: new Date(),
                        },
                    }}
                    placeholder={`Select ${v?.title?.toLowerCase()}...`}
                    fullWidth
                    name={v.title}
                />
            );

        case ConditionType.TEXT_INPUT:
            return (
                <TextField
                    fullWidth
                    name={v.title}
                    value={v.items ?? ''}
                    type="text"
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-100"
                    onChange={(e) => handleChange(index, e.target.value)}
                />
            );

        case ConditionType.NUMBER_INPUT:
            return (
                <TextField
                    fullWidth
                    type="number"
                    noArrows
                    name={v.title}
                    value={v.items ?? ''}
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-100 !bg-white"
                    onChange={(e) => handleChange(index, e.target.value)}
                />
            );

        case ConditionType.TEXT_INPUT_REGEX:
        case ConditionType.INPUT_JOIN:
            return (
                <TextField
                    fullWidth
                    type="text"
                    noArrows
                    name={v.title}
                    value={v.items ?? ''}
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-100 !bg-white"
                    onChange={(e) => handleChange(index, e.target.value)}
                    pattern=""
                />
            );

        case ConditionType.PERCENTAGE_INPUT:
            return (
                <TextField
                    fullWidth
                    type="number"
                    noArrows
                    name={v.title}
                    value={v.items ?? ''}
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-100 !bg-white"
                    onChange={(e) => handleChange(index, e.target.value)}
                    pattern=""
                />
            );
        case ConditionType.MONEY_COUNTRY:
            return (
                <div className="flex gap-2 justify-between w-full">
                    <TextField
                        fullWidth
                        type="number"
                        noArrows
                        name={v.title}
                        title={v.title}
                        value={currentValues[0]} // First value for input
                        placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                        className="text-gray-100 !bg-white"
                        onChange={(e) =>
                            handleBothInputChange(
                                index,
                                e.target.value,
                                'input',
                                currentValues
                            )
                        }
                        pattern=""
                    />
                    <Select
                        bordered
                        isCompanyAdmin
                        isGroup
                        options={[
                            {
                                id: '',
                                name: `Currency`,
                            },
                            ...(defaultData[v?.title]?.map((list: any) =>
                                typeof list !== 'object'
                                    ? { name: list, id: list }
                                    : { name: list.name, _id: list._id }
                            ) || []),
                        ]}
                        value={currentValues[1]} // Second value for select
                        handleOnSelect={(e: ChangeEvent<HTMLSelectElement>) => {
                            handleBothInputChange(
                                index,
                                !e.target.value ||
                                    e.target.value ===
                                        `Select ${v?.title?.toLowerCase()}...`
                                    ? ''
                                    : e.target.value,
                                'select',
                                currentValues
                            );
                        }}
                        className="!w-28 text-gray-400 !border-2 !border-[#f1f1f1] !rounded-lg hover:!border-[#9ed79f]"
                    />
                </div>
            );
        default:
            return (
                <TextField
                    fullWidth
                    name={v.title}
                    value={v.items ?? ''}
                    type="text"
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-400 !bg-white"
                    onChange={(e) => handleChange(index, e.target.value)}
                />
            );
    }
};
