import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Loader, PrimaryButton, SvgIcon } from 'components/Common';
import ReactFlow, {
    addEdge,
    Background,
    Connection,
    ConnectionLineType,
    Edge,
    MarkerType,
    Panel,
    Position,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from 'reactflow';
import {
    getGroupRule,
    getViewRule,
    saveRule,
    updateRule,
} from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { ISaveRule } from 'data/types/request';
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal';
import useRemoveTreeOfChildNode from 'hooks/useRemoveTreeOfChildNode';
import 'reactflow/dist/style.css';
import RuleBuilderDrawer from './RuleBuilderDrawer/RuleBuilderDrawer';
import SimpleFloatingEdge from './SimpleFloatingEdges/SimpleFloatingEdge';
import { INodeData, INodeType } from './RuleBuilder.type';
import './RuleBuilderDrawer/ruleBuilderDrawer.css';
import { CustomNode } from './CustomeNode/CustomNode';
import { getPosition, getTargetPos, RuleBuilderTabs } from '../RuleEngine.type';

const edgeTypes = {
    floating: SimpleFloatingEdge,
};

const nodeTypes = {
    custom: CustomNode,
};

const offsetY = 330; // Adjust this offset as needed

const CreateRuleBuilder = () => {
    const navigate = useNavigate();

    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);

    const reactFlow = useReactFlow();
    const { zoomIn, zoomOut } = useReactFlow();
    const [searchParams] = useSearchParams();

    const dataId = searchParams.get('dataId');
    const GroupId = searchParams.get('groupsId');
    const order = searchParams.get('order');

    const [loading, setLoading] = useState<boolean>(false);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [isConditionOpen, setIsConditionOpen] = useState<boolean>(false);
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
    const [ruleTitle, setRuleTitle] = useState<string>('');
    const [selectNode, setSelectNode] = useState<string>('');
    const [captureElementNode, setCaptureElementNode] = useState<any>(null);
    const [groupId, setGroupId] = useState<string | undefined>('');
    const [isLoading, setIsLoading] = useState(false);
    const [groupCardData, setGroupCardData] = useState<any>([]);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState<boolean>(false);
    const [addConditionModal, setAddConditionModal] = useState<boolean>(false);
    const [closeRuleModal, setCloseRuleModal] = useState<boolean>(false);
    const [closeRuleNode, setCloseRuleNode] = useState<INodeData>();
    const [isInptValueChanged, setIsInptValueChanged] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [srcPos, setSrcPos] = useState('');
    const [ruleDrawerTab, setRuleDrawerTab] = useState(
        RuleBuilderTabs.CONDITION
    );

    const onConnect = useCallback((params: Edge<any> | Connection) => {
        // Here, you can handle the connection logic
        connectingNodeId.current = null;
        setEdges((existingEdges) =>
            addEdge(
                {
                    ...params,
                    type: 'floating',
                    interactionWidth: 100,
                    // markerStart: { type: MarkerType.ArrowClosed },
                    // markerEnd: { type: MarkerType.Arrow },
                },
                existingEdges
            )
        );
    }, []);

    const onNodeClick = (event: any, node: any) => {
        const handlePos = event.target.getAttribute('data-handlepos');
        if (handlePos) return; // return if clicked on plus icon instead of node
        setCaptureElementNode(node);
        setIsOpenDrawer(true);
        setIsConditionOpen(true);
    };

    const onConnectStart = useCallback(
        (event: any, { nodeId }: any) => {
            const pos = event.target.getAttribute('data-handlepos');
            const isActionTab = event.target.getAttribute('data-isaction');
            setRuleDrawerTab(
                isActionTab === 'true'
                    ? RuleBuilderTabs.ACTION
                    : RuleBuilderTabs.CONDITION
            );
            setSrcPos(pos);
            const iid = `${pos as string}-${nodeId as string}`;

            const isDuplicateConnection = edges.some((ele) => {
                if (ele.sourceHandle === iid) {
                    showToast('Only one rule can connect', 'error');
                    return true;
                }
                return false;
            });

            if (isDuplicateConnection) return;

            connectingNodeId.current = nodeId;
            if (!connectingNodeId.current) return;
            // if (
            //     edges.some((node) => node.source === connectingNodeId.current)
            // ) {
            //     showToast('Only one rule can connect', 'error');
            //     return;
            // }
            const targetIsPane =
                event.target.classList.contains('react-flow__handle');

            if (targetIsPane) {
                setIsOpenDrawer(true);
            }
        },
        [edges]
    );

    const generateTitle = (index: number) => {
        // Add 1 to the index since indexing usually starts from 0
        const titleIndex = index + 1;

        // Generate the title based on the index
        const paddedIndex =
            titleIndex < 10 ? `0${titleIndex}` : String(titleIndex);
        return `R_${paddedIndex}`;
    };

    useEffect(() => {
        if (!selectNode) return;
        const filterNode = nodes.filter((v) => v.id !== selectNode);
        reactFlow.setEdges((li) => li.filter((edge) => edge.id !== selectNode));
        reactFlow.setEdges((li) =>
            li.filter((edge) => edge.target !== selectNode)
        );
        setNodes(filterNode);
        setSelectNode('');
    }, [selectNode]);

    const getRuleData = () => {
        if (!GroupId) return;
        getGroupRule(GroupId)
            .then((res) => {
                const sortedData = res?.rules.sort(
                    (a: any, b: any) => a.order - b.order
                );
                setGroupCardData(sortedData);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getRuleData();
    }, []);

    useEffect(() => {
        const ruleTitleNumber = groupCardData.map((item: any) =>
            parseInt(item?.name?.split('_')[1])
        );

        const maxNumber = Math.max(...ruleTitleNumber);
        const minNumber = Math.min(...ruleTitleNumber);

        const allNumbers = Array.from(
            { length: maxNumber - minNumber + 1 },
            (_, i) => i + minNumber
        );

        const missingRuleNumbers = allNumbers.filter(
            (num) => !ruleTitleNumber.includes(num)
        );

        const addRuleNumber =
            missingRuleNumbers.length > 0
                ? Number(missingRuleNumbers[0]) - 1
                : Number(order);
        const ruleName = generateTitle(addRuleNumber);
        setRuleTitle(ruleName);
    }, [groupCardData]);

    const lastNode = nodes.at(-1);

    const removeTreeOfChildNode = useRemoveTreeOfChildNode({
        setNodes,
        setEdges,
        setCloseRuleModal,
    });

    const onAdd = useCallback(
        (text: string, type: string, actionType?: string) => {
            if (nodes.length === 0) {
                const defaultNode = {
                    key: '0',
                    id: '0',
                    type: 'input',
                    sourcePosition: Position.Right,
                    data: {
                        label: 'Start',
                        id: 0,
                    },
                    position: { x: 100, y: offsetY },
                };
                setNodes((nds) => nds.concat(defaultNode));
            }

            setIsDataChanged(true);

            if (
                nodes.some(
                    (node) =>
                        node.data.label === text &&
                        node.data.actionType === actionType
                )
            ) {
                showToast(
                    `Same ${
                        actionType === RuleBuilderTabs.CONDITION
                            ? 'rule'
                            : 'action'
                    } already exists`,
                    'error'
                );
                return;
            }

            const nodeId = Number(lastNode?.id) + 1 || 1;
            const srcNode = nodes.find(
                (ele) => ele.id === connectingNodeId.current
            );
            const { x, y } = getPosition(srcPos, srcNode);

            if (!!text && !!type) {
                const newNode: INodeType = {
                    key: String(nodeId),
                    id: String(nodeId),
                    data: {
                        label: text,
                        type,
                        id: String(nodeId),
                        items: undefined,
                        operation: undefined,
                        setCloseRuleModal,
                        setCloseRuleNode,
                        srcPos,
                    },
                    position: {
                        x: x === 0 ? 400 : x,
                        y: y === 0 ? offsetY : y,
                    },
                    // sourcePosition: Position.Right,
                    targetPosition: getTargetPos(srcPos),
                    source: connectingNodeId.current ?? '0',
                    target: String(nodeId),
                    type: 'custom',
                    extent: connectingNodeId.current ? 'parent' : undefined,
                    isParent: !!connectingNodeId.current,
                };
                setIsConditionOpen(true);
                setCaptureElementNode(newNode as any);
                setIsOpenDrawer(true);
                setNodes((nds) => nds.concat({ ...newNode }));
                setEdges((eds: Edge<any>[]) => {
                    const newEdge: Edge<any> = {
                        id: String(nodeId),
                        source: connectingNodeId.current ?? '0',
                        target: String(nodeId),
                        // type: 'floating',
                        interactionWidth: 2,
                        sourceHandle: `${srcPos}-${
                            connectingNodeId.current ?? nodeId - 1
                        }`,
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            color: '#000000',
                        },
                        // markerStart: {
                        //     type: MarkerType.ArrowClosed,
                        //     color: '#000000',
                        // },
                        style: { stroke: '#000000' },
                    };
                    return [...eds, newEdge];
                });
            }
        },
        [nodes, srcPos]
    );

    useEffect(() => {
        if (!isOpenDrawer && nodes.length === 1) {
            const filteredNodes = nodes.filter(
                (ele) => ele?.data?.label !== 'Start'
            );
            setNodes(filteredNodes);
            connectingNodeId.current = null;
            setSrcPos('');
        }
    }, [nodes, isOpenDrawer]);

    const handleAdd = () => {
        setIsOpenDrawer(true);
    };

    useEffect(() => {
        const handleKeyPress = (event: any) => {
            if (event.key === '=' || event.key === '+') {
                handleAdd();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [nodes, setNodes]);

    const openDrawer = () => {
        const shouldOpenModal = !lastNode?.data?.items || isInptValueChanged;
        if (shouldOpenModal && isConditionOpen) {
            setAddConditionModal(true);
        } else {
            setIsOpenDrawer((prevState) => !prevState);
            setIsConditionOpen(false);
        }
    };

    const onNodeDragStop = useCallback(
        (_: any, node: { id: string; position: { x: any; y: any } }) => {
            setNodes((prevNodes) =>
                prevNodes.map((n) => {
                    if (n.id === node.id) {
                        return {
                            ...n,
                            position: {
                                x: node.position.x,
                                y: node.position.y,
                            },
                        };
                    }
                    return n;
                })
            );
        },
        [setNodes]
    );

    // const newnodes = newNodes.filter((Newnode: any) => {
    //     if (
    //         Newnode?.data?.type === 'MULTI_SELECT' &&
    //         Newnode?.data?.items?.includes(
    //             CardConstant.AMERICAN_EXPRESS,
    //             CardConstant.MASTERCARD,
    //             CardConstant.VISA
    //         )
    //         )
    //     ) {
    //         const newList = Newnode?.data?.items.map((v: any) =>
    //             getListName(v)
    //         );
    //
    //         Newnode.data.items = newList;
    //     }
    //     return Newnode;
    // });

    const getRule = () => {
        if (!dataId) {
            setGroupId(GroupId as string);
            return;
        }
        setLoading(true);
        getViewRule(dataId)
            .then((res: any) => {
                const parsedData = JSON.parse(res?.rule?.data);
                // setNewNodes(parsedData?.nodes);
                const nodesData = parsedData.nodes.map((ele: INodeType) => {
                    return {
                        ...ele,
                        data: {
                            ...ele.data,
                            setCloseRuleModal,
                            setCloseRuleNode,
                        },
                    };
                });

                setRuleTitle(res?.rule?.name);
                setNodes(nodesData);
                setEdges(parsedData?.edges);
                setGroupId(res?.rule?.groupId);
                setLoading(false);
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getRule();
    }, []);

    // const getFilteredNodes = (nodes: any) => {
    //     const newFilteredNodes = nodes.map((Newnode: any) => {
    //         if (
    //             Newnode?.data?.type === 'MULTI_SELECT' &&
    //             Newnode?.data?.items?.includes(
    //                 CardConstantLabel.AMERICAN_EXPRESS,
    //                 CardConstantLabel.MASTERCARD,
    //                 CardConstantLabel.VISA
    //             )
    //         ) {
    //             const newList = Newnode?.data?.items.map((v: any) => {
    //                 switch (v) {
    //                     case CardConstantLabel.MASTERCARD:
    //                         return CardConstant.MASTERCARD;
    //                     case CardConstantLabel.VISA:
    //                         return CardConstant.VISA;
    //                     case CardConstantLabel.AMERICAN_EXPRESS:
    //                         return CardConstant.AMERICAN_EXPRESS;
    //                     default:
    //                         return v;
    //                 }
    //             });
    //
    //             Newnode.data.items = newList;
    //         } else {
    //             console.log('error');
    //         }
    //         return Newnode;
    //     });
    //     return newFilteredNodes;
    // };

    const handleSaveRule = () => {
        if (nodes.length === 0 || nodes.length === 1) {
            showToast('Please select your build rule first!', 'error');
            return;
        }
        if (!ruleTitle) {
            showToast('Please enter your rule title.', 'error');
            return;
        }
        const isActionNodeAvailable = (nodes as INodeType[]).some(
            (ele) => ele?.data?.actionType === RuleBuilderTabs.ACTION
        );
        if (!isActionNodeAvailable) {
            showToast('Please add at least one action.', 'error');
            return;
        }
        const combineData = { nodes, edges };
        const pspType = (nodes || []).find((v) => v.data.label === 'PSP');
        const { items = 'CLEO' } = pspType?.data || {};
        const dataToSave: ISaveRule = {
            name: ruleTitle,
            action: items,
            data: JSON.stringify(combineData),
            groupId,
            order: parseInt(order as string),
        };
        if (!groupId) return;
        setIsLoading(true);
        if (GroupId) {
            saveRule(dataToSave)
                .then((res: any) => {
                    showToast(res?.message, 'success');
                    setIsLoading(false);
                    setIsDataChanged(false);
                    navigate('/workflows/orchestration');
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        } else if (dataId) {
            delete dataToSave.order;
            updateRule(dataToSave, dataId)
                .then((res: any) => {
                    showToast(res?.message, 'success');
                    setIsLoading(false);
                    navigate('/workflows/orchestration');
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        }
    };
    const nodesLengthRef = useRef(0);

    useEffect(() => {
        if (nodesLengthRef.current !== 0) {
            setIsDataChanged(true);
        } else {
            nodesLengthRef.current = nodes.length;
        }
    }, [nodes.length]);

    const handleBack = () => {
        if (isDataChanged) {
            setIsConfirmationModalOpen(true);
        } else {
            navigate('/workflows/orchestration');
        }
    };

    const handleRemoveLastNode = () => {
        const filterEdges = edges.filter(
            (ele) => ele?.id !== captureElementNode?.data?.id
        );
        const filterNodes = nodes.filter(
            (ele) => ele?.data?.label !== captureElementNode?.data?.label
        );
        if (
            lastNode?.id === captureElementNode?.id &&
            !captureElementNode?.data?.items
        ) {
            setNodes(filterNodes);
            setEdges(filterEdges);
        }
        setAddConditionModal(false);
        setIsOpenDrawer(false);
        setIsConditionOpen(false);
    };

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            {isConfirmationModalOpen && isDataChanged && (
                <ConfirmationModal
                    onConfirmation={handleSaveRule}
                    message="Changes are not saved yet. Do you want to save the changes?"
                    isLoading={isLoading}
                    setIsModalOpen={setIsConfirmationModalOpen}
                    onCancellation={() => navigate('/workflows/orchestration')}
                />
            )}
            {addConditionModal && (
                <ConfirmationModal
                    onConfirmation={handleRemoveLastNode}
                    message="Are you sure you want to discard changes?"
                    isLoading={false}
                    setIsModalOpen={setAddConditionModal}
                    onCancellation={() => {
                        setAddConditionModal(false);
                    }}
                />
            )}
            {closeRuleModal && (
                <ConfirmationModal
                    onConfirmation={() =>
                        removeTreeOfChildNode(closeRuleNode?.id ?? '')
                    }
                    message={`Are you sure you want to delete "${
                        closeRuleNode?.label as string
                    }" and its related conditions and actions?`}
                    isLoading={false}
                    setIsModalOpen={setCloseRuleModal}
                    onCancellation={() => {
                        setCloseRuleModal(false);
                    }}
                />
            )}
            <div className="flex !bg-[#0E2510] p-8 gap-10 items-center justify-between relative !z-10">
                <div className="flex items-center gap-6">
                    <button
                        type="button"
                        className="flex items-center bg-transparent border-none gap-2 cursor-pointer"
                        onClick={() => {
                            if (isOpenDrawer) return;
                            handleBack();
                        }}
                    >
                        <SvgIcon className="!h-6" icon="ARROW_RIGHT" />
                        <div className="text-xl leading-7 font-poppins-bold tracking-tight font-bold text-[#8FB131]">
                            Back
                        </div>
                    </button>
                    <div className="flex items-center gap-2">
                        <input
                            readOnly
                            className="text-lg bg-transparent !border-none focus:!border-none p-0 hover:!border-none font-poppins-bold tracking-tight focus:bg-[#0E2510] hover:bg-[#0E2510] font-bold text-white leading-[22px]"
                            value={ruleTitle}
                            maxLength={50}
                        />
                    </div>
                </div>

                <Panel
                    className="m-0 flex items-center gap-6 p-6"
                    position="top-right"
                >
                    <div className="flex items-center gap-6 text-center">
                        <div className="flex items-center gap-2 p-1 text-center">
                            <div className="text-xs text-white">Zoom</div>
                            <SvgIcon
                                className="h-8"
                                icon="ZOOM_IN"
                                onClick={() => zoomIn()}
                            />
                            <SvgIcon
                                className="h-8"
                                icon="ZOOM_OUT"
                                onClick={() => zoomOut()}
                            />
                        </div>

                        <PrimaryButton
                            loading={isLoading}
                            disabled={isLoading || isOpenDrawer}
                            color="#8FB131"
                            variant="filled"
                            className="w-[168px] !font-extrabold"
                            name={isLoading ? '' : 'Save and Update'}
                            isDrawerButton
                            onClick={handleSaveRule}
                        />
                    </div>
                </Panel>
            </div>
            {loading ? (
                <div className="mt-5 flex justify-center simple-floatingedges">
                    <Loader />
                </div>
            ) : (
                <div className="simple-floatingedges" ref={reactFlowWrapper}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onNodeDragStop={onNodeDragStop}
                        onConnect={onConnect}
                        edgeTypes={edgeTypes}
                        nodeTypes={nodeTypes}
                        onConnectStart={onConnectStart}
                        nodesConnectable={nodes?.length <= 1}
                        connectionLineType={ConnectionLineType.SmoothStep}
                        onNodeClick={onNodeClick}
                        className="react-flow-subflows-example h-[calc(100vh-100px)]"
                    >
                        {nodes.length < 1 && (
                            <div className="absolute z-50 h-full flex items-center justify-center w-full">
                                <div className="flex flex-col items-center gap-6 text-center">
                                    <button
                                        type="button"
                                        onClick={() => handleAdd()}
                                        className="flex w-full cursor-pointer items-center justify-center gap-2 rounded bg-black border-none p-3 box-border text-center text-white max-w-[150px] h-[40px]"
                                    >
                                        <div className="text-center font-poppins-medium tracking-tight text-xs	font-bold">
                                            Start
                                        </div>
                                        <SvgIcon
                                            icon="ADD_ICON"
                                            className="cursor-pointer h-[20px]"
                                        />
                                    </button>
                                    <div className="text-lg font-poppins-bold tracking-tight font-bold leading-[22px]">
                                        Hit the “+” button to start building
                                        your first rule logic
                                    </div>
                                </div>
                            </div>
                        )}
                        {isOpenDrawer && (
                            <div className="flex justify-center h-[600px]">
                                <RuleBuilderDrawer
                                    isOpen={isOpenDrawer}
                                    openDrawer={openDrawer}
                                    isCondition={isConditionOpen}
                                    onAdd={onAdd}
                                    captureElementNode={captureElementNode}
                                    setIsOpenDrawer={setIsOpenDrawer}
                                    setCaptureElementNode={
                                        setCaptureElementNode
                                    }
                                    setIsConditionOpen={setIsConditionOpen}
                                    setIsInptValueChanged={
                                        setIsInptValueChanged
                                    }
                                    actionType={ruleDrawerTab}
                                />
                            </div>
                        )}
                        <Background />
                    </ReactFlow>
                </div>
            )}
        </div>
    );
};

export default CreateRuleBuilder;
