import React, { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import './tags.css';
import { TagInputProps } from '../common';

const TagInput: FC<TagInputProps> = ({
    tags,
    onTagsChange,
    placeholder,
    label,
    isError,
    asterisk,
}) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const handleFocusChange = () => {
        setIsFocused(document.activeElement === inputRef.current);
    };

    useEffect(() => {
        // Add event listeners to track focus changes
        document.addEventListener('focus', handleFocusChange, true);
        document.addEventListener('blur', handleFocusChange, true);
        document.addEventListener('hover', handleFocusChange, true);

        // Clean up the event listeners when the component is unmounted
        return () => {
            document.removeEventListener('focus', handleFocusChange, true);
            document.removeEventListener('blur', handleFocusChange, true);
            document.removeEventListener('hover', handleFocusChange, true);
        };
    }, []);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const addTags = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (event.currentTarget.value.trim() !== '') {
                onTagsChange([...tags, event.currentTarget.value.trim()]);
                event.currentTarget.value = '';
            }
        }
    };

    const removeTags = (index: number) => {
        onTagsChange([...tags.filter((tag, i) => i !== index)]);
    };

    const getLabelColor = () => {
        if (isError) {
            return 'text-red-200';
        }
        if (isHovered || tags.length) {
            return 'text-black';
        }
        if (!isFocused) {
            return 'text-gray-500';
        }
        return 'text-black';
    };

    return (
        <div className="flex flex-col mb-4 w-full">
            <label
                className={classNames(
                    'font-semibold text-sm mb-2 text-start',
                    getLabelColor()
                )}
                htmlFor="input"
            >
                {label}
                {asterisk && (
                    <span className="!text-red-200 text-lg pl-[2px] pt-3">
                        *
                    </span>
                )}
            </label>
            <div className="tags-input overflow-auto flex items-center bg-gray-200 rounded-lg p-3">
                <div className="flex gap-2">
                    {tags.map((tag, index) => (
                        <div
                            key={tag}
                            className="bg-green-100 text-sm rounded-lg text-white flex items-center gap-1 px-1"
                        >
                            <span>{tag}</span>
                            <button
                                type="button"
                                className="cursor-pointer border-none bg-white flex justify-center items-center rounded-full h-3 w-3 my-1 text-green-100"
                                onClick={() => removeTags(index)}
                            >
                                x
                            </button>
                        </div>
                    ))}
                </div>
                <input
                    id="input"
                    type="text"
                    ref={inputRef}
                    onKeyDown={(event) => addTags(event)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    placeholder={placeholder}
                    className="bg-transparent hover:bg-transparent"
                />
            </div>
        </div>
    );
};

export default TagInput;
