import React, { useEffect, useRef, useState } from 'react';
import { ITransactions } from 'data/types/response';
import { approveTransactions, getPaymentApproval } from 'services/api/api';
import { PrimaryButton, Dropdown, TextField } from 'components/Common';
import {
    PAYOUT_ACTION,
    PAYOUT_CANCELLED_OPTIONS,
} from 'data/constants/approve.constants';
import CompanySelectionMessage from 'components/Common/CompanySelectionMessage/CompanySelectionMessage';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import { ActionModal } from '../../Modal';
import { ApprovalTable } from './ApprovalTable';
import { exportFiles } from './ApprovalsUtill';
import { IFilterData, ITransactionsId } from './Approval.type';

const PaymentApprovals = () => {
    const { auth } = useAuthContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [dropdown, setDropdown] = useState(false);
    const [exportType, setExportType] = useState<string | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [approvalData, setApprovalData] = useState<ITransactions[]>([]);
    const [checkedApprovals, setCheckedApprovals] = useState<ITransactionsId[]>(
        []
    );
    const [actionModalData, setActionModalData] = useState({
        status: PAYOUT_ACTION.APPROVED,
        reason: PAYOUT_CANCELLED_OPTIONS.TECHNICAL_ISSUE,
        comment: '',
    });
    const [filterData, setFilterData] = useState<IFilterData>({
        companyId: '',
        status: [],
        search: '',
        page: 1,
        limit: 10,
    });
    const [totalPage, setTotalPage] = useState<number>(10);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const fetchApprovalData = () => {
        setIsLoading(true);

        const selectedCompany =
            auth?.selectedCompany ??
            JSON.parse(localStorage.getItem('selectedCompany') ?? '{}');

        const selectedBrand =
            auth?.selectedBrand ??
            JSON.parse(localStorage.getItem('selectedBrand') ?? '{}');

        const payload: IFilterData = { ...filterData };

        if (selectedBrand?._id) {
            payload.brandIds = [selectedBrand?._id];
        } else if (selectedCompany?._id) {
            payload.brandIds = (selectedCompany?.brandData || []).map(
                ({ _id }: { _id: string }) => _id
            );
        }

        if (!payload?.brandIds && !auth?.isSuperAdmin) {
            setIsLoading(false);
            return;
        }

        getPaymentApproval(payload)
            .then((res) => {
                setTotalPage(res?.total);
                setApprovalData(res.transactions || []);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        if (!auth?.selectedCompany?._id && !auth?.selectedBrand) return;
        const timeoutId = setTimeout(() => fetchApprovalData(), 300);
        return () => clearTimeout(timeoutId);
    }, [filterData, auth?.selectedBrand?._id, auth?.selectedCompany?._id]);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            // If the dropdown is open and the click is outside the dropdown or on the dropdown itself, close it
            if (
                dropdown &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setDropdown(false);
            }
        };

        // Add event listener when dropdown is open
        if (dropdown) {
            document.addEventListener('mousedown', handleDocumentClick);
        }

        // Remove event listener when component is unmounted or dropdown is closed
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [dropdown]);

    const handleAction = () => {
        setIsLoading(true);
        if (checkedApprovals.length === 0) {
            return null;
        }
        const payload = {
            status: actionModalData?.status.toUpperCase(),
            reason:
                actionModalData?.status.toUpperCase() === PAYOUT_ACTION.APPROVED
                    ? PAYOUT_CANCELLED_OPTIONS.NULL
                    : actionModalData?.reason,
            comment: actionModalData?.comment,
            transactions: checkedApprovals,
        };
        approveTransactions(payload)
            .then(() => {
                setActionModalData({
                    status: PAYOUT_ACTION.APPROVED,
                    reason: PAYOUT_CANCELLED_OPTIONS.TECHNICAL_ISSUE,
                    comment: '',
                });
                setCheckedApprovals([]);
                setIsLoading(false);
                fetchApprovalData();
                setOpen(false);
            })
            .catch((err) => {
                setIsLoading(false);
                let errorMessage: JSX.Element | string = 'something went wrong';
                if (typeof err.message === 'string') {
                    errorMessage = err.message;
                }
                if (err?.errors?.length && Array.isArray(err?.errors)) {
                    errorMessage = (
                        <div>
                            {err?.errors.map(
                                (message: string, index: number) => (
                                    <div key={message}>
                                        {index + 1}. {message}
                                    </div>
                                )
                            )}
                        </div>
                    );
                }
                showToast(errorMessage, 'error');
            });
    };

    const handleExportApprovals = () => {
        if (!exportType && approvalData.length === 0) {
            return;
        }

        exportFiles(exportType as string, approvalData);
        setExportType(null);
    };

    useEffect(() => {
        handleExportApprovals();
    }, [exportType]);

    // const handleFilterChange = (value: string) => {
    //     setFilterData({
    //         ...filterData,
    //         page: 1,
    //         status:
    //             value === 'ALL_PAYMENTS'
    //                 ? []
    //                 : filterData.status.includes(value)
    //                 ? filterData.status.filter((item) => item !== value)
    //                 : [...filterData.status, value],
    //     });
    // };

    if (!auth?.selectedCompany?._id && !auth?.selectedBrand) {
        return <CompanySelectionMessage />;
    }

    return (
        <div className="p-5">
            <div className="mr-6 ml-1 flex flex-wrap items-center justify-between">
                <div className="flex flex-wrap items-center gap-5">
                    <div className="font-merri-bold tracking-tighter text-center text-2xl text-[#131119]">
                        Approvals
                    </div>
                    <TextField
                        iconPosition="left"
                        isSearchBar
                        icon="SEARCH_ICON"
                        className="bg-white	border border-solid !rounded-[12px] !border-[#C8C8C8] min-[1480px]:!w-[425px] min-w-[230px] !pl-9"
                        placeholder="Search by tags, filters..."
                        onChange={(e) => {
                            setFilterData({
                                ...filterData,
                                search: e.target.value?.trim(),
                            });
                        }}
                        containerStyle="!mb-0"
                    />
                </div>
                <div className="flex flex-wrap items-center gap-5">
                    <PrimaryButton
                        className="!font-extrabold !rounded-xl px-4 !w-24 !text-xs !h-11"
                        color="#8FB131"
                        variant="filled"
                        disabled={checkedApprovals.length === 0}
                        name="Action"
                        onClick={() => setOpen((prev) => !prev)}
                    />
                    {open && (
                        <ActionModal
                            actionModalData={actionModalData}
                            setActionModalData={setActionModalData}
                            handleAction={handleAction}
                            setIsOpen={setOpen}
                            loading={isLoading}
                        />
                    )}
                    <div>
                        <PrimaryButton
                            className="!font-extrabold !rounded-xl px-4 !w-24 !text-xs !h-11"
                            color="#8FB131"
                            variant="filled"
                            name="Export"
                            aria-expanded={dropdown ? 'true' : 'false'}
                            onClick={() => setDropdown((prev) => !prev)}
                        />
                        {dropdown && (
                            <div ref={dropdownRef}>
                                <Dropdown
                                    isExportModal
                                    className="right-1 z-[100]"
                                    submenus={[
                                        { title: 'Microsoft (.xlsx)', id: 1 },
                                        { title: 'CSV (.csv)', id: 2 },
                                        { title: 'PDF (.pdf)', id: 3 },
                                        {
                                            title: 'Open Document (.ods)',
                                            id: 4,
                                        },
                                        {
                                            title: 'Tab Seperated Values (.tsv)',
                                            id: 5,
                                        },
                                        { title: 'Web Page (HTML)', id: 6 },
                                    ]}
                                    handleClick={(e) => {
                                        setExportType(e);
                                        setDropdown(false);
                                    }}
                                    dropdown={dropdown}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* <div className="flex gap-4 pt-4">
                {PaymentApprovalsStatus.map((v) => (
                    <PrimaryButton
                        key={`keys_${v.value}`}
                        className={classNames(
                            '!text-black !text-xs px-3 !rounded-xl',
                            {
                                '!bg-[#DEECAA]':
                                    (filterData.status.length === 0 &&
                                        v.name === 'All Payments') ||
                                    filterData.status.includes(v.value),
                                '!bg-white':
                                    (v.name !== 'All Payments' &&
                                        !filterData.status.includes(v.value)) ||
                                    (filterData.status.length !== 0 &&
                                        v.name === 'All Payments'),
                            }
                        )}
                        color="#8FB131"
                        variant="filled"
                        name={v.name}
                        onClick={() => handleFilterChange(v.value)}
                    />
                ))}
            </div> */}
            <ApprovalTable
                setCheckedApprovals={setCheckedApprovals}
                checkedApprovals={checkedApprovals}
                loading={isLoading}
                currentPage={currentPage}
                approvalData={approvalData}
                setCurrentPage={setCurrentPage}
                setFilterData={setFilterData}
                totalPage={totalPage}
                filterData={filterData}
            />
        </div>
    );
};
export default PaymentApprovals;
