import { Controller } from 'react-hook-form';
import React from 'react';
import classNames from 'classnames';
import cc from 'currency-codes';
import { IMultiSelectData } from 'data/common';
import { PaymentProvider } from 'data/constants/payment.constants';
import {
    PrimaryButton,
    PrimaryTextArea,
    SvgIcon,
    MultiSelectDropdown,
    RFSelectField,
} from 'components/Common';
import useFetchCountries from 'hooks/useFetchCountries';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import '../index.css';
import { IBrandFormProps } from '../../Brads.type';

export const BrandForm = ({
    onSubmit,
    isEditBrandsDrawer,
    openDrawer,
    isSuperAdmin,
    control,
    companyOptionList,
    getUsers,
    adminUsers,
    brand,
    loading,
}: IBrandFormProps) => {
    const { countries } = useFetchCountries();

    return (
        <form onSubmit={onSubmit}>
            <div className="p-5">
                <div className="flex items-center justify-between">
                    <div className="my-0 text-xl font-black text-green-100 font-merri-bold tracking-tighter">
                        {isEditBrandsDrawer
                            ? 'Update Sub Brand'
                            : 'Add Sub Brand'}
                    </div>
                    <SvgIcon
                        className="cursor-pointer"
                        icon="CLOSE_BUTTON"
                        onClick={openDrawer}
                    />
                </div>
                {isSuperAdmin && (
                    <div className="mt-5">
                        <RFSelectField
                            name="superAdminCompany"
                            control={control}
                            fullWidth
                            label="Select Your Company"
                            asterisk
                            onChange={(e) => {
                                if (e !== undefined) {
                                    getUsers(e.target.value);
                                }
                            }}
                            isDisabled={isEditBrandsDrawer}
                            options={companyOptionList || []}
                        />
                    </div>
                )}
                <div className="mt-5">
                    <RFTextField
                        control={control}
                        name="name"
                        fullWidth
                        label="Brand Name"
                        type="text"
                        asterisk
                    />
                </div>
                <div className="mt-5">
                    <RFSelectField
                        name="adminUser"
                        control={control}
                        fullWidth
                        asterisk
                        label="Admin User"
                        isDisabled={isEditBrandsDrawer}
                        options={adminUsers || []}
                    />
                </div>
                <div className="mt-5">
                    <RFSelectField
                        name="pspType"
                        control={control}
                        fullWidth
                        asterisk
                        label="Psp Type"
                        isDisabled={isEditBrandsDrawer}
                        options={[
                            { id: '', name: 'Select' },
                            ...PaymentProvider,
                        ]}
                    />
                </div>
                <div className="mt-5">
                    <RFTextField
                        control={control}
                        name="locals"
                        fullWidth
                        label="Locals"
                        type="text"
                        asterisk
                    />
                </div>
                <div className="mt-5">
                    <Controller
                        name="currencies"
                        control={control}
                        render={({
                            field: { value, onChange },
                            fieldState,
                        }) => (
                            <MultiSelectDropdown
                                label="Currencies"
                                asterisk
                                style={{
                                    chips: {
                                        background: '#d9d9d9',
                                        color: 'black',
                                        borderRadius: '8px',
                                        padding: '5px',
                                        fontWeight: 700,
                                    },
                                }}
                                className="custom-multiselect w-[353px]"
                                hidePlaceholder={
                                    brand?.currencies &&
                                    brand?.currencies.length > 0
                                }
                                selectedValues={(value || [])?.map(
                                    (str: string, index: number) => ({
                                        name: str,
                                        id: index + 1,
                                    })
                                )}
                                showArrow
                                isError={!!fieldState.error?.message}
                                options={cc.data.map((data) => ({
                                    id: data.digits,
                                    name: data.code,
                                }))}
                                displayValue="name"
                                onSelect={(selectedList) => {
                                    const currencyList = (
                                        selectedList || []
                                    ).map((v: IMultiSelectData) => v.name);

                                    onChange(currencyList);
                                }}
                                onRemove={(selectedList) => {
                                    const currencyList = (
                                        selectedList || []
                                    ).map((v: IMultiSelectData) => v.name);
                                    onChange(currencyList);
                                }}
                                errorMessage={fieldState?.error?.message}
                            />
                        )}
                    />
                </div>
                <div className="mt-5">
                    <Controller
                        name="countries"
                        control={control}
                        render={({
                            field: { value, onChange },
                            fieldState,
                        }) => (
                            <MultiSelectDropdown
                                label="Countries"
                                asterisk
                                className="custom-multiselect w-[353px]"
                                hidePlaceholder={
                                    brand?.countries &&
                                    brand?.countries.length > 0
                                }
                                selectedValues={(value || [])?.map(
                                    (str: string, index: number) => ({
                                        name: str,
                                        id: index + 1,
                                    })
                                )}
                                showArrow
                                isError={!!fieldState.error?.message}
                                style={{
                                    chips: {
                                        background: '#d9d9d9',
                                        color: 'black',
                                        borderRadius: '8px',
                                        padding: '5px',
                                        fontWeight: 700,
                                    },
                                }}
                                options={countries.map((data: any, index) => ({
                                    id: index,
                                    name: data?.name?.common,
                                }))}
                                displayValue="name"
                                onSelect={(selectedList) => {
                                    const countryListData: string = (
                                        selectedList || []
                                    ).map((v: IMultiSelectData) => v.name);
                                    onChange(countryListData);
                                }}
                                onRemove={(selectedList) => {
                                    const countryListData = (
                                        selectedList || []
                                    ).map((v: IMultiSelectData) => v.name);
                                    onChange(countryListData);
                                }}
                                errorMessage={fieldState.error?.message}
                            />
                        )}
                    />
                </div>
                <div className="mt-5">
                    <Controller
                        name="description"
                        control={control}
                        render={({ field: { value, ...field } }) => (
                            <PrimaryTextArea
                                cols={5}
                                rows={4}
                                label="Description"
                                type="text"
                                value={value ?? ''}
                                className="text-gray-400"
                                {...field}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="flex mt-2 p-5 sticky bottom-0 bg-white">
                <PrimaryButton
                    disabled={loading}
                    type="submit"
                    isDrawerButton
                    className={classNames('w-full')}
                    color="#2E672F"
                    loading={loading}
                    variant="filled"
                    name={
                        loading
                            ? ''
                            : isEditBrandsDrawer
                            ? 'Update Brand'
                            : 'Create Brand'
                    }
                />
            </div>
        </form>
    );
};
